// @flow

export const stopLeaving = (event: any) => {
  const confirmationMessage = 'Are you sure to leave the page?';  // a space
  (event || window.event).returnValue = confirmationMessage;
  return confirmationMessage;
}

export const preventLeavingWhenClickedPay = () => {
  const myEvent = window.attachEvent || window.addEventListener;
  const chkEvent = window.attachEvent
    ? 'onbeforeunload'
    : 'beforeunload'; /// make IE7, IE8 compitable
  myEvent(chkEvent, stopLeaving);
}

export const stopPreventLeaving = () => {
  const chkEvent = window.attachEvent
    ? 'onbeforeunload'
    : 'beforeunload'; /// make IE7, IE8 compitable
  window.removeEventListener(chkEvent, stopLeaving);
}

export const domain = typeof process.env.FRONTEND_URL === "undefined" ? '' : process.env.FRONTEND_URL;
export const api = typeof process.env.API_URL === "undefined" ? '' : process.env.API_URL;
export const debugMode = typeof process.env.DEBUG === "undefined" ? false : process.env.DEBUG;
export const FBPixelID = typeof process.env.FACEBOOK_PIXEL_ID === "undefined" ? '' : process.env.FACEBOOK_PIXEL_ID;
export const feedbackEmail = typeof process.env.FEEDBACK_EMAIL === "undefined" ? '' : process.env.FEEDBACK_EMAIL;
export const sentryDSN = typeof process.env.SENTRY_DSN === "undefined" ? '' : process.env.SENTRY_DSN;
export const appleMerchantID = typeof process.env.APPLE_MERCHANT_ID === "undefined" ? '' : process.env.APPLE_MERCHANT_ID;
export const yaMetrikaID = typeof process.env.YA_METRIKA_ID === "undefined" ? '' : parseInt(process.env.YA_METRIKA_ID);
export const yaMetrikaConfig = {clickmap: true, trackLinks: true, accurateTrackBounce: true, webvisor: true}
export const relativeRoot = typeof process.env.RELATIVE_ROOT === "undefined" ? '' : process.env.RELATIVE_ROOT;
export const iubendaID = typeof process.env.IUBENDA_ID === "undefined" ? '' : parseInt(process.env.IUBENDA_ID);
export const gaID = typeof process.env.GA_ID === "undefined" ? '' : process.env.GA_ID;
export const title = `Baby Maker and Baby Face generator`;
export const paymentSystem = typeof process.env.PAYMENT_SYSTEM === "undefined" ? '' : process.env.PAYMENT_SYSTEM;

export const privacyPolicyLink = 'https://www.luxand.com/privacy.php';
export const termsAndConditionsLink = 'https://www.luxand.com/terms.php';

export const isAnimatedBabyAllowed = false;
export const isAnimatedBabySkipped = false;
export const newFaceAskDoYouLikeAllowed = false;
export const subscriptionExperimentId = typeof process.env.SUBSCRIPTION_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.SUBSCRIPTION_EXPERIMENT_ID;
export const sizeExperimentId = typeof process.env.SIZE_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.SIZE_EXPERIMENT_ID;
export const singlePayAmount = typeof process.env.SINGLE_PAY_AMOUNT === "undefined" ? 299 : parseInt(process.env.SINGLE_PAY_AMOUNT);
export const unsubExperimentId = typeof process.env.UNSUB_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.UNSUB_EXPERIMENT_ID;

export const payScreenExperimentId = typeof process.env.PAY_SCREEN_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.PAY_SCREEN_EXPERIMENT_ID;

export const bottomSheetExperimentId = typeof process.env.BOTTOM_SHEET_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.BOTTOM_SHEET_EXPERIMENT_ID;

export const firstPageExperimentId = typeof process.env.FIRST_PAGE_EXPERIMENT_ID === "undefined"
  ? null
  : process.env.FIRST_PAGE_EXPERIMENT_ID;
