// @flow

import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {useAtom} from "@reatom/react";

import {StepsAtom} from "../atoms/Steps";
import {PaymentAtom} from "../atoms/Payment";
import {SubscriberAtom} from "../atoms/Subscriber";
import {debugMode, relativeRoot} from "../common";
import {Loader} from "./screens/Loader";
import {useSignIn} from "../effects/signIn";
import {usePaymentExpired, usePaymentLoadingState, usePaymentPayed} from "../contexts/PaymentStateContext";
import {useSubscriber, useSubscriberLoadingState} from "../contexts/SubscriberStateContext";
import {useAppGateway} from "../contexts/AppContext";
import { usePageSizeExperiment } from '../contexts/ExperimentsContext'
import { PageSizeExperiment } from '../enums/PageSizeExperimentEnum'

import { useSkipStepExperiment } from "../contexts/ExperimentsContext";
import {SkipStepExperimentEnum} from "../enums/SkipStepExperimentEnum";
import {StepsSkipExperimentAtom} from "../atoms/StepsSkipExperiment";

const rootPath = `${relativeRoot}/:locale(de|es|fr|hi|pl|pt|ru|tr|zh|what-would-our-baby-look-like)?`;

const FirstParent = lazy(() => import('./screens/FirstParent'));
const SecondParent = lazy(() => import('./screens/SecondParent'));
const KidConfig = lazy(() => import('./screens/KidConfig'));
const UnifiedPayScreen = lazy(() => import('./screens/UnifiedPayScreen'));
const UnifiedResultScreen = lazy(() => import('./screens/UnifiedResultScreen'));
const ProfileScreen = lazy(() => import('./screens/Profile'));
const BluesnapScreen = lazy(() => import('./screens/BluesnapScreen'));
const ContactUs = lazy(() => import('./ContactUs'));
const AboutUs = lazy(() => import('./AboutUs'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const Feedback = lazy(() => import('./Feedback'));
const DebugComponent = lazy(() => import('./utils/debug'));
const PageNotFoundComponent = lazy(() => import('./404.js'));
const WikiScreen = lazy(() => import('./screens/WikiScreen'));

export const Screen = () => {
  const paymentPayed = usePaymentPayed()
  const paymentExpired = usePaymentExpired()
  const isPaymentsLoaded = usePaymentLoadingState()
  const isSubscriberLoaded = useSubscriberLoadingState()
  const subscriber = useSubscriber()
  const pageSizeExperiment = usePageSizeExperiment()

  // exp
  const stepSkipExperiment = useSkipStepExperiment()
  const stepsSkipExperimentState = useAtom(StepsSkipExperimentAtom)
  const showPayScreenSkipExperiment = stepsSkipExperimentState.currentStepId === 3 && !(paymentPayed || paymentExpired);
  const showResultScreenSkipExperiment = stepsSkipExperimentState.currentStepId === 3 && (paymentPayed || paymentExpired);
  const showPreparedPayScreenSkipExperiment = isSubscriberLoaded && showPayScreenSkipExperiment && isPaymentsLoaded
  const showPreparedResultScreenSkipExperiment = isSubscriberLoaded && showResultScreenSkipExperiment;
// experiment


  const steps = useAtom(StepsAtom);

  const signInHandler = useSignIn();
  const signInPopUp = signInHandler.signInPopUp();

  const showPayScreen = steps.currentStepId === 4 && !(paymentPayed || paymentExpired);
  const showResultScreen = steps.currentStepId === 4 && (paymentPayed || paymentExpired);

  const showPreparedPayScreen = isSubscriberLoaded && showPayScreen && isPaymentsLoaded
  const showPreparedResultScreen = isSubscriberLoaded && showResultScreen;

  return (<>
    <div className={`section-main page-size-experiment`}>
    <Suspense fallback={<Loader/>}>
      <Switch>
        <Route
          exact
          strict
          sensitive
          path='/:slug1(.)*:slug2([A-Z]):slug3(.)*(/?)'
          render={props => <Redirect to={props.location.pathname.toLowerCase()}/>}
        />
        <Route
          exact
          strict
          sensitive
          path='/:slug1*:slug2([A-Z]):slug3*(/?)'
          render={props => <Redirect to={props.location.pathname.toLowerCase()}/>}
        />

        {
          stepSkipExperiment === SkipStepExperimentEnum.skip
          &&
          <Route exact={true} path={`${rootPath}/`}>
            {stepsSkipExperimentState.currentStepId === 1 && isSubscriberLoaded && <FirstParent subscriberId={subscriber.id}/>}
            {stepsSkipExperimentState.currentStepId === 2 && isSubscriberLoaded && <SecondParent subscriberId={subscriber.id}/>}
            {/*{steps.currentStepId === 3 && isSubscriberLoaded && <KidConfig/>}*/}
            {showPreparedPayScreenSkipExperiment && !showResultScreenSkipExperiment && isSubscriberLoaded && <UnifiedPayScreen/>}
            {!showPreparedPayScreenSkipExperiment && showPreparedResultScreenSkipExperiment && <UnifiedResultScreen/>}
            {/*{steps.currentStepId === 4 && !showPreparedPayScreen && !showPreparedResultScreen && <Loader/>}*/}
            {stepsSkipExperimentState.currentStepId === 3 && !showPreparedPayScreenSkipExperiment && !showPreparedResultScreenSkipExperiment && <Loader/>}
          </Route>
        }

        {
          stepSkipExperiment === SkipStepExperimentEnum.dontSkip
          &&
          <Route exact={true} path={`${rootPath}/`}>
            {steps.currentStepId === 1 && isSubscriberLoaded && <FirstParent subscriberId={subscriber.id}/>}
            {steps.currentStepId === 2 && isSubscriberLoaded && <SecondParent subscriberId={subscriber.id}/>}
            {steps.currentStepId === 3 && isSubscriberLoaded && <KidConfig/>}
            {showPreparedPayScreen && !showResultScreen && isSubscriberLoaded && <UnifiedPayScreen/>}
            {!showPreparedPayScreen && showPreparedResultScreen && <UnifiedResultScreen/>}
            {steps.currentStepId === 4 && !showPreparedPayScreen && !showPreparedResultScreen && <Loader/>}
          </Route>
        }

        <Route path={`${rootPath}/terms-of-service`} component={TermsOfService}/>
        <Route path={`${rootPath}/contact-us`} component={ContactUs}/>
        <Route path={`${rootPath}/about-us`} component={AboutUs}/>
        <Route path={`${rootPath}/feedback`} component={Feedback}/>
        <Route path={`${rootPath}/profile`} component={ProfileScreen}/>
        <Route path={`${rootPath}/wiki`} component={WikiScreen}/>

        {/*<Route path={`${rootPath}/bs`} component={BluesnapScreen}/>*/}

        <Route component={PageNotFoundComponent} status={404}/>

      </Switch>
    </Suspense>
    {debugMode === 'true' && (<Suspense fallback={<></>}>
      <DebugComponent/>
    </Suspense>)}
    {signInPopUp}
    </div>
  </>);
};
