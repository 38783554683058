// export const OneTimePayment = 'single_pay';
// export const WeeklySubscription = 'subscription';
// export const MonthlySubscription = 'monthly';

export const PaymentType = Object.freeze({
  'OneTimePayment': 'single_pay',
  'WeeklySubscription': 'subscription',
  'MonthlySubscription': 'monthly',
  'AnnualSubscription': 'annual',
});
