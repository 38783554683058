const ru = {
  "header": {
    "What Will Your Baby Look Like?": "Как будет выглядеть мой ребенок?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Войти", // сам перевел
    "Sign In mobile": "Войти", // сам перевел
    "with your email": "с помощью вашего e-mail", // сам перевел
    "Available on Apple store": "Available on Apple store", // сам не перевел
    "Available on": "Available on", // сам не перевел
    "Apple store": "Apple store", // сам не перевел
    "Android App on Google Play": "Android App on Google Play", // сам не перевел
    "Android App on": "Android App on", // сам не перевел
    "Google Play": "Google Play", // сам не перевел
    "Home": "Главная",
    "Contact Us": "Связаться с нами",
    "Feedback": "Оставить отзыв",
    "Privacy Policy": "Политика конфиденциальности",
    "Terms of Service": "Правила использования",
    "Cookie Policy": "Политика файлов Cookie",
    "Do Not Sell My Personal Information": "Не продавайте мои персональные данные",
    "Cancel Subscription": "Отменить подписку",
    "Copyright": "Авторские права",
    "All rights received": "Все права защищены",
    "About Us": "О нас",
  },
  "main": {
    "Skip Navigation": "Skip Navigation", // сам не перевел
  },
  "footer": {
    "About Us": "О нас",
    "Copyright": "Авторские права",
    "All rights received": "Все права защищены",
    "Contact Us": "Связаться с нами",
    "Feedback": "Оставить отзыв",
    "Privacy Policy": "Политика конфиденциальности",
    "Terms of Service": "Правила использования",
    "Cookie Policy": "Политика файлов Cookie",
    "Do Not Sell My Personal Information": "Не продавайте мои персональные данные",
    "Unsubscribe": "Отменить подписку",
    "Sign In": "Войти",
  },
  "stepsToolbar": {
    "Previous": "Назад",
    "Next": "Вперед",
    "Step": "Шаг",
    "Try For Free": "Попробовать бесплатно",
    "See Your Baby": "Увидеть своего будущего ребенка!",
  },
  "parent": {
    "Upload your photo": "Загрузить вашу фотографию",
    "Upload your partner's photo": "Загрузите фото вашего партнера",
    "leftBox": {
      "Upload your photo to generate a baby": "Загрузите ваше фото, чтобы создать ребенка",
      "Upload your partner's photo": "Загрузите фото вашего партнера",
      "Good Photo": "Хорошая фотография", // сам перевел
      "Bad Photo": "Плохая фотография", // сам перевел
      "Start by uploading your photo or taking a selfie": "Начните с загрузки фото или сделайте селфи-снимок",
      "The face should be frontal, clearly visible, no glasses": "Лицо должно быть в анфас, хорошо различимо, без очков",
      "The face should occupy at least 70% of the photo": "Изображение лица должно занимать не менее 70% фото",
      "Proceed by uploading your partner's photo below": "Продолжите, загрузив фото вашего партнера ниже",
      "Face should be frontal, clearly visible, no glasses": "Лицо должно быть в анфас, хорошо различимо, без очков",
      "The face in the photo should occupy at least 70%": "Лицо должно занимать не менее 70% фотографии",
    },
    "buttons": {
      "Take a Selfie": "Сделать селфи-снимок",
      "OR CHOOSE A CELEBRITY": "или выберите знаменитость",
      "Choose a celebrity": "Выбрать знаменитость",
    },
    "dnd": {
      "Drag & drop your photo": "Перетяните ваше фото",
      "or": "или",
      "Upload Image": "Загрузить изображение",
      "Remove photo": "Убрать фотографию", // сам перевел
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Поддерживаются JPG, JPG200, PNG изображения размером до 8MB",
    },
    "celebs": {
      "Loading": "Загрузка",
      "Select Celebrity": "Выбрать знаменитость",
      "Search for celebrity": "Поиск по знаменитостям",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Загрузите вашего партнера',
      'Move to baby settings': 'Перейти к настройкам ребенка',
      'Show me my Future Baby': 'Покажите моего будущего ребенка',
      'See Your Baby': 'Увидеть своего ребенка',
    },
    "title": {
      'Your photo': 'Ваша фотография',
      'Your partner photo': 'Фотография вашего партнера',
      'Kid config': 'Конфигурации ребенка',
      'Your future baby': 'Ваш будущий ребенок',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Настройки ребенка",
      "What will your baby look like?": "Как будет выглядеть твой ребенок?",
      "Personalize your baby with a name, gender and skin tone": "Персонализируйте своего ребенка, выбрав имя, пол и тон кожи",
      "Your picture": "Ваша фотография",
      "Your partner's picture": "Фотография вашего партнера",
    },
    "Baby's name": "Имя ребенка",
    "Enter baby's name": "Введите имя ребенка",
    "I hope for a": "Я надеюсь будет",
    "gender": {
      "Baby girl": "Девочка",
      "Baby boy": "Мальчик",
      "Either": "Не имеет значения",
    },
    "Select skin tone": "Выбрать тон кожи",
    "skin": {
      "Auto-detect": "Авто",
      "(90% accurate)": "",
      "Light": "Светлый",
      "Medium": "Средний",
      "Dark": "Темный",
      "Asian": "Азиатский",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3-дневный премиум доступ БЕСПЛАТНЫЙ",
      "See your baby for a small [amount] one-time fee": "Увидьте своего ребенка за небольшую разовую плату в ${{amount}}",
      "See your future baby!": "Увидьте своего будущего ребенка!",
      "See your baby and generate more babies": "Увидьте своего ребенка и создайте больше детей",
      "Save, download and share your babies": "Сохраняйте, скачивайте и делитесь детьми",
      "Includes name generator & parenting lessons": "Включает генератор имен и уроки родительства",
    },
    "flower": {
      "Try For Free": "Попробовать бесплатно",
      "See Your Baby": "Увидеть своего ребенка",
      "1-day access": "1-дневный доступ",
      "3-day free access": "3-дневный бесплатный доступ",
      "Then [amount] weekly, cancel anytime!": "Затем ${{amount}} в неделю, отмена в любое время!", // Вырвал из двух строк одну -- верно перевел?
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!", // Тут хз чё написать
      "Then [amount] annually, cancel anytime!": "Затем ${{amount}} в год, отмена в любое время!", // Вырвал из двух строк одну -- верно перевел?
      "See your future baby!": "Увидьте своего будущего ребенка!",
      "Click": "Нажмите", // проверь перевод
      "to get a": "чтобы получить", // проверь перевод
      "to your pictures and premium features": "к вашим изображениям и премиум функциям", // проверь перевод
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Попробовать бесплатно",
    "See Your Baby": "Увидьте своего ребенка",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator основывается на высоких технологиях распознавания лиц, и позволяет пользователям создавать изображения будущих детей после загрузки фотографий двух партнеров. BabyFaceGenerator предоставляет пользователям 3-дневный бесплатный пробный доступ для ознакомления с услугой, и позволяет использовать все премиум функции, включающие в себя (но не ограничены) показ созданного ребенка, возможность создавать неограниченное количество детей, сохранение и скачивание детей, возможность делиться детьми",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period",
    },
    "weekly": {
      "part1": "BabyFaceGenerator основывается на высоких технологиях распознавания лиц, и позволяет пользователям создавать изображения будущих детей после загрузки фотографий двух партнеров. BabyFaceGenerator предоставляет пользователям 3-дневный бесплатный пробный доступ для ознакомления с услугой, и позволяет использовать все премиум функции, включающие в себя (но не ограничены) показ созданного ребенка, возможность создавать неограниченное количество детей, сохранение и скачивание детей, возможность делиться детьми",
      "part2": "Первые 3 дня подписки абсолютно бесплатны и стоят $0.00, после 3 дней использования (если подписка не отменена) с пользователя взимается плата в размере ${{amount}} в неделю на еженедельной основе. Еженедельная плата начнет взиматься через Apple Pay или Google Pay (в зависимости от метода оплаты, используемого пользователем) с момента окончания бесплатного пробного 3-дневного периода. Подписка автоматически обновляется каждую неделю (${{amount}} в неделю) до момента отмены подписки, которая должна быть произведена не менее чем за 24 часа до окончания актуального периода подписки. С вашего счета снимется оплата для продления подписки в течение 24 часов до окончания актуального периода подписки",
      // "part3": "Этот сервис следует использовать только для развлечения, а сгенерированные детские фотографии не имеют ничего общего с реальной генетикой.",
      "part4": "Получая доступ к сервису или используя его, вы соглашаетесь соблюдать Пользовательское Соглашение.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator основывается на высоких технологиях распознавания лиц, и позволяет пользователям создавать изображения будущих детей после загрузки фотографий двух партнеров. BabyFaceGenerator предоставляет пользователям 1-дневный доступ к всем премиум функциям, которые включают в себя (но не ограничены) показ созданного ребенка, возможность создавать неограниченное количество детей, сохранение и скачивание детей, возможность делиться детьми. 1-дневный премиум доступ стоит ${{amount}}, это разовая оплата БЕЗ подписки и БЕЗ скрытых трат. Плата будет взята один раз через Apple Pay или Google Pay (в зависимости от метода оплаты, используемого пользователем)",
      "unSub": "Если вы использовали бесплатную 3-дневную подписку нажмите здесь -",
      "unSub2": "остановить подписку/отменить подписку",
    },
    "annual": {
      "part1": "BabyFaceGenerator основывается на высоких технологиях распознавания лиц, и позволяет пользователям создавать изображения будущих детей после загрузки фотографий двух партнеров. BabyFaceGenerator предоставляет пользователям 3-дневный бесплатный пробный доступ для ознакомления с услугой, и позволяет использовать все премиум функции, включающие в себя (но не ограничены) показ созданного ребенка, возможность создавать неограниченное количество детей, сохранение и скачивание детей, возможность делиться детьми",
      "part2": "Первые 3 дня подписки абсолютно бесплатны и стоят $0.00, после 3 дней использования (если подписка не отменена) с пользователя взимается плата в размере ${{amount}} в год на ежегодной основе. Ежегодная плата начнет взиматься через Apple Pay или Google Pay (в зависимости от метода оплаты, используемого пользователем) с момента окончания бесплатного пробного 3-дневного периода. Подписка автоматически обновляется каждый год (${{amount}} в год) до момента отмены подписки, которая должна быть произведена не менее чем за 24 часа до окончания актуального периода подписки. С вашего счета снимется оплата для продления подписки в течение 24 часов до окончания актуального периода подписки",
      "part3": "Этот сервис следует использовать только для развлечения, а сгенерированные детские фотографии не имеют ничего общего с реальной генетикой.",
      "part4": "Получая доступ к сервису или используя его, вы соглашаетесь соблюдать Пользовательское Соглашение.",
    },
    "signIn": "Войти",
    "signIn2": "в ваш аккаунт",
    "contactUs": "Свяжитесь с нами",
    "contactUs2": "если вам необходима техническая помощь, или в случае вопросов и пожеланий",
    "unSub": "Вы можете отменить подписку в любой момент нажав здесь",
    "unSub2": "Остановить премиум подписку / Отписаться",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Введите емайл связанный с учетной записью BabyFaceGenerator, чтобы войти",
    "Enter your email address": "Введите ваш емайл",
    "Sign In": "Войти",
  },
  "EmailPopup": {
    "Important": "Внимание",
    "enter your email address to see a baby and manage your account": "введите ваш емайл, чтобы увидеть ребенка и управлять своим аккаунтом",
    "Enter a valid email address": "Введите ваш емайл",
    "Email not valid": "Email not valid",
    "Save email": "Сохранить емайл",
  },
  "meta": {
    "title": "Создать Ребенка - Генератор Лица Ребенка",
    "description": "Как будет выглядеть мой ребенок? Загрузите фотографии себя и своего партнера, чтобы создать ребенка за считанные секунды!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Загрузить вашу фотографию",
        "Upload your partner's photo": "2. Загрузите фото вашего партнера",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Загрузите ваше фото, чтобы создать ребенка",
          "Upload your partner's photo": "2. Загрузите фото вашего партнера",
          "Start by uploading your photo or taking a selfie": "Начните с загрузки фото или сделайте селфи-снимок",
          "The face should be frontal, clearly visible, no glasses": "Лицо должно быть в анфас, хорошо различимо, без очков",
          "The face should occupy at least 70% of the photo": "Изображение лица должно занимать не менее 70% фото",
          "Proceed by uploading your partner's photo below": "Продолжите, загрузив фото вашего партнера ниже",
          "Face should be frontal, clearly visible, no glasses": "Лицо должно быть в анфас, хорошо различимо, без очков",
          "The face in the photo should occupy at least 70%": "Лицо должно занимать не менее 70% фотографии",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Настройки ребенка",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Увидьте своего будущего ребенка!",
        }
      },
    }
  }
}

export default ru