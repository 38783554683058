// @flow

import React from "react";
import {useAction, useAtom} from "@reatom/react";
import {nextStep, prevStep, StepsAtom} from "../atoms/Steps";

import {Step} from './Step';
import {GoBack} from "./GoBack";
import {GoNext} from "./GoNext";
import {useTranslation} from "react-i18next";
import { useSkipStepExperiment } from "../contexts/ExperimentsContext";
import { StepsSkipExperimentAtom, nextStepSkipExperiment, prevStepSkipExperiment } from "../atoms/StepsSkipExperiment";
import { SkipStepExperimentEnum } from "../enums/SkipStepExperimentEnum";
export const StepsToolbar = () => {
  const {t} = useTranslation();
  const stepsState = useAtom(StepsAtom);
  const currentStepId = stepsState.currentStepId;
  const currentStep = stepsState.steps[currentStepId - 1];
  const stepsList = stepsState.steps?.map(
    step => (<Step key={step.id} stepId={step.id} currentStepId={currentStepId}/>)
  );


  ///// =================== experiment
  const stepsSkipExperimentState = useAtom(StepsSkipExperimentAtom)
  const currentStepSkipExperimentId = stepsSkipExperimentState.currentStepId
  const currentStepExperiment = stepsSkipExperimentState.steps[currentStepSkipExperimentId - 1]
  const stepsSkipExperimentList = stepsSkipExperimentState.steps?.map(
    step => (<Step key={step.id} stepId={step.id} currentStepId={currentStepSkipExperimentId}/>)
  );

  const stepSkipExperiment = useSkipStepExperiment()

  ///// =================== experiment end


  const prevAvailable = currentStepId > 1;
  const nextAvailable = currentStepId < stepsState.steps.length && currentStep.done;

  const prevActive = prevAvailable ? '' : 'disable';
  const nextActive = nextAvailable ? '' : 'disable';

  const handleNext = useAction(() => {
    if (nextAvailable) {
      return nextStep({step: currentStep})
    }
  }, [currentStep, nextAvailable]);

  const handleBack = useAction(() => {
    if (prevAvailable) {
      return prevStep({step: currentStep})
    }
  }, [currentStep, prevAvailable]);


  ///// =================== experiment
  const prevSkipExperimentAvailable = currentStepSkipExperimentId > 1;
  const nextSkipExperimentAvailable = currentStepSkipExperimentId < stepsSkipExperimentState.steps.length && currentStepExperiment.done;
  const prevSkipExperimentActive = prevSkipExperimentAvailable ? '' : 'disable';
  const nextSkipExperimentActive = nextSkipExperimentAvailable ? '' : 'disable';

  // console.log(stepSkipExperiment)

  const handleSkipExperimentNext = useAction(() => {
    if (nextSkipExperimentAvailable) {
      return nextStepSkipExperiment({step: currentStepExperiment})
    }
  }, [currentStepExperiment, nextSkipExperimentAvailable]);

  const handleSkipExperimentBack = useAction(() => {
    if (prevSkipExperimentAvailable) {
      return prevStepSkipExperiment({step: currentStepExperiment})
    }
  }, [currentStepExperiment, prevSkipExperimentAvailable]);
  ///// =================== experiment end




  return (
    <>
      {
        stepSkipExperiment === SkipStepExperimentEnum.dontSkip
        &&
        <nav className="timeline">
          <ul>
            <li>
              <a href="#"
                 className={`nav-btn prev ${prevActive}`}
                 title={t('stepsToolbar.Previous')}
                 onClick={handleBack}
              ><span className="bm_icon_arrow"/>{t('stepsToolbar.Previous')}</a>
            </li>
            {stepsList}
            <li>
              <a href="#"
                 className={`nav-btn next ${nextActive}`}
                 title={t('stepsToolbar.Next')}
                 onClick={handleNext}
              >{t('stepsToolbar.Next')}<span className="bm_icon_arrows"/></a>
            </li>
          </ul>
        </nav>
      }
      {
        stepSkipExperiment === SkipStepExperimentEnum.skip
        &&
        <nav className="timeline">
          <ul>
            <li>
              <a href="#"
                 className={`nav-btn prev ${prevSkipExperimentActive}`}
                 title={t('stepsToolbar.Previous')}
                 onClick={handleSkipExperimentBack}
              ><span className="bm_icon_arrow"/>{t('stepsToolbar.Previous')}</a>
            </li>
            {stepsSkipExperimentList}
            <li>
              <a href="#"
                 className={`nav-btn next ${nextSkipExperimentActive}`}
                 title={t('stepsToolbar.Next')}
                 onClick={ handleSkipExperimentNext }
              >{t('stepsToolbar.Next')}<span className="bm_icon_arrows"/></a>
            </li>
          </ul>
        </nav>
      }
    </>



  );
}
