import React, {useContext} from "react";
import {useAtom} from "@reatom/react";
import {AppConfigAtom} from "../atoms/AppConfig";

const AppGatewayContext = React.createContext()

export function useAppGateway() {
  return useContext(AppGatewayContext)
}

export function AppConfigProvider({children}) {
  const appState = useAtom(AppConfigAtom)
  return (
    <>
      <AppGatewayContext.Provider value={appState.gateway}>
        {children}
      </AppGatewayContext.Provider>
    </>
  )
}