const de = {
  "header": {
    "What Will Your Baby Look Like?": "Wie wird Ihr Baby aussehen?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Melden Sie",
    "Sign In mobile": "Melden Sie",
    "with your email": "sich mit Ihrer E-Mail an",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Startseite",
    "Contact Us": "Kontaktc",
    "Feedback": "Feedback",
    "Privacy Policy": "Datenschutzbestimmungen",
    "Terms of Service": "Nutzungsbedingungen",
    "Cookie Policy": "Cookie-Richtlinie",
    "Do Not Sell My Personal Information": "Meine persönlichen Daten nicht weiterverkaufen",
    "Cancel Subscription": "Abonnement kündigen",
    "Copyright": "Copyright",
    "All rights received": "Alle Rechte vorbehalten",
    "About Us": "Über uns",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "Über uns",
    "Copyright": "Copyright",
    "All rights received": "Alle Rechte vorbehalten",
    "Contact Us": "Kontaktc",
    "Feedback": "Feedback",
    "Privacy Policy": "Datenschutzbestimmungen",
    "Terms of Service": "Nutzungsbedingungen",
    "Cookie Policy": "Cookie-Richtlinie",
    "Do Not Sell My Personal Information": "Meine persönlichen Daten nicht weiterverkaufen",
    "Unsubscribe": "Abbestellen",
    "Sign In": "Einloggen",
  },
  "stepsToolbar": {
    "Previous": "Vorheriges",
    "Next": "Nächstes",
    "Step": "",
    "Try For Free": "Gratis testen",
    "See Your Baby": "Ihr Baby sehen",
  },
  "parent": {
    "Upload your photo": "Ihr Foto hochladen",
    "Upload your partner's photo": "Das Foto Ihres Partners hochladen",
    "leftBox": {
      "Upload your photo to generate a baby": "Laden Sie Ihr Foto hoch, um ein Baby zu erstellen",
      "Upload your partner's photo": "Das Foto Ihres Partners hochladen",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Laden Sie zunächst Ihr Foto hoch oder machen Sie ein Selfie",
      "The face should be frontal, clearly visible, no glasses": "Das Gesicht sollte von vorne fotografiert und deutlich sichtbar sein; bitte ohne Brille",
      "The face should occupy at least 70% of the photo": "Das Gesicht sollte mindestens 70 % des Bildes ausmachen",
      "Proceed by uploading your partner's photo below": "Fahren Sie fort, indem Sie das Foto Ihres Partners unten hochladen",
      "Face should be frontal, clearly visible, no glasses": "Das Gesicht sollte von vorne fotografiert und deutlich sichtbar sein; bitte ohne Brille",
      "The face in the photo should occupy at least 70%": "Das Gesicht sollte mindestens 70 % des Bildes ausmachen",
    },
    "buttons": {
      "Take a Selfie": "Ein Selfie machen",
      "OR CHOOSE A CELEBRITY": "oder einen Promi auswählen",
      "Choose a celebrity": "Einen Promi auswählen",
    },
    "dnd": {
      "Drag & drop your photo": "Ihr Foto hierher ziehen und ablegen",
      "or": "oder",
      "Upload Image": "Bild hochladen",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Unterstützt JPG, JPG200, PNG und Bildgrößen von max. 8 MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Promi auswählen",
      "Search for celebrity": "Einen Promi suchen",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Ihren Partner hochladen',
      'Move to baby settings': 'Zu Baby-Einstellungen gehen',
      'Show me my Future Baby': 'Mein zukünftiges Baby anzeigen',
      'See Your Baby': 'Ihr Baby sehen',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Baby-Einstellungen",
      "What will your baby look like?": "Wie wird Ihr Baby aussehen?",
      "Personalize your baby with a name, gender and skin tone": "Ihr Baby mit Namen, Geschlecht und einem Hautton personalisieren",
      "Your picture": "Ihr Foto",
      "Your partner's picture": "Das Foto Ihres Partners",
    },
    "Baby's name": "Name des Babys",
    "Enter baby's name": "Name des Babys eingeben",
    "I hope for a": "Ich wünsche mir",
    "gender": {
      "Baby girl": "ein Mädchen",
      "Baby boy": "einen Jungen",
      "Either": "Egal",
    },
    "skin": {
      "Auto-detect": "Automatische Erkennung",
      "(90% accurate)": "",
      "Light": "Hell",
      "Medium": "Mittel",
      "Dark": "Dunkel",
      "Asian": "Asiatisch",
    },
    "Select skin tone": "Hautton auswählen",
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3-Tage-Premium-Zugang GRATIS",
      "See your baby for a small [amount] one-time fee": "Für eine einmalige Gebühr von nur {{amount}}$ können Sie jetzt Ihr Baby sehen",
      "See your future baby!": "Ihr zukünftiges Baby sehen!",
      "See your baby and generate more babies": "Ihr Baby sehen und weitere Babys erstellen",
      "Save, download and share your babies": "Ihre Babys speichern, herunterladen und mit anderen teilen",
      "Includes name generator & parenting lessons": "Enthält Namensgenerator und Erziehungsstunden",
    },
    "flower": {
      "Try For Free": "Gratis testen",
      "See Your Baby": "Ihr Baby sehen",
      "1-day access": "1-Tages-Zugang", // сам добавил, можент и не надо было..
      "3-day free access": "3-Tages-Zugang", // сам добавил, можент и не надо было..
      "Then [amount] weekly, cancel anytime!": "Danach {{amount}}$/Woche; jederzeit kündbar!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "Danach {{amount}}$/Jahr; jederzeit kündbar!\n",
      "See your future baby!": "Ihr zukünftiges Baby sehen!",
      "Click": "Auf",
      "to get a": "klicken für einen",
      "to your pictures and premium features": "zu Ihren Bildern und Premium-Funktionen",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Gratis testen",
    "See Your Baby": "Ihr Baby sehen",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "Der BabyFaceGenerator basiert auf einer Hightech-Gesichtserkennungstechnologie und ermöglicht es Nutzern, Fotos zukünftiger Babys zu erstellen, indem sie Fotos von zwei Partnern hochladen. Der BabyFaceGenerator bietet den Nutzern ein 3-Tages-Gratistest-Abo mit Zugang zu allen Premium-Funktionen, die u. a. das Anzeigen eines erstellen Babyfotos, die Möglichkeit, unbegrenzt viele Babyfotos zu generieren, Speichern, Herunterladen und Teilen von Babyfotos umfassen.",
      // не нашел неделю..
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "Der BabyFaceGenerator basiert auf einer Hightech-Gesichtserkennungstechnologie und ermöglicht es Nutzern, Fotos zukünftiger Babys zu erstellen, indem sie Fotos von zwei Partnern hochladen. Der BabyFaceGenerator bietet den Nutzern ein 3-Tages-Gratistest-Abo mit Zugang zu allen Premium-Funktionen, die u. a. das Anzeigen eines erstellen Babyfotos, die Möglichkeit, unbegrenzt viele Babyfotos zu generieren, Speichern, Herunterladen und Teilen von Babyfotos umfassen.",
      "part2": " Die ersten 3 Tage des Abonnements sind gratis und kosten 0,00 $. Nach 3 Tagen (wenn das Abonnement nicht gekündigt wird) werden dem Nutzer {{amount}} $/Woche auf regelmäßiger Basis berechnet. Nach Ende der 3-Tages Gratis-Testversion werden regelmäßige Abo-Zahlungen über Apple Pay oder Google Pay (je nach der Methode, die für die Anmeldung zur Gratis-Testversion verwendet wurde) abgebucht. Das Abonnement verlängert sich automatisch auf wöchentlicher Basis ({{amount}} $/Woche), wenn es nicht mindestens 24 Stunden vor Ablauf des aktuellen Zeitraums gekündigt wird. Ihr Konto wird innerhalb von 24 Stunden vor Ende des aktuellen Zeitraums für eine Verlängerung belastet.",
      // "part3": "Dieser Dienst sollte nur zur Unterhaltung verwendet werden, und generierte Babyfotos haben nichts mit der Genetik im wirklichen Leben zu tun.",
      "part4": "Durch den Zugriff auf oder die Nutzung des Dienstes erklären Sie sich damit einverstanden, die Nutzungsbedingungen einzuhalten und an sie gebunden zu sein.",
    },
    "oneTime": {
      "part1": "Der BabyFaceGenerator basiert auf einer Hightech-Gesichtserkennungstechnologie und ermöglicht es Nutzern, Fotos zukünftiger Babys zu erstellen, indem sie Fotos von zwei Partnern hochladen. Der BabyFaceGenerator bietet den Nutzern einen 1-Tages-Zugang zu allen Premium-Funktionen, die u. a. das Anzeigen eines erstellen Babyfotos, die Möglichkeit, unbegrenzt viele Babyfotos zu generieren, Speichern, Herunterladen und Teilen von Babyfotos umfassen. Der 1-Tages-Premium-Zugang kostet {{amount}} $ als einmalige Gebühr; KEIN Abo; KEINE versteckten Gebühren. Die Zahlung wird einmalig über Apple Pay oder Google Pay abgerechnet (abhängig von der verwendeten Zahlungsmethode)",
      "unSub": "Falls Sie ein Gratistest-Abo genutzt haben, klicken Sie hier - ",
      "unSub2": "Abonnement beenden/Mitgliedschaft",
    },
    "annual": {
      "part1": "Der BabyFaceGenerator basiert auf einer Hightech-Gesichtserkennungstechnologie und ermöglicht es Nutzern, Fotos zukünftiger Babys zu erstellen, indem sie Fotos von zwei Partnern hochladen. Der BabyFaceGenerator bietet den Nutzern ein 3-Tages-Gratistest-Abo mit Zugang zu allen Premium-Funktionen, die u. a. das Anzeigen eines erstellen Babyfotos, die Möglichkeit, unbegrenzt viele Babyfotos zu generieren, Speichern, Herunterladen und Teilen von Babyfotos umfassen.",
      "part2": "Die ersten 3 Tage des Abonnements sind gratis und kosten 0,00 $. Nach 3 Tagen (wenn das Abonnement nicht gekündigt wird) werden dem Nutzer {{amount}} $/Jahr auf regelmäßiger Basis berechnet. Nach Ende der 3-Tages Gratis-Testversion werden regelmäßige Abo-Zahlungen über Apple Pay oder Google Pay (je nach der Methode, die für die Anmeldung zur Gratis-Testversion verwendet wurde) abgebucht. Das Abonnement verlängert sich automatisch auf jährlich Basis ({{amount}} $/Jahr), wenn es nicht mindestens 24 Stunden vor Ablauf des aktuellen Zeitraums gekündigt wird. Ihr Konto wird innerhalb von 24 Stunden vor Ende des aktuellen Zeitraums für eine Verlängerung belastet.",
      "part3": "Dieser Dienst sollte nur zur Unterhaltung verwendet werden, und generierte Babyfotos haben nichts mit der Genetik im wirklichen Leben zu tun.",
      "part4": "Durch den Zugriff auf oder die Nutzung des Dienstes erklären Sie sich damit einverstanden, die Nutzungsbedingungen einzuhalten und an sie gebunden zu sein.",
    },
    "signIn": "Melden Sie",
    "signIn2": "sich bei Ihrem Konto an",
    "contactUs": "Kontaktc",
    "contactUs2": "wenn Sie Unterstützung brauchen oder Fragen oder Wünsche haben.",
    "unSub": "Sie können Ihr Abonnement jederzeit kündigen, indem Sie hier klicken",
    "unSub2": "Premium-Abonnement beenden/Abbestellen",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Geben Sie die mit Ihrem BabyFaceGenerator-Konto verknüpfte E-Mail-Adresse ein, um sich anzumelden",
    "Enter your email address": "Geben sie ihre E-Mailadresse ein",
    "Sign In": "Einloggen",
  },
  "EmailPopup": {
    "Important": "Wichtig",
    "enter your email address to see a baby and manage your account": "Geben Sie Ihre E-Mail-Adresse ein, um ein Baby zu sehen und Ihr Konto zu verwalten",
    "Enter a valid email address": "Geben sie eine gültige E-Mail-Adresse an",
    "Email not valid": "Email not valid",
    "Save email": "E-Mail speichern",
  },
  "meta": {
    "title": "Baby Maker und Baby Face Generator",
    "description": "Wie wird mein Baby aussehen? Laden Sie einfach Fotos von Ihnen und Ihrem Partner hoch und erstellen Sie in Sekundenschnelle ein Baby!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Ihr Foto hochladen",
        "Upload your partner's photo": "2. Das Foto Ihres Partners hochladen",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Laden Sie Ihr Foto hoch, um ein Baby zu erstellen",
          "Upload your partner's photo": "2. Das Foto Ihres Partners hochladen",
          "Start by uploading your photo or taking a selfie": "Laden Sie zunächst Ihr Foto hoch oder machen Sie ein Selfie",
          "The face should be frontal, clearly visible, no glasses": "Das Gesicht sollte von vorne fotografiert und deutlich sichtbar sein; bitte ohne Brille",
          "The face should occupy at least 70% of the photo": "Das Gesicht sollte mindestens 70 % des Bildes ausmachen",
          "Proceed by uploading your partner's photo below": "Fahren Sie fort, indem Sie das Foto Ihres Partners unten hochladen",
          "Face should be frontal, clearly visible, no glasses": "Das Gesicht sollte von vorne fotografiert und deutlich sichtbar sein; bitte ohne Brille",
          "The face in the photo should occupy at least 70%": "Das Gesicht sollte mindestens 70 % des Bildes ausmachen",
        }
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Baby-Einstellungen",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Ihr zukünftiges Baby sehen!",
        }
      },
    }
  }
}
export default de