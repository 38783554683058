//@flow

import React from "react"
import {useAction, useAtom} from "@reatom/react";
import {CardPaymentAtom, ProcessCardPayment} from "../atoms/CardPayment";

export const useCardPay = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const cardPayState = useAtom(CardPaymentAtom);
  const handlePayment = useAction(() => {
      return ProcessCardPayment();
    }
  );

  const cardPayReady = cardPayState.stripeObject !== null;

  const showPopup = () => {
    setIsOpen(cardPayState.stripeObject !== null)
  }

  const hidePopup = ()=>{
    setIsOpen(false)
  }

  return {handlePayment, cardPayReady, showPopup, hidePopup, isOpen};
}