import React, {useCallback, useEffect, useRef, useState} from "react";
import {CardElement, PaymentElement, useElements} from "@stripe/react-stripe-js";
import {useAtom} from "@reatom/react";
import {CardPaymentAtom} from "../atoms/CardPayment";
import {useTranslation} from "react-i18next";
import Popup from "reactjs-popup";
import {Spinner} from "./screens/Loader";

export const CardPopup = (props) => {
  const amount = props.amount / 100;
  const [popupReady, setPopupReady] = useState(false);
  const [stripeReady, setStripeReady] = useState(false);
  const [ready, setReady] = useState(false);
  const [processing, setProcessing] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState('')
  const [cardReady, setCardReady] = useState(false);
  const [expireReady, setExpireReady] = useState(false);
  const [cvcReady, setCvcReady] = useState(false);
  const cardState = useAtom(CardPaymentAtom);
  const cardBlockRef = useRef();
  const [cardNumber, setCardNumber] = useState(null);
  const [cardExpiry, setCardExpiry] = useState(null);
  const [cardCvc, setCardCvc] = useState(null);
  const {t} = useTranslation();

  const overlayRef = React.useRef();
  const modalRef = React.useRef();

  useEffect(_ => {
    if (popupReady && cardNumber && cardExpiry && cardCvc) {
      cardNumber.mount('#cardNumber')
      cardExpiry.mount('#cardExpiry')
      cardCvc.mount('#cardCvc')
      cardNumber.on('change', handleChange)
      cardExpiry.on('change', handleChange)
      cardCvc.on('change', handleChange)
      cardNumber.on('ready', () => {
        setStripeReady(true)
      })
    }
  }, [popupReady, cardNumber, cardExpiry, cardCvc])

  const handleChange = (e) => {
    // setReady(e.complete)
    // console.log(e)
    if (e.elementType === 'cardNumber' && e.complete) {
      setCardReady(true);
    }
    if (e.elementType === 'cardExpiry' && e.complete) {
      setExpireReady(true);
    }
    if (e.elementType === 'cardCvc' && e.complete) {
      setCvcReady(true);
    }
    setDisabled(e.empty)
    setError(e.error ? e.error.message : '')
  }

  const handleSubmit = (e) => {
    if (cardReady && expireReady && cvcReady) {
      setProcessing(true)
      cardState.stripeObject
        .createToken(cardNumber)
        .then((res) => {
          setReady(true)
          props.handlePayment({
            token: res.token,
            paymentTypeId: cardState.paymentTypeId
          })
          setProcessing(false)
        })
    }
  }

  const popupStyle = {
    width: props.width ? props.width : '50%',
    // height: '50%',
    paddingTop: '20px',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'left',
    backgroundColor: '#ffd6a0',
    borderRadius: '10px',
    borderColor: '#FFE6C6',
    borderWidth: '1px',
    boxShadow: '0 10px 15px #92BFC9',
  };
  const titleStyle = {
    width: '100%',
    display: 'inline-block',
    color: 'black',
    fontSize: '33px',
    lineHeight: '40px',
    fontWeight: 'bold',
  }
  const titleStyleSub = {...titleStyle}
  const titleStyleOTP = {...titleStyle}

  const subtitleStyle = {
    width: '100%',
    display: 'inline-block',
    color: '#B19269',
    fontSize: '13px',
    fontWeight: 'lighter'
  }
  const subtitleStyleSub = {...subtitleStyle}
  const subtitleStyleOTP = {...subtitleStyle, color: 'black'}

  const errorStyle = {
    width: '100%',
    height: '20px',
    display: 'inline-block',
    color: 'red',
    fontSize: '15px',
    lineHeight: '20px'
  }

  const paymentReadyStyle = {
    width: '100%',
    height: '20px',
    display: 'inline-block',
    color: 'white',
    fontSize: '15px',
    lineHeight: '20px'
  }

  const popupOverlayStyle = {
    background: '#97D7E4'
  }

  const listStyle = {
    width: `auto`,
    padding: `0`,
  }

  const ulStyle = {
    textAlign: `left`,
    fontSize: `14px`,
    margin: '15px 0 25px',
  }

  const payBtnStyle = {
    margin: `20px 0 30px`,
    backgroundColor: '#11B814',
    borderColor: '#11B814',
    padding: '10px 40px',
    fontSize: '18px',
    lineHeight: '30px',
  }

  const payBtnWrapperStyle = {
    textAlign: 'center',
  }

  const cardInputStyle = {margin: '5px 0 10px', minHeight: '26px', padding: '13px 10px'}
  const ddmmStyle = {...cardInputStyle, margin: 0, float: 'left', width: '48%'}
  const cvvStyle = {...cardInputStyle, margin: 0, float: 'right', width: '48%'}

  const hrStyle = {color: 'black', border: 0, borderTop: '2px solid black', margin: `10px 0`}

  return (
    <>
      <Popup
        open={props.isOpen}
        onOpen={() => {
          setCardNumber(cardState.elements.create('cardNumber'))
          setCardExpiry(cardState.elements.create('cardExpiry'))
          setCardCvc(cardState.elements.create('cardCvc'))
          setPopupReady(true)
        }}
        onClose={()=>{
          cardNumber.destroy()
          cardExpiry.destroy()
          cardCvc.destroy()
          setCardNumber(null)
          setCardExpiry(null)
          setCardCvc(null)
          props.closePopup()
        }}
        contentStyle={popupStyle}
        overlayStyle={popupOverlayStyle}
        modal
      >
        <>
          {props.isSubscription && <span style={titleStyleSub}>{t('cardPopup.title sub')}</span>}
          {!props.isSubscription && <span style={titleStyleOTP}>{t('cardPopup.title otp')}</span>}
          <hr style={hrStyle}/>
          {props.isSubscription &&
            <span style={subtitleStyleSub}>{t('cardPopup.subtitle sub [amount]', {amount})}</span>}
          {!props.isSubscription &&
            <span style={subtitleStyleOTP}>{t('cardPopup.subtitle otp [amount]', {amount})}</span>}

          <ul style={ulStyle}>
            <li style={listStyle}>&#10004; See your baby</li>
            <li style={listStyle}>&#10004; Generate unlimited babies</li>
            <li style={listStyle}>&#10004; Save, download and share your babies</li>
          </ul>
          <div className="clear"/>
          <div id="cardBlock" ref={cardBlockRef}>
            {!stripeReady && <Spinner size={'40px'} color={'black'}/>}
            <div id="cardNumber" className={`input-style`} style={cardInputStyle}/>
            <div>
              <div id="cardExpiry" className={`input-style`} style={ddmmStyle}/>
              <div id="cardCvc" className={`input-style`} style={cvvStyle}/>
            </div>
          </div>
          {(error || processing || ready) && (
            <div className={`text-center`} style={{margin: `10px 0`}}>
              {error && <span style={errorStyle}>{error}</span>}
              {processing && <Spinner size={'40px'} color={'white'}/>}
              {ready && (
                <>
                  <Spinner size={'40px'} color={'white'}/>
                  <div className={`clear`}/>
                  <span style={paymentReadyStyle}>{t('cardPopup.message')}</span>
                </>
              )}
            </div>
          )}
          <div style={payBtnWrapperStyle}>
            <button className="btn btn-green"
                    title={t('cardPopup.buttons.pay [amount]', {amount: props.isSubscription ? '0.00' : amount})}
                    onClick={handleSubmit}
                    disabled={processing}
                    style={payBtnStyle}
            >
              <span/>{t('cardPopup.buttons.pay [amount]', {amount: props.isSubscription ? '0.00' : amount})}
            </button>
          </div>
        </>
      </Popup>
    </>
  )
}
