const babyGenerator = {
  "header": {
    "What Will Your Baby Look Like?": "Baby Generator - See Your Future Baby",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Sign In",
    "Sign In mobile": "Sign In",
    "with your email": "with your email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Home",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Cancel Subscription": "Cancel Subscription",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "About Us": "About Us",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "About Us",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Unsubscribe": "Unsubscribe",
    "Sign In": "Sign In",
  },
  "stepsToolbar": {
    "Previous": "Previous",
    "Next": "Next",
    "Step": "Step",
    "Try For Free": "Try For Free",
    "See Your Baby": "See Your Baby",
  },
  "parent": {
    "Upload your photo": "Upload your photo",
    "Upload your partner's photo": "Upload your partner's photo",
    "leftBox": {
      "Upload your photo to generate a baby": "Generate baby by uploading your photo",

      "Upload your partner's photo": "Upload your partner's photo",

      "Good Photo": "Baby generator - good uploaded photo example",
      "Bad Photo": "Baby generator - bad uploaded photo example",

      "Start by uploading your photo or taking a selfie": "Upload your photo or take a selfie",
      "The face should be frontal, clearly visible, no glasses": "Portrait photo, face clearly visible, no glasses",
      "The face should occupy at least 70% of the photo": "Your face should occupy at least 70% of the image",

      "Proceed by uploading your partner's photo below": "Proceed by uploading your partner's photo below",
      "Face should be frontal, clearly visible, no glasses": "Face should be frontal, clearly visible, no glasses",
      "The face in the photo should occupy at least 70%": "The face in the photo should occupy at least 70%",
    },
    "buttons": {
      "Take a Selfie": "Take a Selfie",
      "OR CHOOSE A CELEBRITY": "OR CHOOSE A CELEBRITY",
      "Choose a celebrity": "Choose a celebrity",
    },
    "dnd": {
      "Drag & drop your photo": "Drag & drop your photo",
      "or": "or",
      "Upload Image": "Upload Image",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Supports JPG, JPG200, PNG and max image size of 8MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Select Celebrity",
      "Search for celebrity": "Search for celebrity",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Upload your partner',
      'Move to baby settings': 'Move to baby settings',
      'Show me my Future Baby': 'Show me my Future Baby',
      'See Your Baby': 'See Your Baby',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Baby settings",
      "What will your baby look like?": "What will your baby look like?",
      "Personalize your baby with a name, gender and skin tone": "Personalize your baby with a name, gender and skin tone",
      "Your picture": "Your picture",
      "Your partner's picture": "Your partner's picture",
    },
    "Baby's name": "Baby's name",
    "Enter baby's name": "Enter baby's name",
    "I hope for a": "I hope for a",
    "gender": {
      "Baby girl": "Baby girl",
      "Baby boy": "Baby boy",
      "Either": "Either",
    },
    "Select skin tone": "Select skin tone",
    "skin": {
      "Auto-detect": "Auto-detect",
      "(90% accurate)": "(90% accurate)",
      "Light": "Light",
      "Medium": "Medium",
      "Dark": "Dark",
      "Asian": "Asian",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3-day premium access is FREE",
      "See your baby for a small [amount] one-time fee": "See your baby for a small ${{amount}} one-time fee",
      "See your future baby!": "See your future baby!",
      "See your baby and generate more babies": "See your baby and generate more babies",
      "Save, download and share your babies": "Save, download and share your babies",
    },
    "flower": {
      "Try For Free": "TRY FOR FREE",
      "See Your Baby": "SEE YOUR BABY",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "See your future baby!": "See your future baby!",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "TRY FOR FREE",
    "See Your Baby": "SEE YOUR BABY",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/week on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a weekly basis (${{amount}}/week) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 1-day access to all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies. 1-day premium access costs {{amount}} one-time fee, NO subscriptions, NO hidden fees. Payment will be charged once via Apple Pay or Google Pay (depending on the method used to make a payment)",
      "unSub": "In case you used a free trial subscription click here - ",
      "unSub2": "stop subscription/cancel membership",
    },
    "signIn": "Sign In",
    "signIn2": "to your account",
    "contactUs": "Contact us",
    "contactUs2": "for support, or in case you have any questions or requests.",
    "unSub": "You can cancel your subscription anytime by clicking here:",
    "unSub2": "Stop premium subscription / Unsubscribe",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Enter the email address associated with your BabyFaceGenerator account to sign in",
    "Enter your email address": "Enter your email address",
    "Sign In": "Sign In",
  },
  "EmailPopup": {
    "Important": "Important",
    "enter your email address to see a baby and manage your account": "enter your email address to see a baby and manage your account",
    "Enter a valid email address": "Enter a valid email address",
    "Email not valid": "Email not valid",
    "Save email": "Save email",
  },
  "meta": {
    "title": "Free Baby Generator | Based on Some of the Newest AI Algorithms",
    "description": "Baby generator shows what your baby will look like by analyzing photos of you and your partner. Generate your baby now!",
  },
  "seo": {
    "pic1alt": 'Baby generator preview 1',
    "pic2alt": 'Baby generator preview 2',
    "pic3alt": 'Baby generator preview 3',
    "pic4alt": 'Baby generator preview 4',
    "small": {
      "part1": "Baby generator is a simple yet effective online tool to see what your baby will look like. It is based on the newest artificial intelligence algorithms that analyze uploaded photos of two partners. The algorithms will generate a photo of a future baby in only a few seconds.",
      "part2": "To start using our baby generator, simply proceed by uploading your photo, a photo of your partner or a second parent, and customize the baby by adding a baby name, picking a skin color (if necessary), and more.",
      "h2": "Future baby generator",
      "part3": "The Baby generator is a web-based application, which means it works on any device that has a browser and an internet connection. You have several options to upload photos of two partners - uploading them from a device library, taking a selfie, or even choosing one of the celebrities from a library of celebrity photos.",
      "part4": "To see the most accurate photo of a baby, make sure you upload high quality photos with faces clearly visible. The Baby generator works by analyzing pictures, not by randomizing baby faces, which is why the quality of uploaded photos matter.",
    },
  },
}
export default babyGenerator;