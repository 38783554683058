import React, {useContext} from 'react'
import {useAtom} from "@reatom/react";
import {PaymentAtom} from "../atoms/Payment";

const PaymentStatePayedContext = React.createContext()
const PaymentStateExpiredContext = React.createContext()
const PaymentStateLoadingContext = React.createContext()
const PaymentStateTrialUsedContext = React.createContext()

export function usePaymentPayed() {
  return useContext(PaymentStatePayedContext)
}

export function usePaymentExpired() {
  return useContext(PaymentStateExpiredContext)
}

export function usePaymentTrialState() {
  return useContext(PaymentStateTrialUsedContext)
}

export function usePaymentLoadingState() {
  return useContext(PaymentStateLoadingContext)
}

export function PaymentStateProvider({children}) {
  const paymentState = useAtom(PaymentAtom)
  const paymentStateLoading = paymentState.weeklySub !== null
    || paymentState.oneTimePay !== null
    || paymentState.monthlySub !== null
    || paymentState.annualSub !== null;
  return (
    <PaymentStatePayedContext.Provider value={paymentState.payed}>
      <PaymentStateExpiredContext.Provider value={paymentState.expired}>
        <PaymentStateLoadingContext.Provider value={paymentStateLoading}>
          <PaymentStateTrialUsedContext.Provider value={paymentState.trial}>
            {children}
          </PaymentStateTrialUsedContext.Provider>
        </PaymentStateLoadingContext.Provider>
      </PaymentStateExpiredContext.Provider>
    </PaymentStatePayedContext.Provider>
  )
}