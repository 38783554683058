// @flow

import {declareAction, declareAtom} from "@reatom/core";
import {GenerateKidPic} from "./Generator";
import axios from "axios";
import * as common from "./../common";
import {AppConfigAtom} from "./AppConfig";
import {UtmAnalytics} from "./UtmAnalytics";
import {PaymentType} from "../enums/PaymentTypesEnum";
import {NRError, NRSuccess, NRUnSubscribed} from "./NewRelic";
import { SkipStepExperimentEnum } from '../enums/SkipStepExperimentEnum'

export const SetExpirationDate = declareAction(
  'setExpirationDateAction'
  // (payload, store) => {
  //   localStorage.setItem('paymentState', JSON.stringify(store.getState(PaymentAtom)));
  // }
);

export const SetTrial = declareAction('setTrialAction');

export const SetTrialPayment = declareAction(
  'setTrialPaymentAction',
  (payload, store) => {
    const appState = store.getState(AppConfigAtom);
    axios.post(`${common.api}/subscriber/${appState.fingerprint}/subscribe/trial`).then(res => {
      // const expirationDate = moment(res.data.expire_date).valueOf();
      const expirationDate = (new Date(res.data.expire_date)).getTime();
      store.dispatch(SetTrial(true));
      store.dispatch(SetExpirationDate(expirationDate));
      store.dispatch(GenerateKidPic());
    })
  }
)

export const ShowSuccessfulPaymentPopup = declareAction('showSuccessfulPaymentPopupAction');
export const HideSuccessfulPaymentPopup = declareAction('hideSuccessfulPaymentPopupAction');

export const SuccessfulPayment = declareAction(
  'successfulPaymentAction',
  (payload, store) => {
    // localStorage.setItem('paymentState', JSON.stringify(store.getState(PaymentAtom)));
    const expirationDate = (new Date(payload.date)).getTime()
    // const expirationDate = moment(payload).valueOf();
    store.dispatch(ShowSuccessfulPaymentPopup());
    store.dispatch(SetPayed(true));
    store.dispatch(SetExpirationDate(expirationDate));
    if (SkipStepExperimentEnum.dontSkip === payload.experiment) {
      store.dispatch(GenerateKidPic({ experiment: 0 }))
    }
    if (SkipStepExperimentEnum.skip === payload.experiment) {
      store.dispatch(GenerateKidPic({ experiment: 1 }))
    }
  }
);

export const UnSuccessfulPayment = declareAction('unSuccessfulPaymentAction');

export const SetPayed = declareAction('setPayedAction');
export const SetExpired = declareAction('setExpiredAction');
export const SetCanceled = declareAction('setCanceledAction');
export const SetCancelAtPeriodEnd = declareAction('setCancelAtPeriodEndAction');

// ==============================================================
export const SetSetupState = declareAction('setSetupStateAction')

export const SetPaymentError = declareAction('setPaymentErrorAction');

export const Unsubscribe = declareAction(
  'unsubscribeAction',
  (payload, store) => {
    const appState = store.getState(AppConfigAtom);
    axios.post(
      `${common.api}/subscriber/${appState.fingerprint}/unsubscribe`
      // `${common.api}/subscriber/${appState.fingerprint}/unsubscribe/bluesnap`
    )
      .then(
        res => {
          // store.dispatch(SetCancelAtPeriodEnd(true));
          store.dispatch(SetCanceled(true));
          store.dispatch(SetPayed(false));
          store.dispatch(SetTrial(false));
          store.dispatch(SetExpirationDate(null));
          store.dispatch(NRUnSubscribed(NRSuccess));
        }
      )
      .catch(e => {
        console.error(e)
        store.dispatch(NRUnSubscribed(NRError));
      })
  }
);

export const SetPaymentProcessor = declareAction('setPaymentProcessorAction');

const findPaymentType = (types, type) => {
  const found = types.find(_type => _type.type === type);
  return found || null;
}
export const GetPaymentType = declareAction(
  'getPaymentTypeAction',
  (payload, store) => {
    const utmState = store.getState(UtmAnalytics);
    const type = utmState.utmSource === null ? 'default' : utmState.utmSource;
    axios.get(`${common.api}/payment-type/${type}`)
      .then(resp => {
        const oneTimePay = findPaymentType(resp.data, PaymentType.OneTimePayment)
        const weeklySub = findPaymentType(resp.data, PaymentType.WeeklySubscription)
        const monthlySub = findPaymentType(resp.data, PaymentType.MonthlySubscription)
        const annualSub = findPaymentType(resp.data, PaymentType.AnnualSubscription)
        store.dispatch(SetPaymentTypes({oneTimePay, weeklySub, monthlySub, annualSub}));
      })
      .catch(console.error);
  }
);

export const SetPaymentTypes = declareAction(
  'setPaymentsTypesAction',
  ((payload, store) => {
    store.dispatch(CheckIsPaymentInit());
  })
);
export const GetGateway = declareAction(
  'getGatewayAction',
  (payload, store) => {
    axios.get(`${common.api}/determine-gateway`)
      .then(resp => {
        store.dispatch(SetGateway(resp.data.gateway));
        store.dispatch(CheckIsPaymentInit());
      })
      .catch(console.error);
  }
);
export const SetGateway = declareAction('setGatewayAction');
const CheckIsPaymentInit = declareAction(
  'checkIsPaymentInitializedAction',
  ((payload, store) => {
    const paymentAtomState = store.getState(PaymentAtom);
    if (paymentAtomState.gateway !== null && (
      paymentAtomState.oneTimePay !== null ||
      paymentAtomState.weeklySub !== null ||
      paymentAtomState.monthlySub !== null ||
      paymentAtomState.annualSub !== null
    )) {
      store.dispatch(PaymentInitialized());
    }
  })
);
export const PaymentInitialized = declareAction('paymentInitializedAction');
export const PaymentReady = declareAction('paymentReadyAction');
export const SetPaymentType = declareAction('setPaymentTypeAction');
export const SetSubscriptionPaymentType = declareAction('setSubscriptionPaymentTypeAction');
export const SetCurrentSubscriptionId = declareAction(
  'setCurrentSubscriptionIdAction',
  ((payload, store) => {
    localStorage.setItem('currentSubStripeId', payload);
  }));

const loadInitState = () => {
  const currentSubStripeId = localStorage.getItem('currentSubStripeId');
  return {
    ready: false,
    initialized: false,
    payed: false,
    expired: false,
    canceled: false,
    cancelAtPeriodEnd: false,
    paymentError: null,
    expirationDate: null,
    trial: false,
    showSuccessPopup: false,
    processor: null,
    oneTimePay: null,
    weeklySub: null,
    monthlySub: null,
    annualSub: null,
    gateway: null,
    paymentType: null,
    setupDone: null,
    subscriptionPaymentType: null,
    currentSubscriptionId: currentSubStripeId,
  };
};

const initState = loadInitState();

export const PaymentAtom = declareAtom(
  'paymentAtom',
  initState,
  on => [
    on(SetPayed, (state, payload) => {
      return {...state, payed: payload};
    }),
    on(SetExpired, (state, payload) => {
      return {...state, expired: payload};
    }),
    on(SetSetupState, (state, payload) => {
      // console.log(payload, 'setSetupState')
      return {...state, setupDone: payload}
    }),
    on(SetCanceled, (state, payload) => {
      return {...state, canceled: payload};
    }),
    on(SetCancelAtPeriodEnd, (state, payload) => {
      return {...state, cancelAtPeriodEnd: payload};
    }),
    on(SetExpirationDate, (state, payload) => {
      return {...state, expirationDate: payload};
    }),
    on(SetTrial, (state, payload) => {
      return {...state, trial: payload};
    }),
    on(SetPaymentError, (state, payload) => {
      return {...state, paymentError: payload};
    }),
    on(ShowSuccessfulPaymentPopup, (state, payload) => {
      return {...state, showSuccessPopup: true}
    }),
    on(HideSuccessfulPaymentPopup, (state, payload) => {
      return {...state, showSuccessPopup: false}
    }),
    on(SetPaymentProcessor, (state, payload) => {
      return {...state, processor: payload}
    }),
    on(SetPaymentTypes, (state, payload) => {
      return {
        ...state,
        oneTimePay: payload.oneTimePay,
        weeklySub: payload.weeklySub,
        monthlySub: payload.monthlySub,
        annualSub: payload.annualSub,
      };
    }),
    on(SetGateway, (state, payload) => {
      return {
        ...state,
        gateway: payload
      }
    }),
    on(PaymentInitialized, (state, payload) => {
      return {
        ...state,
        initialized: true,
      }
    }),
    on(PaymentReady, (state, payload) => {
      return {
        ...state,
        ready: true,
      }
    }),
    on(SetPaymentType, (state, payload) => {
      return {
        ...state,
        paymentType: payload,
      }
    }),
    on(SetSubscriptionPaymentType, (state, payload) => {
      return {
        ...state,
        subscriptionPaymentType: payload,
      }
    }),
    on(SetCurrentSubscriptionId, (state, payload) => {
      return {
        ...state,
        currentSubscriptionId: payload,
      }
    })
  ]
)
