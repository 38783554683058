import React, {useContext} from 'react'
import usePaymentTypeResolver from "../effects/paymentType";

const PaymentTypeContext = React.createContext()

export function usePaymentType() {
  return useContext(PaymentTypeContext)
}

export function PaymentTypeProvider({children}) {
  const paymentTypeResolver = usePaymentTypeResolver()
  const paymentType = paymentTypeResolver.paymentType;

  return (
    <PaymentTypeContext.Provider value={paymentType}>
      {children}
    </PaymentTypeContext.Provider>
  )
}