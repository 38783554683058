//@flow

import {declareAction, declareAtom} from "@reatom/core";
import axios from "axios";
import {api} from "./../common";


export const ResponseLog = declareAction(
  'responseLogAction',
  (payload, store) => {
    const form = new FormData();
    form.append('message', JSON.stringify(payload));
    axios.post(
      `${api}/logger/debug`,
      form,
      {headers: {'Content-Type': 'multipart/form-data'}}
    )
      .then(response => {
        // console.log(response);
      })
      .catch(err => {
        // store.dispatch(ErrorLog(err));
        console.error(err)
      });
  }
);

export const ErrorLog = declareAction(
  'errorLogAction',
  (payload, store) => {
    const form = new FormData();
    form.append('message', JSON.stringify(payload));
    axios.post(
      `${api}/logger/error`,
      form,
      {headers: {'Content-Type': 'multipart/form-data'}}
    )
      .then(response => {
        // console.log(response);
      })
      .catch(err => {
        // store.dispatch(ErrorLog(err));
        console.error(err)
      });
  }
)

export const FrontendLog = declareAction('frontendLogAction');

export const DebugAtom = declareAtom(
  'debugAtom',
  {responses: [], errors: [], frontend: []},
  on => [
    on(FrontendLog, (state, payload) => {
      return {...state, frontend: [...state.frontend, JSON.stringify(payload)]};
    }),
    on(ResponseLog, (state, payload) => {
      return {...state, responses: [...state.responses, JSON.stringify(payload)]};
    }),
    on(ErrorLog, (state, payload) => {
      return {...state, errors: [...state.errors, JSON.stringify(payload)]};
    })
  ]
);