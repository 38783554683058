import {declareAction, declareAtom} from "@reatom/core";
import {UtmAnalytics} from "./UtmAnalytics";
import {WalletType} from "../enums/WalletTypesEnum";

export const SetWalletType = declareAction(
  'setWalletTypeAction',
  (payload, store) => {
    const utm = store.getState(UtmAnalytics);
    if (['cardpay', 'cardsub'/*, 'facebook'*/].includes(utm.utmSource)) {
      store.dispatch(StoreWalletType(WalletType.Card))
    } else {
      store.dispatch(StoreWalletType(WalletType.Wallet))
    }
  }
)

export const StoreWalletType = declareAction('storeWalletTypeAction')

export const WalletTypeAtom = declareAtom(
  'walletTypeAtom',
  {walletType: null},
  on => {
    on(StoreWalletType, (state, payload) => {
      return {...state, walletType: payload}
    })
  }
)
