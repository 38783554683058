//@flow

import { declareAction, declareAtom } from '@reatom/core'

import {
  SetCancelAtPeriodEnd,
  SetCanceled,
  SetExpirationDate,
  SetExpired,
  SetGateway,
  SetPayed,
  SetPaymentProcessor,
  SetTrial,
  SetSetupState, SetSubscriptionPaymentType,
} from './Payment'
import { ChangeVersion } from './AppConfig'

export const SubscriberLoaded = declareAction(
  'subscriberLoadedAction',
  (payload, store) => {

    const trial = false
    let payed = false
    let expireDate = null
    let expired = false
    let canceled = false
    let cancelAtPeriodEnd = false
    const currentTime = new Date()//moment.utc();

    let currentTimeBeforeHour = new Date()

    let alreadySettedUp = false

    for (let subscription of payload.subscriptions) {
      if (subscription.state === 'active') {
        const subscriptionExpireTime = (new Date(subscription.expireDate)).getTime()
        payed = true
        expireDate = subscriptionExpireTime
        store.dispatch(SetSubscriptionPaymentType(subscription.paymentType))
      }
    }

    for (let subscription of payload.subscriptions) {
      if (subscription.state === 'active') {
        currentTimeBeforeHour.setHours(currentTimeBeforeHour.getHours() - 1)

        // ДОБАВЛЕНИЕ 20 МИНУТ в задержку! ИЛИ 1200000 Миллисекунд
        currentTimeBeforeHour.setTime(currentTimeBeforeHour.getTime() - 1200000)

        if ((currentTimeBeforeHour.getTime() >= (new Date(subscription.createdAt)).getTime()) && !alreadySettedUp) {
          alreadySettedUp = true
        }
      }
    }
    for (let subscription of payload.subscriptions) {
      if (subscription.state === 'expired' && !payed) {
        // // const subscriptionExpireTime = moment(subscription.expireDate).valueOf();
        // const subscriptionExpireTime = (new Date(subscription.expireDate)).getTime();
        // if (subscriptionExpireTime > currentTime.getTime()) {
        expired = true
        // expireDate = subscriptionExpireTime;
        // }
        store.dispatch(SetSubscriptionPaymentType(subscription.paymentType))
      }
    }
    if (typeof payload.version !== 'undefined' && payload.version !== null)
      store.dispatch(ChangeVersion(payload.version))

    for (let subscription of payload.subscriptions) {
      if (subscription.state === 'canceled' && !payed) {
        // // const subscriptionExpireTime = moment(subscription.expireDate).valueOf();
        // const subscriptionExpireTime = (new Date(subscription.expireDate)).getTime();
        // if (subscriptionExpireTime > currentTime.getTime()) {
        canceled = true
        // expireDate = subscriptionExpireTime;
        // }
      }
    }
    // for (let subscription of payload.subscriptions) {
    //   if (subscription.state === 'cancel_at_period_end' && !payed) {
    //     // // const subscriptionExpireTime = moment(subscription.expireDate).valueOf();
    //     const subscriptionExpireTime = (new Date(subscription.expireDate)).getTime();
    //     // if (subscriptionExpireTime > currentTime.getTime()) {
    //     cancelAtPeriodEnd = true;
    //     payed = true;
    //     expireDate = subscriptionExpireTime;
    //     // }
    //   }
    // }

    store.dispatch(SetSetupState(alreadySettedUp))
    store.dispatch(SetExpired(expired))
    store.dispatch(SetCanceled(canceled))
    store.dispatch(SetCancelAtPeriodEnd(cancelAtPeriodEnd))

    store.dispatch(SetTrialUsed(payload.isUsedTrial))
    store.dispatch(SetTrial(trial))
    if (payed) {
      const lastSub = payload.subscriptions[payload.subscriptions.length - 1]
      if (lastSub.paymentGateway === 'google_pay') {
        store.dispatch(SetPaymentProcessor('GOOGLE'))
      } else if (lastSub.paymentGateway === 'apple_pay') {
        store.dispatch(SetPaymentProcessor('APPLE'))
      }
      store.dispatch(SetGateway(lastSub.stripeGateway))
      store.dispatch(SetPayed(payed))
    }
    store.dispatch(SetExpirationDate(expireDate))
  }
)
export const SetTrialUsed = declareAction('setTrialUsedAction')
export const SetEmail = declareAction('setEmailAction')
export const AddBaby = declareAction('addBabyAction')

//TODO: fix после сохранения email чтоб добавлял loaded & email
//TODO: fix после генерации ребенка добавлять его в subscriber.babies
export const SubscriberAtom = declareAtom(
  'subscriberAtom',
  {
    subscriber: null,
    isUsedTrial: false,
    loaded: false,
  },
  on => [
    on(SubscriberLoaded, (state, payload) => {
      return { ...state, subscriber: payload, loaded: true }
    }),
    on(SetTrialUsed, (state, payload) => {
      return { ...state, isUsedTrial: payload }
    }),
    on(SetEmail, (state, payload) => {
      return { ...state, subscriber: { ...state.subscriber, email: payload } }
    }),
    on(AddBaby, (state, payload) => {
      return { ...state, subscriber: { ...state.subscriber, babies: [...state.subscriber.babies, payload] } }
    })
  ]
)