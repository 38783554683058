//@flow

import {useAction, useAtom} from "@reatom/react";
import {PaymentAtom} from "../atoms/Payment";
import {ShowStripePaymentRequest, StripeApplePaymentAtom} from "../atoms/StripePayment";
import * as common from "../common";
import {ShowGooglePaymentRequest} from "../atoms/GooglePayment";
import {gtagEvent, gtagEventNew} from "../utils/gtag";
import {ProcessCardPayment, ShowCardPaymentRequest} from "../atoms/CardPayment";
import {WalletTypeAtom} from "../atoms/WalletType";
import {WalletType} from "../enums/WalletTypesEnum";
import useExperimentsResolver from "./experiments";
import {SizeExperiment} from "../enums/SizeExperimentEnum";
import {AppConfigAtom, IncrementPaymentClickCount} from "../atoms/AppConfig";

export const useTrial = () => {
  const appState = useAtom(AppConfigAtom);
  const walletTypeState = useAtom(WalletTypeAtom)
  const paymentState = useAtom(PaymentAtom)
  const applePayState = useAtom(StripeApplePaymentAtom)

  const isTrialEnabled = paymentState.trial
  const handleTrial = useAction((paymentData, sizeExperiment) => {
    if (walletTypeState.walletType === WalletType.Wallet) {
      if (applePayState.applePayEnabled) {
        return ShowStripePaymentRequest();
      } else {
        common.preventLeavingWhenClickedPay();
        return ShowGooglePaymentRequest();
      }
    } else {
      return ProcessCardPayment(paymentData);
    }

  }, [applePayState, appState.paymentClickCount]);
  return {handleTrial, isTrialEnabled};
}