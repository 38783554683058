
import {declareAction, declareAtom} from "@reatom/core";
import axios from "axios";
import * as common from "../common";

const loadInitState = () => {
  let state = {
    wikiState: []
  };
  return state;
};

const initState = loadInitState();

export const LoadWikiArticles = declareAction('loadWikiArticles');

export const WikiArticles = declareAction(
  'loadWikiArticles',
  (payload, store) => {
    axios.get(`${common.api}/wiki`).then(res => {
      store.dispatch(LoadWikiArticles(res.data));
    }).catch(console.error);
  }
);



export const ArticlesConfigAtom = declareAtom(
  'articlesConfigAtom',
  initState,
  on => [
    on(LoadWikiArticles, (state, payload) => {
      return {...state, wikiState: payload};
    })
  ]
);