import { useEffect } from 'react'
import { useAction, useAtom } from '@reatom/react'
import { PaymentAtom, PaymentReady, SetPaymentType } from '../atoms/Payment'
import { CheckApplePaySupport } from '../atoms/StripePayment'
import { InitGooglePayment } from '../atoms/GooglePayment'
import { PaymentType } from '../enums/PaymentTypesEnum'
import { InitCardPayment } from '../atoms/CardPayment'

const usePaymentInitializer = (
  paymentType,
  experiments
) => {
  const paymentState = useAtom(PaymentAtom)

  const savePaymentType = useAction((paymentType) => SetPaymentType(paymentType))

  const setAppleSubscriptionType = useAction((subscriptionType, paymentTypeId) => {
    return CheckApplePaySupport({
      paymentType: subscriptionType,
      paymentTypeId: paymentTypeId,
      experiments: experiments,
      // bottomSheetExperiment: bottomSheetExperiment,
      // generatorExperiment: generatorExperiment,
      // pageSizeExperiment: pageSizeExperiment
    })
  })

  const setGoogleSubscriptionType = useAction((subscriptionType, paymentTypeId) => {
    return InitGooglePayment({
      paymentType: subscriptionType,
      paymentTypeId: paymentTypeId,
      experiments: experiments,
      // bottomSheetExperiment: bottomSheetExperiment,
      // generatorExperiment: generatorExperiment,
      // pageSizeExperiment: pageSizeExperiment
    })
  })

  const setCardSubscriptionType = useAction((subscriptionType, paymentTypeId) => {
    return InitCardPayment({
      paymentType: subscriptionType,
      paymentTypeId: paymentTypeId
    })
  })

  const setPaymentFinallyInitialized = useAction(() => PaymentReady())

  const setSubscriptionType = (subscriptionType, paymentType) => {
    if (paymentType !== null) {
      const id = paymentType.id
      setAppleSubscriptionType(subscriptionType, id)
      setGoogleSubscriptionType(subscriptionType, id)
      setCardSubscriptionType(subscriptionType, id)
      setPaymentFinallyInitialized()
    }
  }

  useEffect(() => {
      if (typeof paymentType !== 'undefined' && paymentState.initialized && !paymentState.ready) {
        switch (paymentType) {
          case PaymentType.MonthlySubscription:
            setSubscriptionType(PaymentType.MonthlySubscription, paymentState.monthlySub)
            break
          case PaymentType.WeeklySubscription:
            setSubscriptionType(PaymentType.WeeklySubscription, paymentState.weeklySub)
            break
          case PaymentType.OneTimePayment:
            setSubscriptionType(PaymentType.OneTimePayment, paymentState.oneTimePay)
            break
          case PaymentType.AnnualSubscription:
            setSubscriptionType(PaymentType.AnnualSubscription, paymentState.annualSub)
            break
        }
      }
      return () => {}
    },
    [paymentType, paymentState]
  )
  return { paymentType }
}

export default usePaymentInitializer