//@flow
import React, { useState } from 'react'
import { iubendaID, relativeRoot } from '../../common'
import { Link, useHistory } from 'react-router-dom'
import Iubenda from 'react-iubenda-policy'
import { useUnsubscribe } from '../../effects/unsubscribe'
import { useSignIn } from '../../effects/signIn'
import { useTranslation } from 'react-i18next'
import useLanguages from '../../effects/languages'
import { SubscriberAtom } from '../../atoms/Subscriber'

import { StepsAtom, goToStep } from '../../atoms/Steps'
import { PaymentAtom } from '../../atoms/Payment'

import { useAction, useAtom } from '@reatom/react'

import { gtagEvent } from '../../utils/gtag'
import { UnsubExperiment } from '../../enums/UnsubEnum'
import { useSkipStepExperiment, useUnsubExperiment } from '../../contexts/ExperimentsContext'
import { SkipStepExperimentEnum } from '../../enums/SkipStepExperimentEnum'
import { StepsSkipExperimentAtom, goToStep as goToStepExperiment } from '../../atoms/StepsSkipExperiment'

const appStoreLink = 'https://itunes.apple.com/app/apple-store/id981437721?pt=1076586&ct=bm_other&mt=8'
const googleStoreLink = 'https://play.google.com/store/apps/details?id=com.luxand.app'

// const rootPath = window.location.pathname.toString().includes(`/${i18n.language}/`)
//   ? `${relativeRoot}/${i18n.language}`
//   : `${relativeRoot}`;

export const Header = (props) => {
  const { languages, seoLandings, languageChangeHandler } = useLanguages()
  const { t, i18n } = useTranslation()
  const rootPath = i18n.language === 'en' ? `/` : `/${i18n.language}/`
  const unsubscribeHandler = useUnsubscribe()
  const signInHandler = useSignIn()
  const signInPopUp = signInHandler.signInPopUp()
  const subscriberState = useAtom(SubscriberAtom)
  const history = useHistory()

  const steps = useAtom(StepsAtom)
  const stepsExperiment = useAtom(StepsSkipExperimentAtom)
  const paymentState = useAtom(PaymentAtom)
  const skipStepExperiment = useSkipStepExperiment()

  let showPayScreen = steps.currentStepId === 4 && !paymentState.payed
  let showResultScreen = steps.currentStepId === 4 && paymentState.payed
  if (SkipStepExperimentEnum.skip === skipStepExperiment) {
    showPayScreen = stepsExperiment.currentStepId === 3 && !paymentState.payed
    showResultScreen = stepsExperiment.currentStepId === 3 && paymentState.payed
  }

  const isSubscriberLoaded = subscriberState.subscriber !== null
  const paymentTypeLoaded = paymentState.weeklySub !== null
    || paymentState.oneTimePay !== null
    || paymentState.monthlySub !== null

  const showPreparedPayScreen = isSubscriberLoaded && showPayScreen && paymentTypeLoaded

  const hideMobileMenu = !showPreparedPayScreen && showResultScreen
  const mobileMenuClasses = ['mobile-menu-toggle']
  if (hideMobileMenu) {
    mobileMenuClasses.push('hidden')
  }

  const goToFirstStep = useAction(_ => goToStep(1), [])
  const goToFirstStepExperiment = useAction(_=>goToStepExperiment(1),[])

  const [signIn, setSignIn] = useState(() => {
  })

  React.useEffect(() => {
    setSignIn(() => {
      return signInHandler.showProfilePopUp
    })
    if (subscriberState.loaded) {
      if (subscriberState.subscriber && subscriberState.subscriber.email) {
        setSignIn(() => {
          return history.push
        })
      }
    }
  }, [subscriberState.subscriber])

  React.useEffect(() => {
    const closeMobileMenu = (e) => {
      if (e.target.closest('.mobile-menu-wrap') !== null) {
        const menuToggle = document.querySelector('.mobile-menu-toggle.active')
        menuToggle.click()
      }
    }
    document.addEventListener('click', closeMobileMenu)
    return () => {
      document.removeEventListener('click', closeMobileMenu)
    }
  })

  // let unsubName = t(`header.Profile`);
  const unsubExperiment = useUnsubExperiment()
  const unsubName = t(`experiment.unsub.${unsubExperiment}`)
  const currYear = (new Date()).getFullYear()

  return (
    <>
      {signInPopUp}
      <header className="header"
              id="header">
        <div className="container">
          <Link to={rootPath}
                className={'logo'}
                title={t('header.Baby Face Generator')}
                onClick={()=>{
                  if(SkipStepExperimentEnum.dontSkip === skipStepExperiment) {
                    goToFirstStep()
                  }
                  if(SkipStepExperimentEnum.skip === skipStepExperiment) {
                    goToFirstStepExperiment()
                  }
                }}

          >
            <img src="/img/logo@2x.png"
                 alt={t('header.Baby Face Generator')}
            />
          </Link>
          <h1 className="site-desc">{t('header.What Will Your Baby Look Like?')}</h1>
          <nav className="app-nav">
            <ul className="app-menu">
              <li>
                <a onClick={signInHandler.showSignInPopUp}
                   className="btn inverse"
                >
                  {t('header.Sign In')}<span className="small">{t('header.with your email')}</span>
                </a>
              </li>
            </ul>
          </nav>

          <div className="mobile-menu-wrap">
            <div className="mobile-menu-box">
              <div className="mobile-menu-body">
                <nav className="app-nav mobile">
                  <ul className="app-menu">
                    <span
                      className="app-desctiption"

                    >
                      BabyFaceGenerator analyzes up to 70 facial features of two partners to generate the face of a future baby. BabyFaceGenerator is based on AI technology, however genetics are far more complex than that. Use this site for your entertainment only.
                    </span>
                    {/* <li>
                      <a href={appStoreLink}
                         className="btn inverse with-icon apple"
                         title={t('header.Available on Apple store')}>
                        <span className="bm_icon_apple"/>
                        <span className="small">{t('header.Available on')}</span>{t('header.Apple store')}</a>
                    </li>
                    <li>
                      <a href={googleStoreLink}
                         className="btn inverse with-icon"
                         title={t('header.Android App on Google Play')}>
                        <span className="bm_icon_google-play"/>
                        <span className="small">{t('header.Android App on')}</span>{t('header.Google Play')}</a>
                    </li> */}

                    <li>
                      <div className="pointer-description-block">
                        <span className="pointer-description">
                          Available in different languages:<br/>
                        </span>
                      </div>
                      {(languages.map((lang, idx) => {
                        return <div className="pointer-wrapper"
                                    key={`top_lang_${lang.url}`}>
                          <a
                            href={lang.url === 'en' ? '/' : `/${lang.url}/`}
                            className={`pointer`}
                            onClick={(e) => {
                              e.preventDefault()
                              languageChangeHandler(lang.url)
                            }}>{lang.name.toUpperCase()} {idx !== languages.length - 1 && (
                            <span style={{ fontWeight: '100' }}><br/></span>)}
                          </a>
                        </div>
                      }))}
                    </li>
                    {seoLandings.length > 0 && <li>
                      {(seoLandings.map((landing, idx) => {
                        return <a key={`land_${idx}`}
                                  href={`/${landing.url}/`}
                                  className={`pointer`}
                                  onClick={(e) => {
                                    e.preventDefault()
                                    languageChangeHandler(landing.url)
                                  }}>{landing.name.toUpperCase()} {idx !== seoLandings.length - 1 && (
                          <span style={{ fontWeight: '100' }}>&nbsp;|&nbsp;</span>)} </a>
                      }))}
                    </li>}
                  </ul>
                </nav>
                <nav className="mobile-menu">
                  <ul>
                    <li>
                      <Link to={`${relativeRoot}/`}>{t('header.Home')}</Link>
                    </li>
                    <li>
                      <a onClick={() => {
                        signInHandler.showSignInPopUp()
                      }}
                         className="pointer">
                        {t('header.Sign In mobile')}
                      </a>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/about-us/`}
                      >{t('header.About Us')}</Link>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/contact-us/`}
                      >{t('header.Contact Us')}</Link>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/feedback/`}>{t('header.Feedback')}</Link>
                    </li>
                    <li>
                      <Iubenda id={iubendaID}
                               type={'privacy'}
                               styling={'nostyle'}
                      >{t('header.Privacy Policy')}</Iubenda>
                    </li>
                    <li>
                      <Link to={`${relativeRoot}/terms-of-service/`}>{t('header.Terms of Service')}</Link>
                    </li>
                    <li>
                      <Iubenda id={iubendaID}
                               type={'cookie'}
                               styling={'nostyle'}
                      >{t('header.Cookie Policy')}</Iubenda>
                    </li>
                    <li>
                      <a onClick={() => {
                        signIn('/profile')
                        gtagEvent({
                          action: 'manage account click',
                          category: 'manage account',
                          label: 'manage-mobile-menu'
                        })
                      }}
                         className="pointer">
                        {unsubName ? unsubName : t('header.Profile')}
                      </a>
                      {/*<a className={"pointer"}*/}
                      {/*   title={t('header.Cancel Subscription')}*/}
                      {/*   onClick={()=>{unsubscribeHandler.handleUnsubscribe('menu');}}*/}
                      {/*>{t('header.Cancel Subscription')}</a>*/}
                    </li>
                    <li>
                      <a href="#"
                         onClick={(e) => {
                           e.preventDefault()
                         }}
                         className="iubenda-ccpa-opt-out"
                      >{t('header.Do Not Sell My Personal Information')}</a>
                    </li>
                    <li></li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-menu-footer">
                <p className="copyright">{t('header.Copyright')} © {currYear}. {t('header.All rights received')}.</p>
              </div>
            </div>
          </div>

          <div className={mobileMenuClasses.join(' ')}>
            <span/>
          </div>
        </div>
      </header>
    </>
  )
}