const es = {
  "header": {
    "What Will Your Baby Look Like?": "¿Cómo será tu bebé?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Iniciar sesión",
    "Sign In mobile": "Iniciar sesión",
    "with your email": "con su email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Home",
    "Contact Us": "Contacto",
    "Feedback": "Comentarios",
    "Privacy Policy": "Política de privacidad",
    "Terms of Service": "Condiciones del servicio",
    "Cookie Policy": "Política de cookies",
    "Do Not Sell My Personal Information": "No vender mi información personal",
    "Cancel Subscription": "Cancelar suscripción",
    "Copyright": "Copyright",
    "All rights received": "Todos los derechos recibidos",
    "About Us": "Sobre nosotros",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "Sobre nosotros",
    "Copyright": "Copyright",
    "All rights received": "Todos los derechos recibidos",
    "Contact Us": "Contacto",
    "Feedback": "Comentarios",
    "Privacy Policy": "Política de privacidad",
    "Terms of Service": "Condiciones del servicio",
    "Cookie Policy": "Política de cookies",
    "Do Not Sell My Personal Information": "No vender mi información personal",
    "Unsubscribe": "Cancelar suscripción",
    "Sign In": "Registrarse",
  },
  "stepsToolbar": {
    "Previous": "Anterior",
    "Next": "Siguiente",
    "Step": "Paso",
    "Try For Free": "Pruébalo gratis",
    "See Your Baby": "Descubre cómo será tu bebé",
  },
  "parent": {
    "Upload your photo": "Sube tu foto",
    "Upload your partner's photo": "Sube una foto de tu pareja",
    "leftBox": {
      "Upload your photo to generate a baby": "Sube tu foto para generar un bebé",
      "Upload your partner's photo": "Sube una foto de tu pareja",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Empieza por hacerte la foto o hazte un selfie",
      "The face should be frontal, clearly visible, no glasses": "La cara tiene que estar mirando a la cámara, debe verse con claridad y no puedes llevar gafas puestas.",
      "The face should occupy at least 70% of the photo": "La cara debe ocupar al menos un 70% de la foto",
      "Proceed by uploading your partner's photo below": "Ahora sube la foto de tu pareja a continuación",
      "Face should be frontal, clearly visible, no glasses": "La cara tiene que estar mirando a la cámara, debe verse con claridad y no puedes llevar gafas puestas.",
      "The face in the photo should occupy at least 70%": "La cara debe ocupar como mínimo el 70% de la foto",
    },
    "buttons": {
      "Take a Selfie": "Hazte un selfie",
      "OR CHOOSE A CELEBRITY": "o elige a un famoso",
      "Choose a celebrity": "Elige a un famoso",
    },
    "dnd": {
      "Drag & drop your photo": "Arrastra y suelta tu foto",
      "or": "o",
      "Upload Image": "Subir imagen",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Admite JPG, JPG200, PNG y el tamaño máximo de la imagen es de 8 MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Selecciona a un famoso",
      "Search for celebrity": "Buscar un famoso",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Sube a tu pareja',
      'Move to baby settings': 'Ir a la configuración del bebé',
      'Show me my Future Baby': 'Muéstrame a mi futuro bebé',
      'See Your Baby': 'Descubre cómo será tu bebé',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Configuración del bebé",
      "What will your baby look like?": "¿Cómo será tu bebé?",
      "Personalize your baby with a name, gender and skin tone": "Personaliza tu bebé con el nombre, el sexo y el tono de la piel",
      "Your picture": "Tu foto",
      "Your partner's picture": "La foto de tu pareja",
    },
    "Baby's name": "Nombre del bebé",
    "Enter baby's name": "Introduce el nombre del bebé",
    "I hope for a": "Espero que sea",
    "gender": {
      "Baby girl": "Niña",
      "Baby boy": "Niño",
      "Either": "Me da igual",
    },
    "Select skin tone": "Selecciona el tono de la piel",
    "skin": {
      "Auto-detect": "Detección automática",
      "(90% accurate)": "",
      "Light": "Claro",
      "Medium": "Medio",
      "Dark": "Oscuro",
      "Asian": "Asiático",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "Acceso premium de 3 días GRATUITO",
      "See your baby for a small [amount] one-time fee": "Descubre cómo será tu bebé por una pequeña tarifa única de {{amount}}$",
      "See your future baby!": "¡Descubre a tu futuro bebé!",
      "See your baby and generate more babies": "Descubre cómo será tu bebé y genera más bebés",
      "Save, download and share your babies": "Guarda, descarga y comparte tus bebés",
      "Includes name generator & parenting lessons": "Incluye generador de nombres y lecciones de crianza",
    },
    "flower": {
      "Try For Free": "Pruébalo gratis",
      "See Your Baby": "Descubre cómo será tu bebé",
      "1-day access": "un acceso de 1 día a tus",
      "3-day free access": "un acceso gratuito de 3 días a tus ",
      // "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!",
      "Then [amount] weekly, cancel anytime!": "¡Después, la cuota será de {{amount}}$ a la semana y lo podrás cancelar en cualquier momento!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "¡Después, la cuota será de {{amount}}$ al año y lo podrás cancelar en cualquier momento!\n",
      "See your future baby!": "¡Descubre a tu futuro bebé!",
      "Click": "Haz clic en",
      "to get a": "para conseguir",
      "to your pictures and premium features": "fotos y a las funciones premium",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Pruébalo gratis",
    "See Your Baby": "Descubre cómo será tu bebé",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator cuenta con el respaldo de una tecnología de reconocimiento facial de última generación y permite que los usuarios generen fotos de bebés futuros subiendo las fotos de los dos miembros de la pareja. BabyFaceGenerator ofrece a los usuarios una suscripción de prueba gratuita de 3 días para acceder a todas las funciones premium, que incluyen, a modo de ejemplo, mostrar la imagen de un bebé generado, lo que permite generar un número ilimitado de bebés, guardarlos, descargarlos y compartirlos.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator cuenta con el respaldo de una tecnología de reconocimiento facial de última generación y permite que los usuarios generen fotos de bebés futuros subiendo las fotos de los dos miembros de la pareja. BabyFaceGenerator ofrece a los usuarios una suscripción de prueba gratuita de 3 días para acceder a todas las funciones premium, que incluyen, a modo de ejemplo, mostrar la imagen de un bebé generado, lo que permite generar un número ilimitado de bebés, guardarlos, descargarlos y compartirlos.",
      "part2": " Los primeros 3 días de la suscripción son gratuitos y su coste es de 0 $; una vez transcurridos esos 3 días (si no se cancela la suscripción), se cargará al usuario  {{amount}}$ por semana de forma periódica. Los pagos de suscripción periódicos se efectuarán a través de Apple Pay o Google Pay (dependiendo del método utilizado para suscribirse a una versión de prueba gratuita) cuando finalice el periodo de prueba gratuito de 3 días. La suscripción se renueva automáticamente de forma semanal ({{amount}} $/semana) a menos que se cancele como mínimo 24 horas antes de que finalice el periodo vigente en ese momento. Se realizará el cargo de renovación en tu cuenta 24 horas antes de que finalice el periodo vigente en ese momento.",
      // "part3": "Este servicio debe usarse solo para entretenimiento, y las fotos de bebés generadas no tienen nada en común con la genética de la vida real.",
      "part4": "Al acceder o utilizar el servicio, usted acepta cumplir y estar sujeto a los Términos de servicio.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator cuenta con el respaldo de una tecnología de reconocimiento facial de última generación y permite que los usuarios generen fotos de bebés futuros subiendo las fotos de los dos miembros de la pareja. BabyFaceGenerator ofrece a los usuarios acceso de 1 un día a todas las funciones premium, que incluyen, a modo de ejemplo, mostrar la imagen de un bebé generado, lo que permite generar un número ilimitado de bebés, guardarlos, descargarlos y compartirlos. El acceso premium de 1 día tiene un precio de 2,99 $ (tarifa única), SIN suscripciones NI tarifas ocultas. El pago se cargará una vez a través de Apple Pay o Google Pay (dependiendo del método que se use para realizarlo)",
      "unSub": "En caso de que hayas utilizado una suscripción de prueba gratuita, haz clic aquí -",
      "unSub2": "Detener suscripción/cancelar suscripción.",
    },
    "annual": {
      "part1": "BabyFaceGenerator cuenta con el respaldo de una tecnología de reconocimiento facial de última generación y permite que los usuarios generen fotos de bebés futuros subiendo las fotos de los dos miembros de la pareja. BabyFaceGenerator ofrece a los usuarios una suscripción de prueba gratuita de 3 días para acceder a todas las funciones premium, que incluyen, a modo de ejemplo, mostrar la imagen de un bebé generado, lo que permite generar un número ilimitado de bebés, guardarlos, descargarlos y compartirlos.",
      "part2": "Los primeros 3 días de la suscripción son gratuitos y su coste es de 0 $; una vez transcurridos esos 3 días (si no se cancela la suscripción), se cargará al usuario {{amount}}$ por año de forma periódica. Los pagos de suscripción periódicos se efectuarán a través de Apple Pay o Google Pay (dependiendo del método utilizado para suscribirse a una versión de prueba gratuita) cuando finalice el periodo de prueba gratuito de 3 días. La suscripción se renueva automáticamente de forma anual ({{amount}} $/año) a menos que se cancele como mínimo 24 horas antes de que finalice el periodo vigente en ese momento. Se realizará el cargo de renovación en tu cuenta 24 horas antes de que finalice el periodo vigente en ese momento.",
      "part3": "Este servicio debe usarse solo para entretenimiento, y las fotos de bebés generadas no tienen nada en común con la genética de la vida real.",
      "part4": "Al acceder o utilizar el servicio, usted acepta cumplir y estar sujeto a los Términos de servicio.",
    },
    "signIn": "Iniciar sesión",
    "signIn2": "en su cuenta",
    "contactUs": "Ponte en contacto", // эта и следующая строка переведены в одну, разделить их адекватно при участии того же переводчика у меня не получается, проси перевода отдельного от своих переводчиков
    "contactUs2": "con nosotros si necesitas ayuda o si tienes alguna pregunta o quieres solicitarnos algo",
    "unSub": "Puedes cancelar la suscripción en cualquier momento haciendo clic aquí",
    "unSub2": "Detener suscripción premium / Cancelar suscripción",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Ingrese el email asociado con su cuenta BabyFaceGenerator para iniciar sesión",
    "Enter your email address": "Ingrese su email",
    "Sign In": "Registrarse",
  },
  "EmailPopup": {
    "Important": "Importante",
    "enter your email address to see a baby and manage your account": "ingrese su email para ver un bebé y administrar su cuenta",
    "Enter a valid email address": "Introduzca el email válido",
    "Email not valid": "Email not valid",
    "Save email": "Guardar el email",
  },
  "meta": {
    "title": "Creador de bebés y generador de caras de bebés",
    "description": "¿Cómo será mi bebé? ¡Sube fotos tuyas y de tu pareja y podrás ver a tu bebé en cuestión de segundos!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Sube tu foto",
        "Upload your partner's photo": "2. Sube una foto de tu pareja",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Sube tu foto para generar un bebé",
          "Upload your partner's photo": "2. Sube una foto de tu pareja",
          "Start by uploading your photo or taking a selfie": "Empieza por hacerte la foto o hazte un selfie",
          "The face should be frontal, clearly visible, no glasses": "La cara tiene que estar mirando a la cámara, debe verse con claridad y no puedes llevar gafas puestas.",
          "The face should occupy at least 70% of the photo": "La cara debe ocupar al menos un 70% de la foto",
          "Proceed by uploading your partner's photo below": "Ahora sube la foto de tu pareja a continuación",
          "Face should be frontal, clearly visible, no glasses": "La cara tiene que estar mirando a la cámara, debe verse con claridad y no puedes llevar gafas puestas.",
          "The face in the photo should occupy at least 70%": "La cara debe ocupar como mínimo el 70% de la foto",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Configuración del bebé",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. ¡Descubre a tu futuro bebé!",
        }
      },
    }
  }
}
export default es;