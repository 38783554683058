import React, {useContext} from "react";
import {useSteps} from "../effects/steps";

const FinalStepContext = React.createContext()
const NextStepStateContext = React.createContext()
const ClickNextHandleContext = React.createContext()
const PrevStepStateContext = React.createContext()
const ClickBackHandleContext = React.createContext()
const CurrentStepContext = React.createContext()
const StepsLengthContext = React.createContext()

export function useFinalStep() {
  return useContext(FinalStepContext)
}

export function useNextStep() {
  return useContext(NextStepStateContext)
}

export function usePrevStep() {
  return useContext(PrevStepStateContext)
}

export function useClickNextHandle() {
  return useContext(ClickNextHandleContext)
}

export function useClickBackHandle() {
  return useContext(ClickBackHandleContext)
}

export function useCurrentStep() {
  return useContext(CurrentStepContext)
}

export function useStepsLength() {
  return useContext(StepsLengthContext)
}

export function StepsProvider({children}) {
  const steps = useSteps()

  const handleNext = steps.currentStep.id === (steps.steps.length - 1)
    ? steps.handleClickGenerateKid
    : steps.handleClickNext;

  return (
    <StepsLengthContext.Provider value={steps.steps.length}>
      <CurrentStepContext.Provider value={steps.currentStep}>
        <FinalStepContext.Provider value={steps.lastStep}>
          <NextStepStateContext.Provider value={steps.nextAvailable}>
            <ClickNextHandleContext.Provider value={handleNext}>
              <PrevStepStateContext.Provider value={steps.prevAvailable}>
                <ClickBackHandleContext.Provider value={steps.handleClickBack}>
                  {children}
                </ClickBackHandleContext.Provider>
              </PrevStepStateContext.Provider>
            </ClickNextHandleContext.Provider>
          </NextStepStateContext.Provider>
        </FinalStepContext.Provider>
      </CurrentStepContext.Provider>
    </StepsLengthContext.Provider>
  )
}