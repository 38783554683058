const whatWillOurBabyLookLike = {
  "header": {
    "What Will Your Baby Look Like?": "What Will Our Baby Look Like? Surprise your partner!",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Sign In",
    "Sign In mobile": "Sign In",
    "with your email": "with your email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Home",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Cancel Subscription": "Cancel Subscription",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "About Us": "About Us",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "About Us",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Unsubscribe": "Unsubscribe",
    "Sign In": "Sign In",
  },
  "stepsToolbar": {
    "Previous": "Previous",
    "Next": "Next",
    "Step": "Step",
    "Try For Free": "Try For Free",
    "See Your Baby": "See Your Baby",
  },
  "parent": {
    "Upload your photo": "Upload your photo",
    "Upload your partner's photo": "Upload your partner's photo",
    "leftBox": {
      "Upload your photo to generate a baby": "Start by uploading your photo first",
      "Upload your partner's photo": "Upload your partner's photo",
      "Good Photo": "What will our baby look like - how a good photo looks",
      "Bad Photo": "What will our baby look like - how a bad photo looks",
      "Start by uploading your photo or taking a selfie": "Upload photo from a library or take a selfie",
      "The face should be frontal, clearly visible, no glasses": "Face looks at the camera and is clearly visible",
      "The face should occupy at least 70% of the photo": "Your face should occupy not less than 70% of the image",
      "Proceed by uploading your partner's photo below": "Proceed by uploading your partner's photo below",
      "Face should be frontal, clearly visible, no glasses": "Face should be frontal, clearly visible, no glasses",
      "The face in the photo should occupy at least 70%": "The face in the photo should occupy at least 70%",
    },
    "buttons": {
      "Take a Selfie": "Take a Selfie",
      "OR CHOOSE A CELEBRITY": "OR CHOOSE A CELEBRITY",
      "Choose a celebrity": "Choose a celebrity",
    },
    "dnd": {
      "Drag & drop your photo": "Drag & drop your photo",
      "or": "or",
      "Upload Image": "Upload Image",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Supports JPG, JPG200, PNG and max image size of 8MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Select Celebrity",
      "Search for celebrity": "Search for celebrity",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Upload your partner',
      'Move to baby settings': 'Move to baby settings',
      'Show me my Future Baby': 'Show me my Future Baby',
      'See Your Baby': 'See Your Baby',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Baby settings",
      "What will your baby look like?": "What will your baby look like?",
      "Personalize your baby with a name, gender and skin tone": "Personalize your baby with a name, gender and skin tone",
      "Your picture": "Your picture",
      "Your partner's picture": "Your partner's picture",
    },
    "Baby's name": "Baby's name",
    "Enter baby's name": "Enter baby's name",
    "I hope for a": "I hope for a",
    "gender": {
      "Baby girl": "Baby girl",
      "Baby boy": "Baby boy",
      "Either": "Either",
    },
    "Select skin tone": "Select skin tone",
    "skin": {
      "Auto-detect": "Auto-detect",
      "(90% accurate)": "(90% accurate)",
      "Light": "Light",
      "Medium": "Medium",
      "Dark": "Dark",
      "Asian": "Asian",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3-day premium access is FREE",
      "See your baby for a small [amount] one-time fee": "See your baby for a small ${{amount}} one-time fee",
      "See your future baby!": "See your future baby!",
      "See your baby and generate more babies": "See your baby and generate more babies",
      "Save, download and share your babies": "Save, download and share your babies",
    },
    "flower": {
      "Try For Free": "TRY FOR FREE",
      "See Your Baby": "SEE YOUR BABY",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "See your future baby!": "See your future baby!",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "TRY FOR FREE",
    "See Your Baby": "SEE YOUR BABY",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/week on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a weekly basis (${{amount}}/week) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 1-day access to all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies. 1-day premium access costs {{amount}} one-time fee, NO subscriptions, NO hidden fees. Payment will be charged once via Apple Pay or Google Pay (depending on the method used to make a payment)",
      "unSub": "In case you used a free trial subscription click here - ",
      "unSub2": "stop subscription/cancel membership",
    },
    "signIn": "Sign In",
    "signIn2": "to your account",
    "contactUs": "Contact us",
    "contactUs2": "for support, or in case you have any questions or requests.",
    "unSub": "You can cancel your subscription anytime by clicking here:",
    "unSub2": "Stop premium subscription / Unsubscribe",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Enter the email address associated with your BabyFaceGenerator account to sign in",
    "Enter your email address": "Enter your email address",
    "Sign In": "Sign In",
  },
  "EmailPopup": {
    "Important": "Important",
    "enter your email address to see a baby and manage your account": "enter your email address to see a baby and manage your account",
    "Enter a valid email address": "Enter a valid email address",
    "Email not valid": "Email not valid",
    "Save email": "Save email",
  },
  "meta": {
    "title": "What Will Our Baby Look Like | Baby Generator",
    "description": "What will our baby look like? Generate a baby picture by taking a selfie, and uploading a photo of your partner.",
  },
  "seo": {
    "pic1alt": 'What will our baby look like - sample 1',
    "pic2alt": 'What will our baby look like - sample 2',
    "pic3alt": 'What will our baby look like - sample 3',
    "pic4alt": 'What will our baby look like - sample 4',
    "small": {
      "part1": "If you are in a serious relationship, or have a friend you have a crush on, you definitely asked yourself one tricky question - “what will our baby look like?”. The best thing is that modern technologies are powerful enough to answer this question in less than a minute.",
      "part2": "Take a selfie, upload a picture of your friend or a partner and let the computer generate a photo of your future baby. Easy as that!",
      "h2": "What Will Our Baby Look Like",
      "part3": "You may want to surprise your partner or add a photo to a family album, no matter what the reasons are, this web platform does what it does - generates baby pictures by detecting faces on uploaded photos and analyzing more than 70 unique markers on faces of each partner. Nothing but science and computer power. As you can see and try yourself, predicting the appearance of a baby and answering what will our baby look like is quite simple!",
      "part4": "By the way, we have collected faces of all kinds of famous celebrities, so you can pick any of them and see what your baby will look like.",
    },
  },
}
export default whatWillOurBabyLookLike;