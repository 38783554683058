// @flow

import {declareAtom, declareAction} from "@reatom/core";
import {celebs} from "../utils/celebs";
import axios from "axios";
import {api} from "../common";
import {ErrorLog} from "./Debug";

export const InitCelebs = declareAction(
  'initCelebsAction',
  (payload, store) => {
    store.dispatch(InitCelebsList(celebs));
  }
);
const LogCelebQuery = declareAction(
  'logCelebQueryAction',
  (payload, store) => {
    axios.post(
      `${api}/logger/celeb`,
      {query: payload},
    )
      .then(response => {
        // console.log(response);
      })
      .catch(err => {
        store.dispatch(ErrorLog(err));
        console.error(err)
      });
  }
);
export const InitCelebsList = declareAction('initCelebsListAction')
export const SearchCelebs = declareAction(
  'searchCelebsAction',
  (payload, store) => {
    store.dispatch(LogCelebQuery(payload));
    const filteredCelebs = store.getState(CelebsAtom).default.filter(celeb => {
      if (celeb.name.toLowerCase().includes(payload)) {
        return celeb;
      }
    });
    if (filteredCelebs.length > 0) {
      store.dispatch(UpdateCelebsList(filteredCelebs));
    }
  }
)
export const UpdateCelebsList = declareAction('updateCelebsListAction')
export const ResetCelebsList = declareAction('resetCelebsListAction')

export const CelebsAtom = declareAtom(
  'celebsAtom',
  {default: [], filtered: []},
  on => [
    on(InitCelebsList, (state, payload) => {
      return {default: payload, filtered: payload};
    }),
    on(UpdateCelebsList, (state, payload) => {
      return {...state, filtered: payload};
    }),
    on(ResetCelebsList, (state, payload) => {
      return {...state, filtered: state.default};
    })
  ]);