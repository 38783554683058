import React, {useContext} from 'react'
import {useAtom} from "@reatom/react";
import {WalletTypeAtom} from "../atoms/WalletType";

const WalletTypeContext = React.createContext()

export function useWalletType() {
  return useContext(WalletTypeContext)
}

export function WalletTypeProvider({children}) {
  const walletType = useAtom(WalletTypeAtom);
  return (
    <WalletTypeContext.Provider value={walletType.walletType}>
      {children}
    </WalletTypeContext.Provider>
  )
}