const tr = {
  "header": {
    "What Will Your Baby Look Like?": "Bebeğiniz Nasıl Görünecek?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "E-posta",
    "Sign In mobile": "Kayıt olmak",
    "with your email": "adresinizle kayıt olmak",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Ana Sayfa",
    "Contact Us": "Bizimle İletişime Geçin",
    "Feedback": "Geri Bildirim",
    "Privacy Policy": "Gizlilik İlkesi",
    "Terms of Service": "Hizmet Koşulları",
    "Cookie Policy": "Çerez İlkesi",
    "Do Not Sell My Personal Information": "Kişisel Bilgilerimi Satma",
    "Cancel Subscription": "Aboneliği iptal et",
    "Copyright": "Bizimle İletişime Geçin",
    "All rights received": "Tüm hakları saklıdır",
    "About Us": "Hakkımızda",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "Hakkımızda",
    "Copyright": "Bizimle İletişime Geçin",
    "All rights received": "Tüm hakları saklıdır",
    "Contact Us": "Bizimle İletişime Geçin",
    "Feedback": "Geri Bildirim",
    "Privacy Policy": "Gizlilik İlkesi",
    "Terms of Service": "Hizmet Koşulları",
    "Cookie Policy": "Çerez İlkesi",
    "Do Not Sell My Personal Information": "Kişisel Bilgilerimi Satma",
    "Unsubscribe": "Abonelikten Çık",
    "Sign In": "Kayıt olmak",
  },
  "stepsToolbar": {
    "Previous": "Geri",
    "Next": "İleri",
    "Step": "Adım",
    "Try For Free": "Ücretsiz deneyin",
    "See Your Baby": "Bebeğinizi görün",
  },
  "parent": {
    "Upload your photo": "Fotoğrafınızı yükleyin",
    "Upload your partner's photo": "Partnerinizin fotoğrafını yükleyin",
    "leftBox": {
      "Upload your photo to generate a baby": "Bir bebek oluşturmak için fotoğrafınızı yükleyin",
      "Upload your partner's photo": "Partnerinizin fotoğrafını yükleyin",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "İlk önce fotoğrafınızı yükleyin veya biz özçekim yapın",
      "The face should be frontal, clearly visible, no glasses": "Yüzünüzün önden net bir şekilde görülmesi ve yüzünüzde gözlük olmaması gerekir",
      "The face should occupy at least 70% of the photo": "Yüzünüz, fotoğrafın en az %70'ini kaplamalıdır",
      "Proceed by uploading your partner's photo below": "Aşağıya partnerinizin fotoğrafını yükleyerek devam edin",
      "Face should be frontal, clearly visible, no glasses": "Yüzünün önden net bir şekilde görülmesi ve yüzünde gözlük olmaması gerekir",
      "The face in the photo should occupy at least 70%": "Fotoğraftaki yüz, fotoğrafın en az %70'ini kaplamalıdır",
    },
    "buttons": {
      "Take a Selfie": "Özçekim yapın",
      "OR CHOOSE A CELEBRITY": "veya bir ünlü seçin",
      "Choose a celebrity": "Bir ünlü seçin",
    },
    "dnd": {
      "Drag & drop your photo": "Fotoğrafınızı sürükleyip bırakın",
      "or": "veya",
      "Upload Image": "Resim yükleyin",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "JPG, JPG200, PNG ve maksimum 8 MB resim boyutunu destekler",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Ünlü seçin",
      "Search for celebrity": "Ünlü arayın",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Partnerinizi yükleyin',
      'Move to baby settings': 'Bebek ayarlarına geçin',
      'Show me my Future Baby': 'Gelecekteki bebeğimi göster',
      'See Your Baby': 'Bebeğinizi görün',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Bebek ayarları",
      "What will your baby look like?": "Bebeğiniz Nasıl Görünecek?",
      "Personalize your baby with a name, gender and skin tone": "Bebeğinizin adını, cinsiyetini ve ten rengini belirleyin",
      "Your picture": "Sizin resminiz",
      "Your partner's picture": "Partnerinizin resmi",
    },
    "Baby's name": "Bebeğin adı",
    "Enter baby's name": "Bebeğin adını girin",
    "I hope for a": "İstediğim bebek",
    "gender": {
      "Baby girl": "Kız",
      "Baby boy": "Erkek",
      "Either": "Fark etmez",
    },
    "Select skin tone": "Ten rengi seçin",
    "skin": {
      "Auto-detect": "Otomatik algıla",
      "(90% accurate)": "",
      "Light": "Açık",
      "Medium": "Orta",
      "Dark": "Koyu",
      "Asian": "Asyalı",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3 günlük premium erişim ÜCRETSİZDİR",
      "See your baby for a small [amount] one-time fee": "${{amount}}'lık tek seferlik küçük bir ücret karşılığında bebeğinizi görün",
      "See your future baby!": "Gelecekteki bebeğinizi görün!",
      "See your baby and generate more babies": "Bebeğinizi görün ve başka bebekler oluşturun",
      "Save, download and share your babies": "Bebeklerinizi kaydedin, indirin ve paylaşın",
      "Includes name generator & parenting lessons": "İsim üreteci ve ebeveynlik derslerini içerir",
    },
    "flower": {
      "Try For Free": "Ücretsiz deneyin",
      "See Your Baby": "Bebeğinizi görün",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": "Ücret daha sonrasında haftalık ${{amount}} olacak, istediğiniz zaman iptal edebilirsiniz!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "Ücret daha sonrasında yıllık ${{amount}} olacak, istediğiniz zaman iptal edebilirsiniz!",
      "See your future baby!": "Gelecekteki bebeğinizi görün!",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "Resimlerinize ve premium özelliklere 1 günlük erişim elde etmek için \"BEBEĞİNİZİ GÖRÜN\" seçeneğine tıklayın.",
      "weekly_sub_fulltext": "Resimlerinize ve premium özelliklere 3 günlük ücretsiz erişim elde etmek için \"ÜCRETSİZ DENEYİN\" seçeneğine tıklayın.",
    },
    "Try For Free": "Ücretsiz deneyin",
    "See Your Baby": "Bebeğinizi görün",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "kullanıcıların iki partnerin fotoğraflarını yükleyerek gelecekteki bebeklerini görmelerine olanak sağlar. BabyFaceGenerator; kullanıcılar için oluşturulan bebeği gösterme, sınırsız sayıda bebek oluşturma, kaydetme, indirme ve bebekleri paylaşma dâhil ancak bunlarla sınırlı olmayan bütün premium özelliklere erişmek için 3 günlük bir deneme aboneliği sunar",
      "part2": "Aboneliğin ilk 3 günü ücretsiz olup kullanıcılardan $0,00 alınır, 3 günden sonra (abonelik iptal edilmezse) kullanıcıdan her hafta düzenli olarak ${{amount}} tahsis edilir. Yinelenen abonelik ödemeleri, 3 günlük deneme sürümü sona erince Apple Pay veya Google Pay yoluyla (ücretsiz denemeye abone olmak için kullanılan yönteme bağlı olarak) tahsis edilir. Abonelik, geçerli dönem sona ermeden en az 24 saat önce iptal edilmediği takdirde her hafta (${{amount}}/hafta) otomatik olarak yenilenir. Yenilenme ücreti, geçerli dönem sona ermeden önceki 24 saat içinde hesabınızdan tahsis edilir",
    },
    "weekly": {
      "part1": "kullanıcıların iki partnerin fotoğraflarını yükleyerek gelecekteki bebeklerini görmelerine olanak sağlar. BabyFaceGenerator; kullanıcılar için oluşturulan bebeği gösterme, sınırsız sayıda bebek oluşturma, kaydetme, indirme ve bebekleri paylaşma dâhil ancak bunlarla sınırlı olmayan bütün premium özelliklere erişmek için 3 günlük bir deneme aboneliği sunar",
      "part2": "Aboneliğin ilk 3 günü ücretsiz olup kullanıcılardan $0,00 alınır, 3 günden sonra (abonelik iptal edilmezse) kullanıcıdan her hafta düzenli olarak ${{amount}} tahsis edilir. Yinelenen abonelik ödemeleri, 3 günlük deneme sürümü sona erince Apple Pay veya Google Pay yoluyla (ücretsiz denemeye abone olmak için kullanılan yönteme bağlı olarak) tahsis edilir. Abonelik, geçerli dönem sona ermeden en az 24 saat önce iptal edilmediği takdirde her hafta (${{amount}}/hafta) otomatik olarak yenilenir. Yenilenme ücreti, geçerli dönem sona ermeden önceki 24 saat içinde hesabınızdan tahsis edilir.",
      // "part3": "Bu hizmet yalnızca eğlence amaçlı kullanılmalıdır ve oluşturulan bebek fotoğraflarının gerçek hayattaki genetikle hiçbir ortak yanı yoktur.",
      "part4": "Hizmete erişerek veya hizmeti kullanarak, Hizmet Şartlarına uymayı ve bunlara tabi olmayı kabul edersiniz.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator, ileri düzeyde bir yüz tanıma teknolojisi tarafından desteklenir ve kullanıcıların iki partnerin fotoğraflarını yükleyerek gelecekteki bebeklerini görmelerine olanak sağlar. BabyFaceGenerator; kullanıcılar için oluşturulan bebeği gösterme, sınırsız sayıda bebek oluşturma, kaydetme, indirme ve bebekleri paylaşma dâhil ancak bunlarla sınırlı olmayan bütün premium özelliklere 1 günlük erişim hakkı sunar. 1 günlük premium erişim tek seferlik olarak ${{amount}}'dır; abonelik YOKTUR, gizli ücretlendirmeler YOKTUR. Ödeme, Apple Pay veya Google Pay yoluyla (ödeme yapmak için kullanılan yönteme bağlı olarak) bir defa tahsis edilir",
      "unSub": "Ücretsiz bir deneme aboneliği kullandıysanız buraya tıklayın; ",
      "unSub2": "aboneliğinizi durdurun/üyeliğinizi iptal edin",
    },
    "annual": {
      "part1": "kullanıcıların iki partnerin fotoğraflarını yükleyerek gelecekteki bebeklerini görmelerine olanak sağlar. BabyFaceGenerator; kullanıcılar için oluşturulan bebeği gösterme, sınırsız sayıda bebek oluşturma, kaydetme, indirme ve bebekleri paylaşma dâhil ancak bunlarla sınırlı olmayan bütün premium özelliklere erişmek için 3 günlük bir deneme aboneliği sunar",
      "part2": "Aboneliğin ilk 3 günü ücretsiz olup kullanıcılardan $0,00 alınır, 3 günden sonra (abonelik iptal edilmezse) kullanıcıdan her yıl düzenli olarak ${{amount}} tahsis edilir. Yinelenen abonelik ödemeleri, 3 günlük deneme sürümü sona erince Apple Pay veya Google Pay yoluyla (ücretsiz denemeye abone olmak için kullanılan yönteme bağlı olarak) tahsis edilir. Abonelik, geçerli dönem sona ermeden en az 24 saat önce iptal edilmediği takdirde her yıl (${{amount}}/yıl) otomatik olarak yenilenir. Yenilenme ücreti, geçerli dönem sona ermeden önceki 24 saat içinde hesabınızdan tahsis edilir.",
      "part3": "Bu hizmet yalnızca eğlence amaçlı kullanılmalıdır ve oluşturulan bebek fotoğraflarının gerçek hayattaki genetikle hiçbir ortak yanı yoktur.",
      "part4": "Hizmete erişerek veya hizmeti kullanarak, Hizmet Şartlarına uymayı ve bunlara tabi olmayı kabul edersiniz.",
    },
    "signIn": "Hesabınıza",
    "signIn2": "giriş yapın",
    "contactUs": "Destek için",
    "contactUs2": "veya herhangi bir sorunuz ya da talebiniz varsa bizimle iletişime geçebilirsiniz",
    "unSub": "Buraya tıklayarak aboneliğinizi istediğiniz zaman iptal edebilirsiniz",
    "unSub2": "Premium aboneliği durdur / Abonelikten çık",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Oturum açmak için BabyFaceGenerator hesabınızla ilişkili e-posta adresini girin",
    "Enter your email address": "E-posta adresinizi giriniz",
    "Sign In": "Kayıt olmak",
  },
  "EmailPopup": {
    "Important": "Önemli",
    "enter your email address to see a baby and manage your account": "Bir bebeği görmek ve hesabınızı yönetmek için e-posta adresinizi girin",
    "Enter a valid email address": "Geçerli bir e-posta adresi girin",
    "Email not valid": "Email not valid",
    "Save email": "E-postayı kaydet",
  },
  "meta": {
    "title": "Bebek Yapıcı ve Bebek Yüzü Oluşturucu",
    "description": "Bebeğim nasıl görünecek? Kendinizin ve partnerinizin fotoğraflarını yükledikten sonra saniyeler içinde bir bebek oluşturabilirsiniz!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Fotoğrafınızı yükleyin",
        "Upload your partner's photo": "2. Partnerinizin fotoğrafını yükleyin",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Bir bebek oluşturmak için fotoğrafınızı yükleyin",
          "Upload your partner's photo": "2. Partnerinizin fotoğrafını yükleyin",
          "Start by uploading your photo or taking a selfie": "İlk önce fotoğrafınızı yükleyin veya biz özçekim yapın",
          "The face should be frontal, clearly visible, no glasses": "Yüzünüzün önden net bir şekilde görülmesi ve yüzünüzde gözlük olmaması gerekir",
          "The face should occupy at least 70% of the photo": "Yüzünüz, fotoğrafın en az %70'ini kaplamalıdır",
          "Proceed by uploading your partner's photo below": "Aşağıya partnerinizin fotoğrafını yükleyerek devam edin",
          "Face should be frontal, clearly visible, no glasses": "Yüzünün önden net bir şekilde görülmesi ve yüzünde gözlük olmaması gerekir",
          "The face in the photo should occupy at least 70%": "Fotoğraftaki yüz, fotoğrafın en az %70'ini kaplamalıdır",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Bebek ayarları",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Gelecekteki bebeğinizi görün!",
        }
      },
    }
  }
}

export default tr