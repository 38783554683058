const pl = {
  "header": {
    "What Will Your Baby Look Like?": "Jak będzie wyglądać Twoje dziecko?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Zaloguj się",
    "Sign In mobile": "Zaloguj się",
    "with your email": "za pomocą swojego e-mail",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Strona główna",
    "Contact Us": "Kontakt",
    "Feedback": "Opinie",
    "Privacy Policy": "Polityka prywatności",
    "Terms of Service": "Warunki korzystania",
    "Cookie Policy": "Polityka plików cookie",
    "Do Not Sell My Personal Information": "Nie sprzedawaj moich danych osobowych",
    "Cancel Subscription": "Anuluj subskrypcję",
    "Copyright": "Copyright",
    "All rights received": "Wszelkie prawa zastrzeżone",
    "About Us": "O nas",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "O nas",
    "Copyright": "Copyright",
    "All rights received": "Wszelkie prawa zastrzeżone",
    "Contact Us": "Kontakt",
    "Feedback": "Feedback",
    "Privacy Policy": "Polityka prywatności",
    "Terms of Service": "Warunki korzystania",
    "Cookie Policy": "Polityka plików cookie",
    "Do Not Sell My Personal Information": "Nie sprzedawaj moich danych osobowych",
    "Unsubscribe": "Zrezygnuj z subskrypcji",
    "Sign In": "Zaloguj się",
  },
  "stepsToolbar": {
    "Previous": "Wstecz",
    "Next": "Dalej",
    "Step": "Krok",
    "Try For Free": "Wypróbuj bezpłatnie",
    "See Your Baby": "Zobacz swoje dziecko",
  },
  "parent": {
    "Upload your photo": "Prześlij swoje zdjęcie",
    "Upload your partner's photo": "Prześlij zdjęcie partnera/partnerki",
    "leftBox": {
      "Upload your photo to generate a baby": "Prześlij swoje zdjęcie, aby wygenerować zdjęcie dziecka",
      "Upload your partner's photo": "Prześlij zdjęcie partnera/partnerki",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Zacznij od przesłania zdjęcia lub zrobienia sobie selfie",
      "The face should be frontal, clearly visible, no glasses": "Twarz powinna być skierowana do obiektywu, dobrze widoczna, bez okularów",
      "The face should occupy at least 70% of the photo": "Twarz powinna zajmować co najmniej 70% kadru",
      "Proceed by uploading your partner's photo below": "Kolejnym krokiem jest przesłanie zdjęcia partnera/partnerki",
      "Face should be frontal, clearly visible, no glasses": "Twarz powinna być skierowana do obiektywu, dobrze widoczna, bez okularów",
      "The face in the photo should occupy at least 70%": "Twarz powinna zajmować co najmniej 70% kadru",
    },
    "buttons": {
      "Take a Selfie": "Zrób selfie",
      "OR CHOOSE A CELEBRITY": "lub wybierz znaną osobę",
      "Choose a celebrity": "Wybierz znaną osobę",
    },
    "dnd": {
      "Drag & drop your photo": "Przeciągnij i upuść swoje zdjęcie",
      "or": "lub",
      "Upload Image": "Prześlij zdjęcie",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Akceptowane formaty: JPG, JPG 2000 i PNG; maksymalny rozmiar obrazu: 8 MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Wybierz znaną osobę",
      "Search for celebrity": "Wyszukaj znaną osobę",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Prześlij zdjęcie partnera/partnerki',
      'Move to baby settings': 'Przejdź do ustawień dziecka',
      'Show me my Future Baby': 'Pokaż mi moje przyszłe dziecko',
      'See Your Baby': 'Zobacz swoje dziecko',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Zobacz swoje przyszłe dziecko!',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Ustawienia dziecka",
      "What will your baby look like?": "Jak będzie wyglądać Twoje dziecko?",
      "Personalize your baby with a name, gender and skin tone": "Spersonalizuj swoje dziecko podając imię, płeć i odcień skóry",
      "Your picture": "Twoje zdjęcie",
      "Your partner's picture": "Zdjęcie partnera/partnerki",
    },
    "Baby's name": "Imię dziecka",
    "Enter baby's name": "Wpisz imię dziecka",
    "I hope for a": "I hope for a",
    "gender": {
      "Baby girl": "Dziewczynka",
      "Baby boy": "Chłopczyk",
      "Either": "Obojętnie",
    },
    "Select skin tone": "Wybierz odcień skóry",
    "skin": {
      "Auto-detect": "Wykrywanie",
      "(90% accurate)": "(dokładność 90%)",
      "Light": "Jasny",
      "Medium": "Umiarkowany",
      "Dark": "Ciemny",
      "Asian": "Azjatycki",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "BEZPŁATNY dostęp do funkcji premium przez 3 dni",
      "See your baby for a small [amount] one-time fee": "Zobacz swoje dziecko za niewielką, jednorazową opłatą {{amount}} USD",
      "See your future baby!": "Zobacz swoje przyszłe dziecko!",
      "See your baby and generate more babies": "Zobacz swoje dziecko i wygeneruj więcej zdjęć dzieci",
      "Save, download and share your babies": "Zapisuj, pobieraj i udostępniaj zdjęcia swoich dzieci",
      "Includes name generator & parenting lessons": "Zawiera generator imion i lekcje rodzicielstwa",
    },
    "flower": {
      "Try For Free": "Wypróbuj bezpłatnie",
      "See Your Baby": "Zobacz swoje dziecko",
      "1-day access": "1-dniowy dostęp",
      "3-day free access": "3-dniowy bezpłatny dostęp",
      "Then [amount] weekly, cancel anytime!": "Później opłata wynosi {{amount}} USD tygodniowo",
      "Then [amount] monthly, cancel anytime!": "Następnie opłata wynosi {{amount}} USD miesięcznie",
      "Then [amount] annually, cancel anytime!": "Później opłata wynosi {{amount}} USD rocznie, anuluj w dowolnym momencie!",
      "See your future baby!": "Zobacz swoje przyszłe dziecko!",
      "Click": "Kliknij",
      "to get a": "aby uzyskać",
      "to your pictures and premium features": "do swoich zdjęć i funkcji premium",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Wypróbuj bezpłatnie",
    "See Your Baby": "Zobacz swoje dziecko",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator wykorzystuje zaawansowaną technologię rozpoznawania twarzy, aby generować zdjęcia dzieci na podstawie przesłanych przez użytkownika zdjęć dwojga partnerów. BabyFaceGenerator oferuje użytkownikom bezpłatny 3-dniowy okres próbny z dostępem do wszystkich funkcji premium, które obejmują między innymi wyświetlenie wygenerowanego zdjęcia dziecka i możliwość wygenerowania nieograniczonej liczby zdjęć oraz zapisywanie, pobieranie i udostępnianie zdjęć",
      // тут месяц, я не знаю где перевод месяца
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period",
    },
    "weekly": {
      "part1": "BabyFaceGenerator wykorzystuje zaawansowaną technologię rozpoznawania twarzy, aby generować zdjęcia dzieci na podstawie przesłanych przez użytkownika zdjęć dwojga partnerów. BabyFaceGenerator oferuje użytkownikom bezpłatny 3-dniowy okres próbny z dostępem do wszystkich funkcji premium, które obejmują między innymi wyświetlenie wygenerowanego zdjęcia dziecka i możliwość wygenerowania nieograniczonej liczby zdjęć oraz zapisywanie, pobieranie i udostępnianie zdjęć",
      "part2": "Pierwsze 3 dni subskrypcji są bezpłatne. Po 3 dniach (jeśli subskrypcja nie zostanie anulowana) użytkownik zacznie być obciążany cykliczną opłatą w wysokości {{amount}} USD tygodniowo. Płatności cykliczne będą pobierane przy użyciu Apple Pay lub Google Pay (w zależności od metody użytej przy subskrypcji okresu próbnego) od momentu zakończenia 3-dniowego okresu próbnego. Subskrypcja będzie odnawiana automatycznie co tydzień (za {{amount}} USD), chyba że zostanie anulowana co najmniej 24 godziny przed końcem bieżącego okresu. Konto zostanie obciążone opłatą za odnowienie w ciągu 24 godzin przed końcem bieżącego okresu",
      // "part3": "Ta usługa powinna służyć wyłącznie rozrywce, a wygenerowane zdjęcia dziecka nie mają nic wspólnego z genetyką z prawdziwego życia.",
      "part4": "Uzyskując dostęp do usługi lub korzystając z niej, zgadzasz się przestrzegać i być związanym Warunkami korzystania z usługi.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator wykorzystuje zaawansowaną technologię rozpoznawania twarzy, aby generować zdjęcia dzieci na podstawie przesłanych przez użytkownika zdjęć dwojga partnerów. BabyFaceGenerator oferuje użytkownikom 1-dniowy dostęp do wszystkich funkcji premium, które obejmują między innymi wyświetlenie wygenerowanego zdjęcia dziecka i możliwość wygenerowania nieograniczonej liczby zdjęć oraz zapisywanie, pobieranie i udostępnianie zdjęć. 1-dniowy dostęp do funkcji premium kosztuje {{amount}} USD w postaci jednorazowej opłaty — BEZ subskrypcji i ukrytych opłat. Płatność zostanie pobrana jednorazowo przy użyciu Apple Pay lub Google Pay (w zależności od metody używanej do dokonywania płatności)",
      "unSub": "Jeśli bezpłatny okres próbny został już wykorzystany, kliknij tutaj",
      "unSub2": " wstrzymaj subskrypcję / anuluj członkostwo",
    },
    "annual": {
      "part1": "BabyFaceGenerator wykorzystuje zaawansowaną technologię rozpoznawania twarzy, aby generować zdjęcia dzieci na podstawie przesłanych przez użytkownika zdjęć dwojga partnerów. BabyFaceGenerator oferuje użytkownikom bezpłatny 3-dniowy okres próbny z dostępem do wszystkich funkcji premium, które obejmują między innymi wyświetlenie wygenerowanego zdjęcia dziecka i możliwość wygenerowania nieograniczonej liczby zdjęć oraz zapisywanie, pobieranie i udostępnianie zdjęć",
      "part2": "Pierwsze 3 dni subskrypcji są bezpłatne. Po 3 dniach (jeśli subskrypcja nie zostanie anulowana) użytkownik zacznie być obciążany cykliczną opłatą w wysokości {{amount}} USD rocznie. Płatności cykliczne będą pobierane przy użyciu Apple Pay lub Google Pay (w zależności od metody użytej przy subskrypcji okresu próbnego) od momentu zakończenia 3-dniowego okresu próbnego. Subskrypcja będzie odnawiana automatycznie co rok (za {{amount}} USD), chyba że zostanie anulowana co najmniej 24 godziny przed końcem bieżącego okresu. Konto zostanie obciążone opłatą za odnowienie w ciągu 24 godzin przed końcem bieżącego okresu",
      "part3": "Ta usługa powinna służyć wyłącznie rozrywce, a wygenerowane zdjęcia dziecka nie mają nic wspólnego z genetyką z prawdziwego życia.",
      "part4": "Uzyskując dostęp do usługi lub korzystając z niej, zgadzasz się przestrzegać i być związanym Warunkami korzystania z usługi.",
    },
    "signIn": "Zaloguj się",
    "signIn2": "do konta",
    "contactUs": "Kontakt się z nami",
    "contactUs2": ", jeśli potrzebujesz pomocy lub masz jakiekolwiek pytania albo prośby",
    "unSub": "Subskrypcję możesz anulować w dowolnym momencie, klikając tutaj",
    "unSub2": "Wstrzymaj subskrypcję premium / Zrezygnuj z subskrypcji",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Wprowadź e-mail powiązany z kontem BabyFaceGenerator, aby się zalogować",
    "Enter your email address": "Wpisz swój adres e-mail",
    "Sign In": "Zaloguj się",
  },
  "EmailPopup": {
    "Important": "Ważne",
    "enter your email address to see a baby and manage your account": "wprowadź swój adres e-mail, aby zobaczyć dziecko i zarządzać swoim kontem",
    "Enter a valid email address": "Wpisz poprawny adres e-mail",
    "Email not valid": "Email not valid",
    "Save email": "Zapisz e-mail",
  },
  "meta": {
    "title": "Baby Maker – generator twarzy dziecka",
    "description": "Jak będzie wyglądać moje dziecko? Prześlij zdjęcie swoje oraz partnera/partnerki, aby w kilka sekund zobaczyć, jak będzie wyglądać Wasze dziecko!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Prześlij swoje zdjęcie",
        "Upload your partner's photo": "2. Prześlij zdjęcie partnera/partnerki",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Prześlij swoje zdjęcie, aby wygenerować zdjęcie dziecka",
          "Upload your partner's photo": "2. Prześlij zdjęcie partnera/partnerki",
          "Start by uploading your photo or taking a selfie": "Zacznij od przesłania zdjęcia lub zrobienia sobie selfie",
          "The face should be frontal, clearly visible, no glasses": "Twarz powinna być skierowana do obiektywu, dobrze widoczna, bez okularów",
          "The face should occupy at least 70% of the photo": "Twarz powinna zajmować co najmniej 70% kadru",
          "Proceed by uploading your partner's photo below": "Kolejnym krokiem jest przesłanie zdjęcia partnera/partnerki",
          "Face should be frontal, clearly visible, no glasses": "Twarz powinna być skierowana do obiektywu, dobrze widoczna, bez okularów",
          "The face in the photo should occupy at least 70%": "Twarz powinna zajmować co najmniej 70% kadru",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Ustawienia dziecka",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Zobacz swoje przyszłe dziecko!",
        }
      },
    }
  }
}

export default pl