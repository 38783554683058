import React, {useContext} from "react";
import {useAtom} from "@reatom/react";
import {SubscriberAtom} from "../atoms/Subscriber";

const SubscriberLoadingContext = React.createContext()
const SubscriberContext = React.createContext()

export function useSubscriberLoadingState() {
  return useContext(SubscriberLoadingContext)
}

export function useSubscriber() {
  return useContext(SubscriberContext)
}

export function SubscriberStateProvider({children}) {
  const subscriberState = useAtom(SubscriberAtom);
  const isSubscriberLoaded = subscriberState.subscriber !== null;
  return (
    <SubscriberLoadingContext.Provider value={isSubscriberLoaded}>
      <SubscriberContext.Provider value={subscriberState.subscriber}>
        {children}
      </SubscriberContext.Provider>
    </SubscriberLoadingContext.Provider>
  )
}