// @flow

import React, { useRef } from 'react'
import { useAction, useAtom } from '@reatom/react'
import { useTranslation } from 'react-i18next'
import { GenderEnum, GenderProps } from '../../enums/GenderEnum'
import { SkinEnum, SkinProps } from '../../enums/SkinEnum'
import { changeKidColor, changeKidGender, changeKidName, KidConfigAtom } from '../../atoms/KidConfig'
import { stepDone } from '../../atoms/Steps'
import { GeneratorAtom } from '../../atoms/Generator'
import { useImages } from '../../effects/images'
import { useSteps } from '../../effects/steps'
import usePaymentInitializer from '../../effects/paymentInitializer'
import { BottomSheetExperiment } from '../../enums/BottomSheetEnum'
import useExperimentsResolver from '../../effects/experiments'
import { gtagEvent } from '../../utils/gtag'
import { usePaymentType } from '../../contexts/PaymentTypeContext'
import {
  useBottomSheetExperiment,
  useGeneratorExperiment,
  usePageSizeExperiment, useSkipStepExperiment
} from '../../contexts/ExperimentsContext'
import { PageSizeExperiment } from '../../enums/PageSizeExperimentEnum'

const KidConfig = (props) => {
  const paymentType = usePaymentType()
  const bottomSheetExperiment = useBottomSheetExperiment()
  const generatorExperiment = useGeneratorExperiment()
  const pageSizeExperiment = usePageSizeExperiment()
  const skipStepExperiment = useSkipStepExperiment()
  const paymentTypeInitialized = usePaymentInitializer(
    paymentType,
    {
      bottomSheetExperiment,
      generatorExperiment,
      pageSizeExperiment,
      skipStepExperiment
    }
  )

  const stepsHandler = useSteps()
  const handleStepDone = useAction(() => stepDone({ step: 3 }))
  React.useEffect(() => {
    if (!stepsHandler.currentStep.done) {
      handleStepDone()
    }
  })

  return (
    <>
      <LeftBoxSettings/>
      <div className="right-box">
        <form className="baby-form">
          <div className="baby-form-box baby-form-box__desktop">
            <KidName/>
            <KidGenderAndFrame/>
            <KidSkin/>
          </div>
          <GenerateKidButton/>
        </form>
      </div>
    </>
  )
}

const LeftBoxSettings = () => {
  const { t } = useTranslation()
  const pageSizeExperiment = usePageSizeExperiment()
  return (
    <div className="left-box pr-20">
      <div className="inner-wrap">
        <div className="page-icon-box bm-icon">
          <h2 className="box-title">
            <span className="bm_icon_technical-support title-icon"/>
            {t('experiments.pageShort.kidConfig.leftBox.Baby settings')}
          </h2>
        </div>

        <div className={`content kid-config-content`}>
          <p>{t('kidConfig.leftBox.What will your baby look like?')}</p>
          <p>{t('kidConfig.leftBox.Personalize your baby with a name, gender and skin tone')}.</p>
        </div>

        <Parents/>
      </div>
    </div>
  )
}

const KidName = () => {
  const { t } = useTranslation()
  const config = useAtom(KidConfigAtom)
  const handleNameChange = useAction((e) => changeKidName(e.target.value))
  const nameRef = useRef()

  return (
    <div className="form-row">
      <label className="form-label"
             htmlFor="baby-name">{t(`kidConfig.Baby's name`)}:</label>
      <input
        id="baby-name"
        name="baby-name"
        type="text"
        className="input-style"
        value={config.name}
        placeholder={t(`kidConfig.Enter baby's name`)}
        onChange={handleNameChange}
        onFocus={() => {
          gtagEvent({ action: 'babyname_clicked', category: 'general' })
        }}
        onBlur={(e) => {
          if (e.currentTarget.value.length > 0) {
            gtagEvent({ action: 'babyname_entered', category: 'general' })
          }
        }}
      />
    </div>
  )
}

const GenderCard = (props: any) => {
  const { t } = useTranslation()
  const cardProps = GenderProps[props.gender]
  const classes = ['baby-box']
  if (props.selected) {
    classes.push('selected')
  }
  return (
    <div className={classes.join(' ')} onClick={props.onClick}>
      <span className="baby-inner-box">
        <span className="input-box">
          <span className="input-inner-box">
            <span className="input-inner-cell">
              <span className="input-icon-box"><span className={cardProps.class}/></span>
              <span className="input-title">{t(`kidConfig.gender.${cardProps.title}`)}</span>
            </span>
          </span>
        </span>
      </span>
    </div>
  )
}

const FrameSelector = () => {
  const paymentStatus = useAtom(GeneratorAtom)
  const frameCardClass = ['form-col', 'col-1']
  if (!paymentStatus.payed) {
    frameCardClass.push('no-display')
  }
  return (
    <div className={frameCardClass.join(' ')}>
      <label className="form-label" htmlFor="baby-frame">Frame:</label>
      <div className="inner-form-row">
        <div className="baby-box">
          <span className="baby-inner-box">
            <a href="#choose-frame-form" className="popup-with-form">
              <span className="input-box">
                <span className="input-inner-box">
                  <span className="input-inner-cell">
                    <span className="input-icon-box"><span className="bm_icon_frame"/></span>
                    <span className="input-title inverse">select...</span>
                  </span>
                </span>
              </span>
            </a>
          </span>
        </div>
      </div>
    </div>
  )
}

const GenderCards = () => {
  const { t } = useTranslation()
  const config = useAtom(KidConfigAtom)
  const handleGenderChange = useAction(gender => changeKidGender(gender))
  const genderCards = Object.keys(GenderEnum).map(gender => {
    const cardProps = {
      gender: gender,
      selected: GenderEnum[gender] === config.gender,
      onClick: () => {
        switch (GenderEnum[gender]) {
          case GenderEnum.either:
            gtagEvent({ action: 'gender_either', category: 'general' })
            break
          case GenderEnum.boy:
            gtagEvent({ action: 'gender_boy', category: 'general' })
            break
          case GenderEnum.girl:
            gtagEvent({ action: 'gender_girl', category: 'general' })
            break
        }
        gtagEvent({ action: 'gender_clicked', category: 'general' })
        handleGenderChange(GenderEnum[gender])
      },
    }
    return (<GenderCard key={gender} {...cardProps}/>)
  })

  return (
    <div className="form-col col-4">
      <label className="form-label" htmlFor="baby-gender">{t('kidConfig.I hope for a')}:</label>
      <div className="inner-form-row">
        {genderCards}
      </div>
    </div>
  )
}

const KidGenderAndFrame = () => {
  return (
    <div className="form-row">
      <GenderCards/>

      {/*<FrameSelector/>*/}
    </div>
  )
}

const SkinCard = (props: any) => {
  const { t } = useTranslation()
  const skinProperty = SkinProps[props.skin]
  const classes = ['baby-box']
  if (props.selected) {
    classes.push('selected')
  }
  return (
    <div className={classes.join(' ')} onClick={props.onClick}>
      <span className="baby-inner-box">
        <span className="input-box">
          <span className="input-inner-box">
            <span className="input-inner-cell">
              <span className="input-icon-box">
                {props.skin === 'auto' && (<span className={skinProperty.class}/>)}
                {props.skin !== 'auto' && (<span className={skinProperty.class}><span/></span>)}
              </span>
              <span className="input-title">
                {t(`kidConfig.skin.${skinProperty.title}`)} {skinProperty.subTitle !== '' && (
                <span className="small">{t(`kidConfig.skin.${skinProperty.subTitle}`)}</span>)}
              </span>
            </span>
          </span>
        </span>
      </span>
    </div>
  )
}

const KidSkin = () => {
  const { t } = useTranslation()
  const config = useAtom(KidConfigAtom)
  const handleSkinChange = useAction(skin => changeKidColor(skin))

  const skinCards = Object.keys(SkinEnum).map(skin => {
    const cardProps = {
      skin: skin,
      selected: SkinEnum[skin] === config.skin,
      onClick: () => {
        switch (SkinEnum[skin]) {
          case SkinEnum.dark:
            gtagEvent({ action: 'skintone_dark', category: 'general' })
            break
          case SkinEnum.asian:
            gtagEvent({ action: 'skintone_asian', category: 'general' })
            break
          case SkinEnum.medium:
            gtagEvent({ action: 'skintone_medium', category: 'general' })
            break
          case SkinEnum.light:
            gtagEvent({ action: 'skintone_light', category: 'general' })
            break
        }
        gtagEvent({ action: 'skintone_clicked', category: 'general' })
        handleSkinChange(SkinEnum[skin])
      },
    }
    return (<SkinCard key={skin} {...cardProps}/>)
  })
  return (
    <div className="form-row">
      <label className="form-label" htmlFor="baby-skin-tone-auto">{t('kidConfig.Select skin tone')}:</label>

      <div className="inner-form-row">
        {skinCards}
      </div>
    </div>
  )
}

const ParentImage = (props: any) => {
  return (
    <li>
      <div className="centered-img">
        <img src={props.src} alt={props.label}/>
      </div>
      <span className="photo-desc">{props.label}</span>
    </li>
  )
}

const Parents = () => {
  const { t } = useTranslation()
  const imagesHandler = useImages()
  const leftParent = {
    src: imagesHandler.firstParent,
    label: t('kidConfig.leftBox.Your picture')
  }
  const rightParent = {
    src: imagesHandler.secondParent,
    label: t('kidConfig.leftBox.Your partner\'s picture')
  }
  return (
    <div className="photo-list-wrap">
      <ul className="photo-list">
        <ParentImage {...leftParent}/>
        <ParentImage {...rightParent}/>
      </ul>
    </div>
  )
}

const GenerateKidButton = () => {
  const { t } = useTranslation()
  const steps = useSteps()

  return (
    <div className="btn-list desktop">
      <div className="single-btn-box full-width">
        <a
          href="#"
          className="btn green"
          title={t('steps.mobile.Show me my Future Baby')}
          onClick={steps.handleClickGenerateKid}
        >
          {t('steps.mobile.Show me my Future Baby')}
        </a>
      </div>
    </div>
  )
}

export default KidConfig
