import axios from 'axios'

export function sendPostback ({ clickId, eventName }) {
  const source = 'babyfaceru'
  const eventTime = Math.floor((new Date()).getTime() / 1000)
  if(Boolean(clickId)){
  // console.log({clickId, eventName, source, eventTime})
  axios.get(`https://rmb-trck.com/conv?source=${source}&clickid=${clickId}&event_time=${eventTime}&event_name=${eventName}`)
    .catch(e=>{
      console.error(e)
    })
  }

}

export const remobyEvents = [
  'step_1_done',
  'step_2_done',
  'step_3_done',
  'step_4_done',
  'SubscriptionSuccess'
]