// @flow

export const STEPS = [
  {
    id: 1,
    name: 'step1',
    done: false,
    pic: 'bm_icon_image',
    mobileName: 'Upload your partner',
    title: 'Your photo',
  },
  {
    id: 2,
    name: 'step2',
    done: false,
    pic: 'bm_icon_photo',
    mobileName: 'Move to baby settings',
    title: 'Your partner photo',
  },
  {
    id: 3,
    name: 'step3',
    done: false,
    pic: 'bm_icon_preference',
    mobileName: 'Show me my Future Baby',
    title: 'Kid config',
  },
  {
    id: 4,
    name: 'step4',
    done: false,
    pic: 'bm_icon_finish',
    mobileName: 'See Your Baby',
    title: 'Your future baby',
  },
];

export const STEPSEXPERIMENTS = [
  {
    id: 1,
    name: 'step1',
    done: false,
    pic: 'bm_icon_image',
    mobileName: 'Upload your partner',
    title: 'Your photo',
  },
  // {
  //   id: 2,
  //   name: 'step2',
  //   done: false,
  //   pic: 'bm_icon_photo',
  //   mobileName: 'Move to baby settings',
  //   title: 'Your partner photo',
  // },
  {
    id: 2,
    name: 'step2',
    done: false,
    pic: 'bm_icon_photo',
    mobileName: 'Show me my Future Baby',
    title: 'Your partner photo',
  },
  // {
  //   id: 3,
  //   name: 'step3',
  //   done: false,
  //   pic: 'bm_icon_preference',
  //   mobileName: 'Show me my Future Baby',
  //   title: 'Kid config',
  // },
  // {
  //   id: 4,
  //   name: 'step4',
  //   done: false,
  //   pic: 'bm_icon_finish',
  //   mobileName: 'See Your Baby',
  //   title: 'Your future baby',
  // },
  {
    id: 3,
    name: 'step4',
    done: false,
    pic: 'bm_icon_finish',
    mobileName: 'See Your Baby',
    title: 'Your future baby',
  },
]