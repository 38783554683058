// @flow
import React from "react";
import {useAction, useAtom} from "@reatom/react";
import useGoogleOptimize from "@react-hook/google-optimize";
import {subscriptionExperimentId} from "../common";
import {PaymentType} from "../enums/PaymentTypesEnum";
import {UtmAnalytics} from "../atoms/UtmAnalytics";
import {PaymentAtom, SetPaymentType} from "../atoms/Payment";

const initPaymentType = () => {
  let paymentType = PaymentType.WeeklySubscription;
  const utm = useAtom(UtmAnalytics);
  if (['roasup', 'onetime-test', 'cardpay', 'bs'].includes(utm.utmSource)) {
    paymentType = PaymentType.OneTimePayment;
  } else if (['weekly-test', 'cardsub', 'trial-link'].includes(utm.utmSource)) {
    paymentType = PaymentType.WeeklySubscription;
  } else if (utm.utmSource === 'monthly-test') {
    paymentType = PaymentType.MonthlySubscription;
  } else if (utm.utmSource === 'annual_test') {
    paymentType = PaymentType.AnnualSubscription;
  }
  return paymentType;
}
const usePaymentTypeResolver = () => {
  const paymentAtomState = useAtom(PaymentAtom);
  const data = {paymentType: null};
  if (paymentAtomState.paymentType === null) {
    data.paymentType = initPaymentType();
  } else {
    data.paymentType = paymentAtomState.paymentType;
  }

  return data;
}

export default usePaymentTypeResolver;
