//@flow

import {useAction, useAtom} from "@reatom/react";
import {ImagesAtom} from "../atoms/FileUpload";
import {ParentEnum} from "../enums/ParentEnum";
import { GenerateKidPic, GeneratorAtom, SaveBabyMarker } from '../atoms/Generator'
import useExperimentsResolver from "./experiments";
import {ChangeVersion} from "../atoms/AppConfig";
import {GeneratorExperiment} from "../enums/GeneratorExperimentEnum";

export const useImage = (parent: string) => {
    const images = useAtom(ImagesAtom);
    const pic = parent === ParentEnum.firstParent ? images.firstParent?.pic : images.secondParent?.pic;
    return {pic};
}

export const useImages = () => {
    const images = useAtom(ImagesAtom);
    return {firstParent: images.firstParent?.pic, secondParent: images.secondParent?.pic};
}

export const useImageGenerator = () => {
    const generatorState = useAtom(GeneratorAtom);
    const babyId = generatorState.babyId;
    const version = parseInt(generatorState.version);
    const babyImage = version === 100 ? `/generated/BabyMaker_${babyId}.jpg` : `/babymaker/2/media/BabyMaker_${babyId}.jpg`;
    const shareHash = generatorState.shareHash;
    const generate = useAction(() => {
        return GenerateKidPic()
    });
    const saveBabyMaker = useAction(()=>{
        return SaveBabyMarker()
    })
    return {babyImage, babyId, generate, saveBabyMaker, shareHash};
}
