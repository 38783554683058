// @flow

import {declareAction, declareAtom} from "@reatom/core";
import {STEPS} from "../utils/steps";
import {ClearKidPic, GenerateKidPic} from "./Generator";
// import ReactPixel from "react-facebook-pixel";
import {ParentEnum} from "../enums/ParentEnum";
// import {useAtom} from "@reatom/react";
import {PaymentAtom} from "./Payment";
import {title} from "../common";
import { gtagEvent, gtagEventNew } from '../utils/gtag'
import {AppConfigAtom} from "./AppConfig";
import {KidConfigAtom} from "./KidConfig";
import kidConfig from "../components/screens/KidConfig";
import { useHistory } from 'react-router-dom'
// import { remobyEvents, sendPostback } from '../utils/remoby'
import { useAtom } from '@reatom/react'
import { PageSizeExperiment } from '../enums/PageSizeExperimentEnum'


export const backToHomeFromWiki = () => {
  const history = useHistory()
  if (history.location === '/wiki/' || history.location === '/wiki') {
    history.push('/')
  }
};

export const nextStepAndGenerate = declareAction(
  'nextStepAndGenerateAction',
  (payload, store) => {
    store.dispatch(ClearKidPic());
    store.dispatch(nextStep({step: payload.step}));
    store.dispatch(GenerateKidPic({ experiment: 0 }))
  }
);

export const nextStep = declareAction(
  'nextStepAction',
  (payload, store) => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const currentStep = payload.step;
    const nextStep = currentStep.id < STEPS.length
      ? currentStep.id + 1
      : currentStep.id;
    store.dispatch(goToStep(nextStep));
  }
);

export const prevStep = declareAction(
  'prevStepAction',
  (payload, store) => {
    const currentStep = payload.step;
    const prevStep = currentStep.id > 1
      ? currentStep.id - 1
      : currentStep.id;
    store.dispatch(goToStep(prevStep));
  }
);
export const goToStep = declareAction(
  'goToStepAction',
  (payload, store) => {
    window.document.title = `${title} | ${STEPS[payload - 1].title}`;
    localStorage.setItem('currentStepId', `${payload}`);
    const paymentState = store.getState(PaymentAtom);
    // switch (payload) {
    //   case 1:
    //     // ReactPixel.track('ViewContent', {content_name: ParentEnum.firstParent})
    //     // ReactPixel.trackCustom('VisitParentPage', {parent: ParentEnum.firstParent});
    //     break;
    //   case 2:
    //     // ReactPixel.track('ViewContent', {content_name: ParentEnum.secondParent})
    //     // ReactPixel.trackCustom('VisitParentPage', {parent: ParentEnum.secondParent});
    //     break;
    //   case 3:
    //     // ReactPixel.track('ViewContent', {content_name: 'kidConfig'})
    //     // ReactPixel.trackCustom('VisitKidConfig', {});
    //     break;
    //   case 4:
    //     if (paymentState.payed || paymentState.trial) {
    //       // ReactPixel.track('ViewContent', {content_name: 'resultScreen'})
    //       // ReactPixel.trackCustom('VisitResultScreen', {});
    //     } else {
    //       // ReactPixel.track('ViewContent', {content_name: 'payScreen'})
    //       // ReactPixel.trackCustom('VisitPayScreen', {});
    //     }
    //     break;
    // }
    switch (payload) {
      case 1:
        // ReactPixel.track('ViewContent', {content_name: ParentEnum.firstParent})
        // ReactPixel.trackCustom('VisitParentPage', {parent: ParentEnum.firstParent});
        break;
      case 2:
        // ReactPixel.track('ViewContent', {content_name: ParentEnum.secondParent})
        // ReactPixel.trackCustom('VisitParentPage', {parent: ParentEnum.secondParent});
        break;
      case 3:
      //   // ReactPixel.track('ViewContent', {content_name: 'kidConfig'})
      //   // ReactPixel.trackCustom('VisitKidConfig', {});
        break;
      case 4:
        if (paymentState.payed || paymentState.trial) {
          // ReactPixel.track('ViewContent', {content_name: 'resultScreen'})
          // ReactPixel.trackCustom('VisitResultScreen', {});
        } else {
          // ReactPixel.track('ViewContent', {content_name: 'payScreen'})
          // ReactPixel.trackCustom('VisitPayScreen', {});
        }
        break;
    }
  }
);

export const updateLastDoneStep = declareAction('updateLastDoneStepAction');

export const stepDone = declareAction(
  'stepDoneAction',
  (payload, store) => {
    const appState = store.getState(AppConfigAtom);
    const stepsState = store.getState(StepsAtom);
    const updatedSteps = stepsState.steps.map(
      step => step.id === stepsState.currentStepId
        ? {...step, done: true}
        : step
    );
    localStorage.setItem('steps', JSON.stringify(updatedSteps));
    store.dispatch(updateSteps(updatedSteps));

    // let lastDoneStep = stepsState.lastDoneStep;
    // for (let step of updatedSteps) {
    //   if (step.done && step.id > lastDoneStep) {
    //     lastDoneStep = step.id;
    //   }
    // }

    // switch (payload.step) {
    //   case 1:
    //     gtagEventNew('step_1_done', true)
    //     gtagEvent({action: 'stepDone', label: 'step 1 done'});
    //     // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[0]})
    //     break;
    //   case 2:
    //     gtagEventNew('step_2_done', true)
    //     gtagEvent({action: 'stepDone', label: 'step 2 done'});
    //     // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[1]})
    //     break;
    //   case 3:
    //     gtagEventNew('step_3_done', true)
    //     gtagEvent({action: 'stepDone', label: 'step 3 done'});
    //     // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[2]})
    //     break;
    //   case 4:
    //     gtagEventNew('step_4_done', true)
    //     gtagEvent({action: 'stepDone', label: 'step 4 done'});
    //     // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[3]})
    //     break;
    //
    // }
    switch (payload.step) {
      case 1:
        gtagEventNew('step_1_done', true)
        gtagEvent({action: 'stepDone', label: 'step 1 done'});
        // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[0]})
        break;
      case 2:
        gtagEventNew('step_2_done', true)
        gtagEvent({action: 'stepDone', label: 'step 2 done'});
        // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[1]})
        break;
      case 3:
        gtagEventNew('step_3_done', true)
        gtagEvent({action: 'stepDone', label: 'step 3 done'});
        // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[2]})
        break;
      case 4:
        gtagEventNew('step_4_done', true)
        gtagEvent({action: 'stepDone', label: 'step 4 done'});
        // sendPostback({clickId:appState.remobyId, eventName:remobyEvents[3]})
        break;

    }
    store.dispatch(updateLastDoneStep(payload.step));
  }
);

export const updateSteps = declareAction('updateStepsAction');

const loadInitState = () => {
  const currentStepId =
    localStorage.getItem('currentStepId') !== null && typeof localStorage.getItem('currentStepId') !== 'undefined'
      ? +localStorage.getItem('currentStepId')
      : 1;

  const steps =
    localStorage.getItem('steps') !== null && typeof localStorage.getItem('steps') !== 'undefined'
      ? JSON.parse(localStorage.getItem('steps') || '{}')
      : STEPS;

  let lastDoneStep = 0;
  for (let step of steps) {
    if (step.done && step.id > lastDoneStep) {
      lastDoneStep = step.id;
    }
  }

  // window.document.title = `${title} | ${STEPS[currentStepId - 1].title}`;

  return {
    steps: steps,
    currentStepId: currentStepId,
    lastDoneStep: lastDoneStep,
  };
}

const initState = loadInitState();

export const StepsAtom = declareAtom(
  'stepsAtom',
  initState,
  on => [
    on(updateSteps, (state, payload) => {
      return {...state, steps: payload};
    }),
    on(goToStep, (state, payload) => {
      return {...state, currentStepId: payload};
    }),
    on(updateLastDoneStep, (state, payload) => {
      return {...state, lastDoneStep: payload};
    }),
  ]
);


