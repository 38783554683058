import {declareAction, declareAtom} from "@reatom/core";

export const SetSizeExperiment = declareAction('setSizeExperimentAction');
export const SetUnsubExperiment = declareAction('setUnsubExperimentAction');
export const SetPayScreenExperiment = declareAction('setPayScreenExperimentAction');
export const SetBottomSheetExperiment = declareAction('setBottomSheetExperimentAction');
export const SetFirstPageExperiment = declareAction('setFirstPageExperimentAction');
export const SetArrowExperiment = declareAction('setArrowExperimentAction');
export const SetGeneratorExperiment = declareAction('setGeneratorExperimentAction');
export const SetPageSizeExperiment= declareAction('setPageSizeExperimentAction');

export const SetSkipStepExperiment = declareAction('setSkipStepExperimentAction')

export const ExperimentsAtom = declareAtom(
  'experimentsAtom',
  {
    sizeExperiment: null,
    unsubExperiment: null,
    payScreenExperiment: null,
    bottomSheetExperiment: null,
    firstPageExperiment: null,
    arrowExperiment: null,
    aiExperiment: null,
    pageSizeExperiment:null,
    skipStepExperiment: null,
  },
  on => [
    on(SetSizeExperiment, (state, payload) => {
      return {...state, sizeExperiment: payload};
    }),
    on(SetUnsubExperiment, (state, payload) => {
      return {...state, unsubExperiment: payload};
    }),
    on(SetPayScreenExperiment, (state, payload) => {
      return {...state, payScreenExperiment: payload};
    }),
    on(SetBottomSheetExperiment, (state, payload) => {
      return {...state, bottomSheetExperiment: payload};
    }),
    on(SetFirstPageExperiment, (state, payload) => {
      return {...state, firstPageExperiment: payload};
    }),
    on(SetArrowExperiment, (state, payload) => {
      return {...state, arrowExperiment: payload};
    }),
    on(SetGeneratorExperiment, (state, payload) => {
      return {...state, aiExperiment: payload};
    }),
    on(SetPageSizeExperiment, (state, payload) => {
      return {...state, pageSizeExperiment: payload};
    }),
    on(SetSkipStepExperiment, (state, payload) => {
      return {...state, skipStepExperiment: payload }
    })

    // тут настроить эксперимент
  ]
)
