// arabic
const ar = {
  "header": {
    "What Will Your Baby Look Like?": "كيف سيبدو طفلك؟",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Sign In",
    "with your email": "with your email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "الصفحة الرئيسية",
    "Contact Us": "اتصل بنا",
    "Feedback": "ملاحظات",
    "Privacy Policy": "سياسة الخصوصية",
    "Terms of Service": "شروط الخدمة",
    "Cookie Policy": "سياسة ملفات تعريف الارتباط",
    "Do Not Sell My Personal Information": "لا تبيع معلوماتي الشخصية",
    "Cancel Subscription": "إلغاء الاشتراك",
    "Copyright": "حقوق النشر",
    "All rights received": "جميع الحقوق محفوظة",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "Copyright": "حقوق النشر",
    "All rights received": "جميع الحقوق محفوظة",
    "Contact Us": "اتصل بنا",
    "Feedback": "ملاحظات",
    "Privacy Policy": "سياسة الخصوصية",
    "Terms of Service": "شروط الخدمة",
    "Cookie Policy": "سياسة ملفات تعريف الارتباط",
    "Do Not Sell My Personal Information": "لا تبيع معلوماتي الشخصية",
    "Unsubscribe": "إلغاء الاشتراك",
    "Sign In": "Sign In",
  },
  "stepsToolbar": {
    "Previous": "السابق",
    "Next": "التالي",
    "Step": "الخطوة",
    "Try For Free": "جرب مجانًا",
    "See Your Baby": "شاهد طفلك",
  },
  "parent": {
    "Upload your photo": "حمل صورتك",
    "Upload your partner's photo": "حمل صورة شريكك",
    "leftBox": {
      "Upload your photo to generate a baby": "حمل صورتك لإنشاء صورة طفل",
      "Upload your partner's photo": "حمل صورة شريكك",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "ابدأ بتحميل صورتك أو التقط صورة شخصية لك",
      "The face should be frontal, clearly visible, no glasses": "يجب أن يكون الوجه أماميًا وواضحًا، من دون نظارة",
      "The face should occupy at least 70% of the photo": "يجب أن يشغل الوجه 70% على الأقل من الصورة",
      "Proceed by uploading your partner's photo below": "واصل بتحميل صورة شريكك أدناه",
      "Face should be frontal, clearly visible, no glasses": "يجب أن يكون الوجه أماميًا وواضحًا، من دون نظارة",
      "The face in the photo should occupy at least 70%": "يجب أن يشغل الوجه 70% على الأقل من الصورة",
    },
    "buttons": {
      "Take a Selfie": "التقط صورة شخصية لنفسك",
      "OR CHOOSE A CELEBRITY": "أو اختر شخصية مشهورة",
      "Choose a celebrity": "اختر شخصية مشهورة",
    },
    "dnd": {
      "Drag & drop your photo": "اسحب صورتك وأفلتها",
      "or": "أو", // сам перевел, перевода не было
      "Upload Image": "حمل صورة",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Suppots JPG, JPG200, PNG and max image size of 8MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "حدد شخصية مشهورة",
      "Search for celebrity": "ابحث عن شخصية مشهورة",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'حمل شريكك',
      'Move to baby settings': 'الانتقال إلى إعدادات الطفل',
      'Show me my Future Baby': 'اعرض لي طفلي المستقبلي',
      'See Your Baby': 'شاهد طفلك',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "إعدادات الطفل",
      "What will your baby look like?": "كيف سيبدو طفلك؟",
      "Personalize your baby with a name, gender and skin tone": "يمكنك إضفاء طابع شخصي على طفلك بالاسم والنوع ولون البشرة.",
      "Your picture": "صورتك",
      "Your partner's picture": "صورة شريكك",
    },
    "Baby's name": "اسم الطفل:",
    "Enter baby's name": "أدخل اسم الطفل",
    "gender": {
      "Baby girl": "طفلة",
      "Baby boy": "طفل",
      "Either": "أيهما",
    },
    "skin": {
      "Auto-detect": "تحديد تلقائي",
      "(90% accurate)": "(بدقة 90%)",
      "Light": "فاتح",
      "Medium": "متوسط",
      "Dark": "داكن",
      "Asian": "آسيوي",
    },
    "Select skin tone": "حدد لون البشرة",
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "وصول مميز مجاني لمدة 3 أيام",
      "See your baby for a small [amount] one-time fee": "See your baby for a small ${{amount}} one-time fee", // хз что делать с этим
      "See your future baby!": "شاهد طفلك المستقبلي!",
      "See your baby and generate more babies": "شاهد طفلك وأنشئ مزيدًا من الأطفال",
      "Save, download and share your babies": "احفظ صور أطفالك ونزلها وشاركها",
    },
    "flower": {
      "Try For Free": "جرب مجانًا",
      "See Your Baby": "شاهد طفلك",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!", // хз что делать с этим
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!", // хз что делать с этим
      "See your future baby!": "شاهد طفلك المستقبلي!",
      "Click": "Click", // и с этим
      "to get a": "to get a", // и с этим
      "to your pictures and premium features": "to your pictures and premium features", // и с этим
    },
    "Try For Free": "جرب مجانًا",
    "See Your Baby": "شاهد طفلك",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator مدعوم بتقنية عالية للتعرف على الوجوه، ويسمح للمستخدمين بإنشاء صورًا للأطفال المستقبلية عن طريق تحميل صور الشريكين. يوفر BabyFaceGeneratorللمستخدمين اشتراكًا تجريبيًا مجانيًا لمدة 3 أيام للوصول إلى جميع الميزات المميزة، ويشمل هذا، على سبيل المثال لا الحصر، عرض صورة طفل تم إنشاؤها، والسماح بإنشاء عدد لا محدود من صور الأطفال، وحفظ صور الأطفال وتنزيلها ومشاركتها.",
      "part2": " تكون اول 3 أيام من الاشتراك مجانية وتكلفك 0.00 دولار، وبعد 3 أيام (إذا لم يتم إلغاء الاشتراك) يدفع المستخدم 9.99 دولارًا في الأسبوع بشكل متكرر. سيتم تحصيل مدفوعات الاشتراك المتكررة عبر Apple Pay أو Google Pay (حسب الطريقة المستخدمة للاشتراك في التجربة المجانية) عندما تنتهي التجربة المجانية التي مدتها 3 أيام. ويتم تجديد الاشتراك تلقائيًا على أساس أسبوعي (9.99 دولارًا في الأسبوع) ما لم يتم إلغاؤه قبل نهاية الفترة الحالية بـ24 ساعة على الأقل. سيتم تحصيل مدفوعات التجديد من حسابك في غضون 24 ساعة قبل نهاية الفترة الحالية.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator مدعوم بتقنية عالية للتعرف على الوجوه، ويسمح للمستخدمين بإنشاء صورًا للأطفال المستقبلية عن طريق تحميل صور الشريكين. يوفر BabyFaceGeneratorللمستخدمين اشتراكًا تجريبيًا مجانيًا لمدة 3 أيام للوصول إلى جميع الميزات المميزة، ويشمل هذا، على سبيل المثال لا الحصر، عرض صورة طفل تم إنشاؤها، والسماح بإنشاء عدد لا محدود من صور الأطفال، وحفظ صور الأطفال وتنزيلها ومشاركتها.",
      "part2": " تكون اول 3 أيام من الاشتراك مجانية وتكلفك 0.00 دولار، وبعد 3 أيام (إذا لم يتم إلغاء الاشتراك) يدفع المستخدم 9.99 دولارًا في الأسبوع بشكل متكرر. سيتم تحصيل مدفوعات الاشتراك المتكررة عبر Apple Pay أو Google Pay (حسب الطريقة المستخدمة للاشتراك في التجربة المجانية) عندما تنتهي التجربة المجانية التي مدتها 3 أيام. ويتم تجديد الاشتراك تلقائيًا على أساس أسبوعي (9.99 دولارًا في الأسبوع) ما لم يتم إلغاؤه قبل نهاية الفترة الحالية بـ24 ساعة على الأقل. سيتم تحصيل مدفوعات التجديد من حسابك في غضون 24 ساعة قبل نهاية الفترة الحالية.",
    },
    "oneTime": {
      // Не знаю пока что делать с этой строкой
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 1-day access to all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies. 1-day premium access costs {{amount}} one-time fee, NO subscriptions, NO hidden fees. Payment will be charged once via Apple Pay or Google Pay (depending on the method used to make a payment)",
      "unSub": "إذا كنت استخدمت اشتراكًا تجريبيًا مجانيًا (انضممت قبل 13 فبراير) فانقر ",
      "unSub2": "هنا - إيقاف الاشتراك/ إلغاء العضوية",
    },
    "signIn": "Sign In",
    "signIn2": "to your account",
    "contactUs": "اتصل بنا",
    "contactUs2": "للحصول على الدعم، أو إذا كان لديك أي أسئلة أو طلبات.",
    "unSub": "يمكنك إلغاء الاشتراك في أي وقت عن طريق النقر هنا:",
    "unSub2": "إيقاف الاشتراك المميز/ إلغاء الاشتراك",
  }
}
export default ar;