const hi = {
  "header": {
    "What Will Your Baby Look Like?": "आपका बेबी कैसा दिखेगा?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "अपने ईमेल",
    "Sign In mobile": "अपने ईमेल",
    "with your email": "से साइन इन करें",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "होम",
    "Contact Us": "संपर्क करें",
    "Feedback": "फीडबैक",
    "Privacy Policy": "निजता नीति",
    "Terms of Service": "प्रयोग के नियम",
    "Cookie Policy": "कुकी पालिसी",
    "Do Not Sell My Personal Information": "मेरी निजी जानकारियों की बिक्री न करें",
    "Cancel Subscription": "सदस्यता रद्द करें",
    "Copyright": "कॉपीराइट",
    "All rights received": "सभी अधिकार सुरक्षित",
    "About Us": "हमारे बारे में",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "हमारे बारे में",
    "Copyright": "कॉपीराइट",
    "All rights received": "सभी अधिकार सुरक्षित",
    "Contact Us": "संपर्क करें",
    "Feedback": "फीडबैक",
    "Privacy Policy": "निजता नीति",
    "Terms of Service": "प्रयोग के नियम",
    "Cookie Policy": "कुकी पालिसी",
    "Do Not Sell My Personal Information": "मेरी निजी जानकारियों की बिक्री न करें",
    "Unsubscribe": "अनसब्सक्राइब करें",
    "Sign In": "साइन इन करें",
  },
  "stepsToolbar": {
    "Previous": "पिछला",
    "Next": "अगला",
    "Step": "चरण",
    "Try For Free": "मुफ्त में आजमाइये",
    "See Your Baby": "अपने बच्चे को देखें",
  },
  "parent": {
    "Upload your photo": "अपना फोटो अपलोड करें",
    "Upload your partner's photo": "अपने पार्टनर की फोटो अपलोड करें",
    "leftBox": {
      "Upload your photo to generate a baby": "बेबी को जनरेट करने के लिए अपना फोटो अपलोड करें",
      "Upload your partner's photo": "अपने पार्टनर की फोटो अपलोड करें",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "अपनी फोटो अपलोड करके या एक सेल्फी लेकर शुरू करें",
      "The face should be frontal, clearly visible, no glasses": "चेहरा ललाट, स्पष्ट रूप से दिखाई देना चाहिए, कोई चश्मा नहीं",
      "The face should occupy at least 70% of the photo": "चेहरे फोटो का कम से कम 70% भाग होना चाहिए",
      "Proceed by uploading your partner's photo below": "अपने पार्टनर की फ़ोटो नीचे अपलोड करके आगे बढ़ें",
      "Face should be frontal, clearly visible, no glasses": "चेहरा ललाट, स्पष्ट रूप से दिखाई देना चाहिए, कोई चश्मा नहीं",
      "The face in the photo should occupy at least 70%": "फोटो में चेहरा कम से कम 70% होना चाहिए",
    },
    "buttons": {
      "Take a Selfie": "एक सेल्फी लें",
      "OR CHOOSE A CELEBRITY": "या एक सेलिब्रिटी चुनें",
      "Choose a celebrity": "एक सेलिब्रिटी चुनें",
    },
    "dnd": {
      "Drag & drop your photo": "अपना फोटो खींच कर लायें और छोड़ें",
      "or": "or",
      "Upload Image": "तस्वीर अपलोड करें",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "JPG, JPG200, PNG और अधिकतम 8MB आकार के तस्वीर को सपोर्ट करता है",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "सेलिब्रिटी का चयन करें",
      "Search for celebrity": "सेलिब्रिटी के लिए खोजें",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'अपने पार्टनर को अपलोड करें',
      'Move to baby settings': 'बेबी सेटिंग में जाएं',
      'Show me my Future Baby': 'मेरे भविष्य के बेबी को दिखायें',
      'See Your Baby': 'अपने बच्चे को देखें',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "बेबी सेटिंग",
      "What will your baby look like?": "आपका बेबी कैसा दिखेगा?",
      "Personalize your baby with a name, gender and skin tone": "अपने बेबी को एक नाम, लिंग और रंग के साथ निजीकृत करें",
      "Your picture": "अप्पकी तस्वीर",
      "Your partner's picture": "आपके पार्टनर की तस्वीर",
    },
    "Baby's name": "बेबी का नाम",
    "Enter baby's name": "बेबी का नाम दर्ज करें",
    "I hope for a": "मुझे चाहिए एक",
    "gender": {
      "Baby girl": "बेबी गर्ल",
      "Baby boy": "बेबी बॉय",
      "Either": "कोई भी",
    },
    "Select skin tone": "त्वचा के रंग का चुनाव करें",
    "skin": {
      "Auto-detect": "स्वतः पता करें",
      "(90% accurate)": "(90% सही)",
      "Light": "हल्का",
      "Medium": "मध्यम",
      "Dark": "काला",
      "Asian": "एशियाई",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3 दिन का प्रीमियम एक्सेस मुफ्त",
      "See your baby for a small [amount] one-time fee": "अपने बेबी को देखने के लिए बस ${{amount}} एक बार भगतान करें",
      "See your future baby!": "अपने भविष्य के बच्चे को देखें!",
      "See your baby and generate more babies": "अपने बच्चे को देखें और अधिक बच्चे पैदा करें",
      "Save, download and share your babies": "अपने शिशुओं को सहेजें, डाउनलोड करें और शेयर करें",
      "Includes name generator & parenting lessons": "नाम जनरेटर और पेरेंटिंग पाठ्यक्रम शामिल हैं",
    },
    "flower": {
      "Try For Free": "मुफ्त में आजमाइये",
      "See Your Baby": "अपने बच्चे को देखें",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": " फिर ${{amount}} साप्ताहिक, कभी भी रद्द करें!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "फिर ${{amount}} प्रतिवर्ष, कभी भी रद्द करें!",
      "See your future baby!": "अपने भविष्य के बच्चे को देखें!",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "अपने चित्रों और प्रीमियम सुविधाओं के लिए 1-दिवसीय पहुंच प्राप्त करने के लिए \"अपने बच्चे को देखें\" पर क्लिक करें।",
      "weekly_sub_fulltext": "अपने चित्रों और प्रीमियम सुविधाओं के लिए 3-दिवसीय निःशुल्क पहुँच प्राप्त करने के लिए \"मुफ्त में आजमाइये\" पर क्लिक करें।",
    },
    "Try For Free": "मुफ्त में आजमाइये",
    "See Your Baby": "अपने बच्चे को देखें",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator एक हाई-टेक फेस रिकग्निशन तकनीक द्वारा समर्थित है, और उपयोगकर्ताओं को दो भागीदारों की फ़ोटो अपलोड करके भविष्य की बेबी तस्वीरें बनाने की अनुमति देता है। BabyFaceGenerator उपयोगकर्ताओं को सभी प्रीमियम सुविधाओं तक पहुंचने के लिए 3-दिवसीय नि: शुल्क परीक्षण सदस्यता प्रदान करता है, जिसमें शामिल हैं, लेकिन उत्पन्न बच्चे को दिखाने के लिए सीमित नहीं है, असीमित शिशुओं को उत्पन्न करने, बचाने, डाउनलोड करने और शिशुओं को साझा करने की अनुमति देता है।",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period",
    },
    "weekly": {
      "part1": "BabyFaceGenerator एक हाई-टेक फेस रिकग्निशन तकनीक द्वारा समर्थित है, और उपयोगकर्ताओं को दो भागीदारों की फ़ोटो अपलोड करके भविष्य की बेबी तस्वीरें बनाने की अनुमति देता है। BabyFaceGenerator उपयोगकर्ताओं को सभी प्रीमियम सुविधाओं तक पहुंचने के लिए 3-दिवसीय नि: शुल्क परीक्षण सदस्यता प्रदान करता है, जिसमें शामिल हैं, लेकिन उत्पन्न बच्चे को दिखाने के लिए सीमित नहीं है, असीमित शिशुओं को उत्पन्न करने, बचाने, डाउनलोड करने और शिशुओं को साझा करने की अनुमति देता है।",
      "part2": "सदस्यता के पहले 3 दिन मुफ्त हैं और $ 0.00 की लागत है, 3 दिनों के बाद (यदि सदस्यता रद्द नहीं हुई है) उपयोगकर्ता को आवर्ती आधार पर ${{amount}} / सप्ताह शुल्क लिया जाता है। 3-दिन का निःशुल्क परीक्षण समाप्त होने पर सदस्यता भुगतान का भुगतान Apple Pay या Google पे (एक नि: शुल्क परीक्षण की सदस्यता के लिए उपयोग की जाने वाली विधि के आधार पर) के माध्यम से किया जाएगा। सदस्यता स्वचालित रूप से एक साप्ताहिक आधार पर नवीनीकृत होती है (${{amount}} / सप्ताह) जब तक कि यह वर्तमान अवधि के अंत से कम से कम 24 घंटे पहले रद्द न हो जाए। वर्तमान अवधि के अंत से पहले 24 घंटों के भीतर आपके खाते को नवीनीकरण के लिए चार्ज किया जाएगा।",
      // "part3": "इस सेवा का उपयोग केवल मनोरंजन के लिए किया जाना चाहिए, और उत्पन्न शिशु तस्वीरों का वास्तविक जीवन के आनुवंशिकी से कोई लेना-देना नहीं है।",
      "part4": "सेवा तक पहुँचने या उपयोग करने से, आप सेवा की शर्तों का पालन करने और उनसे बाध्य होने के लिए सहमत होते हैं।",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator एक हाई-टेक फेस रिकग्निशन तकनीक द्वारा समर्थित है, और उपयोगकर्ताओं को दो भागीदारों की फ़ोटो अपलोड करके भविष्य की बेबी तस्वीरें बनाने की अनुमति देता है। BabyFaceGenerator उपयोगकर्ताओं को सभी प्रीमियम सुविधाओं के लिए 1-दिन का उपयोग प्रदान करता है, जिसमें एक उत्पन्न बेबी को दिखाने के लिए सीमित नहीं है, लेकिन असीमित बेबी को उत्पन्न करने, सहेजने, डाउनलोड करने और शिशुओं को साझा करने की अनुमति देता है। 1-दिन के प्रीमियम एक्सेस की लागत ${{amount}} एक-बार शुल्क, कोई सदस्यता, कोई छिपी हुई फीस नहीं है। भुगतान एक बार Apple Pay या Google पे के माध्यम से किया जाएगा (भुगतान करने के लिए उपयोग की जाने वाली विधि के आधार पर)",
      "unSub": "यदि आपने एक नि: शुल्क परीक्षण सदस्यता का उपयोग किया है तो यहां क्लिक करें -",
      "unSub2": "सदस्यता रोकें / सदस्यता रद्द करें।",
    },
    "annual": {
      "part1": "BabyFaceGenerator एक हाई-टेक फेस रिकग्निशन तकनीक द्वारा समर्थित है, और उपयोगकर्ताओं को दो भागीदारों की फ़ोटो अपलोड करके भविष्य की बेबी तस्वीरें बनाने की अनुमति देता है। BabyFaceGenerator उपयोगकर्ताओं को सभी प्रीमियम सुविधाओं तक पहुंचने के लिए 3-दिवसीय नि: शुल्क परीक्षण सदस्यता प्रदान करता है, जिसमें शामिल हैं, लेकिन उत्पन्न बच्चे को दिखाने के लिए सीमित नहीं है, असीमित शिशुओं को उत्पन्न करने, बचाने, डाउनलोड करने और शिशुओं को साझा करने की अनुमति देता है।",
      "part2": "सदस्यता के पहले 3 दिन मुफ्त हैं और $ 0.00 की लागत है, 3 दिनों के बाद (यदि सदस्यता रद्द नहीं हुई है) उपयोगकर्ता को आवर्ती आधार पर ${{amount}} / वर्ष शुल्क लिया जाता है। 3-दिन का निःशुल्क परीक्षण समाप्त होने पर सदस्यता भुगतान का भुगतान Apple Pay या Google पे (एक नि: शुल्क परीक्षण की सदस्यता के लिए उपयोग की जाने वाली विधि के आधार पर) के माध्यम से किया जाएगा। सदस्यता स्वचालित रूप से एक प्रतिवर्ष आधार पर नवीनीकृत होती है (${{amount}} / वर्ष) जब तक कि यह वर्तमान अवधि के अंत से कम से कम 24 घंटे पहले रद्द न हो जाए। वर्तमान अवधि के अंत से पहले 24 घंटों के भीतर आपके खाते को नवीनीकरण के लिए चार्ज किया जाएगा।",
      "part3": "इस सेवा का उपयोग केवल मनोरंजन के लिए किया जाना चाहिए, और उत्पन्न शिशु तस्वीरों का वास्तविक जीवन के आनुवंशिकी से कोई लेना-देना नहीं है।",
      "part4": "सेवा तक पहुँचने या उपयोग करने से, आप सेवा की शर्तों का पालन करने और उनसे बाध्य होने के लिए सहमत होते हैं।",
    },
    "signIn": "अपने अकाउंट",
    "signIn2": "में साइन इन करें",
    "contactUs": "सपोर्ट के लिए हमसे संपर्क करें, या आपके कोई प्रश्न या अनुरोध होने पर।",
    "contactUs2": "",
    "unSub": "आप यहां क्लिक करके कभी भी अपनी सदस्यता रद्द कर सकते हैं",
    "unSub2": " प्रीमियम सदस्यता बंद करें / सदस्यता समाप्त करें",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "साइन इन करने के लिए अपने BabyFaceGenerator खाते से संबद्ध ईमेल पता दर्ज करें",
    "Enter your email address": "अपना ईमेल पता दर्ज करें",
    "Sign In": "साइन इन करें",
  },
  "EmailPopup": {
    "Important": "महत्वपूर्ण",
    "enter your email address to see a baby and manage your account": "अपने बच्चे को देखने और अपना खाता प्रबंधित करने के लिए अपना ईमेल पता दर्ज करें",
    "Enter a valid email address": "एक मान्य ईमेल पता दर्ज करें",
    "Email not valid": "Email not valid",
    "Save email": "ईमेल सहेजें",
  },
  "meta": {
    "title": "बेबी मेकर और बेबी फेस जनरेटर",
    "description": "मेरा बेबी कैसा दिखेगा? बस अपने और अपने पार्टनर की तस्वीरें अपलोड करें और सेकंड में एक बेबी बनाएँ!",
  },
}

export default hi;