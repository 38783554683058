//@flow
const GOOGLE_PAY_BUTTON_SDK_URL = '//pay.google.com/gp/p/js/pay.js';

export const loadSDK = (handleSDKInit: function) => {
  const script = document.createElement('script');
  script.src = GOOGLE_PAY_BUTTON_SDK_URL;
  script.onload = handleSDKInit;
  script.async = true;
  script.defer = true;
  document.body?.appendChild(script)
}
