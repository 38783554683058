// @flow

import { declareAction, declareAtom } from '@reatom/core'
import axios from 'axios'
import { KidConfigAtom, BabiesName, Professions } from './KidConfig'
import { ClearBothParents, ClearParent, ImagesAtom } from './FileUpload'
import { AppConfigAtom } from './AppConfig'
import { GenderEnum } from '../enums/GenderEnum'
import { SkinEnum } from '../enums/SkinEnum'
import * as common from '../common'
import { STEPS } from '../utils/steps'
import { goToStep } from './Steps'
import { ParentEnum } from '../enums/ParentEnum'
import { showWarning } from './Util'
import { ErrorLog, ResponseLog } from './Debug'
import { gtagEvent, gtagEventNew } from '../utils/gtag'
import { AddBaby, SubscriberAtom } from './Subscriber'
import { NRError, NRPhotoGenerated, NRSuccess } from './NewRelic'
import useExperimentsResolver from '../effects/experiments'
import { api } from '../common'

const selectRandomTemplate = (sex, skin, avatars) => {
  let filterSex = null
  switch (sex) {
    case GenderEnum.boy:
      filterSex = '.male'
      break
    case GenderEnum.girl:
      filterSex = '.female'
      break
  }

  let filterSkin = null
  switch (skin) {
    case SkinEnum.light:
      filterSkin = '.white.'
      break
    case SkinEnum.auto:
    case SkinEnum.medium:
    case SkinEnum.asian:
      filterSkin = '.hispanic.'
      break
    case SkinEnum.dark:
      filterSkin = '.afro.'
      break
  }

  // const babyTemplate = null;

  const goodAvatarTemplates = avatars.map(avatar => {
    if (
      (filterSex === null || avatar.includes(filterSex))
      && (filterSkin === null || avatar.includes(filterSkin))
      // && (babyTemplate === null || avatar.includes(babyTemplate))
    )
      return avatar
  })

  let randomAvatarTemplate = ''
  if (goodAvatarTemplates.length > 0) {
    const randomIndex = Math.min(
      Math.floor(Math.random() * goodAvatarTemplates.length),
      goodAvatarTemplates.length - 1
    )
    randomAvatarTemplate = goodAvatarTemplates[randomIndex]
  }
  return randomAvatarTemplate
}

export const GenerateKidPic = declareAction(
  'generateKidPicAction',
  (payload, store) => {
    store.dispatch(ClearKidPic())
    const appConfig = store.getState(AppConfigAtom)
    const kidConfig = store.getState(KidConfigAtom)
    const images = store.getState(ImagesAtom)
    const subscriberState = store.getState(SubscriberAtom)
    const subHaveEmail = subscriberState.subscriber.email

    const generationData = {}
    generationData.sex = kidConfig.gender
    generationData.skin = kidConfig.skin
    generationData.file1 = images.firstParent.pic.split('/').pop()
    generationData.file2 = images.secondParent.pic.split('/').pop()
    // generationData.experiments = '';
    // generationData.description = '';
    // 0-1
    generationData.wideframe = appConfig.wideFrame
    generationData.uid = appConfig.uid
    generationData.fingerprint = appConfig.fingerprint
    // version 21-27
    generationData.version = appConfig.version
    generationData.webcam1 = images.firstParent.fromWebcam ? 1 : 0
    generationData.webcam2 = images.secondParent.fromWebcam ? 1 : 0
    generationData.babyframe = kidConfig.frame
    // 0-1
    generationData.nobabyframe = kidConfig.noFrame
    // 0-1
    generationData.new_face_algorithm = appConfig.newFaceAlgorithm
    // &avatar_render_template=makebaby271list.txt
    generationData.avatar_render_template = selectRandomTemplate(
      kidConfig.gender,
      kidConfig.skin,
      kidConfig.avatars.still
    )
    // 0-1
    generationData.experimental_templates = 1
    generationData.rand = Math.random()
    generationData.name = kidConfig.name.length > 0 ? kidConfig.name : null

    const form = new FormData()
    for (let [key, val] of Object.entries(generationData)) {
      if (typeof val === 'string' || typeof val === 'number') {
        form.append(key, `${val}`)
      }
    }

    form.append('parentPicIds', localStorage.getItem('firstParentId') + ',' + localStorage.getItem('secondParentId'))

    store.dispatch(BabiesName())
    store.dispatch(Professions())

    axios
      .post(
        `${common.api}/generate`,
        form,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      .then(res => {

        //TODO: разобраться с этим делом

        // store.dispatch(ResponseLog(res));
        const generatorResponse = res.data
        const babyLink = generatorResponse.link
        const remoteId = generatorResponse.id
        const shareHash = generatorResponse.share_hash
        // console.log(generatorResponse, babyLink.includes('BabyMaker_'), generationData.version)
        if (babyLink.includes('BabyMaker_')) {
          if (parseInt(generationData.version) === 100) {
            const babyIdMatch = [...babyLink.matchAll(/BabyMaker_(.*).jpg/g)]
            // console.log(babyIdMatch, '100')
            const babyId = babyIdMatch[0].pop()
            store.dispatch(StoreBabyInfo({ babyId, remoteId, shareHash, version: generationData.version }))
            const babyData = {
              id: babyId,
              babyPic: babyLink,
              babyName: generationData.name,
              generatedId: remoteId,
              version: generationData.version
            }
            store.dispatch(AddBaby(babyData))
          } else {
            const babyIdMatch = [...babyLink.matchAll(/(..\/)?media\/BabyMaker_(.*).jpg/g)]
            // console.log(babyIdMatch, 'other')
            const babyId = babyIdMatch[0].pop()
            store.dispatch(StoreBabyInfo({ babyId, remoteId, shareHash, version: generationData.version }))
            const babyData = {
              id: babyId,
              babyPic: babyLink,
              babyName: generationData.name,
              generatedId: remoteId,
              version: generationData.version
            }
            store.dispatch(AddBaby(babyData))
          }

          gtagEvent({
            action: 'photogenerated_success',
            category: 'photogeneration',
            label: 'userphotogenerated'
          })
          gtagEventNew(`alg${appConfig.version}_photo_generated`)
          gtagEventNew('photo_generated_success_new')
        } else {
          const errorMessage = res.data.link.split(':')
          let hideFirstParent = false
          let hideSecondParent = false
          if (errorMessage.length === 2) {
            if (errorMessage[0] === 'CANNOT DETECT FACE') {
              const faces = errorMessage[1].split(' ')
              for (let face of faces) {
                if (face === '1') {
                  hideFirstParent = true
                }
                if (face === '2') {
                  hideSecondParent = true
                }
              }
            }
          }
          if (hideFirstParent && hideSecondParent) {
            store.dispatch(showWarning({
              text: 'Can\'t detect both faces, please upload other pictures.',
              step: STEPS[0].id
            }))
            store.dispatch(ClearBothParents())
            if (subHaveEmail) {
              store.dispatch(goToStep(STEPS[0].id))
            }
          } else if (hideSecondParent) {
            store.dispatch(showWarning({
              text: 'Can\'t detect face of your partner, please upload other picture.',
              step: STEPS[1].id
            }))
            store.dispatch(ClearParent(ParentEnum.secondParent))
            if (subHaveEmail) {
              store.dispatch(goToStep(STEPS[1].id))
            }
          } else if (hideFirstParent) {
            store.dispatch(showWarning({
              text: 'Can\'t detect your face, please upload other picture.',
              step: goToStep(STEPS[0].id),
            }))
            store.dispatch(ClearParent(ParentEnum.firstParent))
            if (subHaveEmail) {
              store.dispatch(goToStep(STEPS[0].id))
            }
          }
          // store.dispatch();
          store.dispatch(NRPhotoGenerated({
            state: NRError,
            error: JSON.stringify({ link: res.data.link, data: generationData })
          }))
        }

        gtagEvent({
          action: 'photogenerated',
          category: 'photogeneration',
          label: 'userphotogenerated'
        })
        gtagEventNew('photo_generated_new')

        if (payload.experiment && payload.experiment === 1) {
          gtagEventNew('experiment_generated')
        } else {
          gtagEventNew('original_generated')
        }

        store.dispatch(NRPhotoGenerated({ state: NRSuccess }))
      })
      .catch(error => {
        store.dispatch(NRPhotoGenerated({ state: NRError, error: JSON.stringify({ error, generationData }) }))
        store.dispatch(ErrorLog(error))
        // store.dispatch(showWarning({ text: 'Can\'t upload image' }))
        // store.dispatch(showWarning(error));
        console.log(error)
      })
  }
)

export const ClearKidPic = declareAction(
  'clearKidPicAction',
  (payload, store) => {
    store.dispatch(StoreBabyInfo({ babyId: null, remoteId: null, shareHash: null, version: 30 }))
  }
)

export const StoreBabyInfo = declareAction(
  'storeBabyIdAction',
  (payload, store) => {
    localStorage.setItem('generatorState', JSON.stringify(store.getState(GeneratorAtom)))
  }
)

export const SaveBabyMarker = declareAction('saveBabyMarkerAction',
  (payload, store) => {
    const currState = store.getState(GeneratorAtom)
    if (currState.remoteId !== null) {
      axios.post(`${api}/saved/${currState.remoteId}`)
        .then((res) => {})
        .catch(console.error)
    }
  })

const loadInitState = () => {
  let state = {
    babyId: null,
    remoteId: null,
    shareHash: null,
    version: 30,
  }
  if (localStorage.getItem('generatorState')) {
    state = { ...state, ...JSON.parse(localStorage.getItem('generatorState') || '{}') }
  }
  return state
}

const initState = loadInitState()

export const GeneratorAtom = declareAtom(
  'generatorAtom',
  initState,
  on => [
    on(StoreBabyInfo, (state, payload) => {
      return {
        ...state,
        babyId: payload.babyId,
        remoteId: payload.remoteId,
        shareHash: payload.shareHash,
        version: payload.version
      }
    }),
  ]
)