// @flow

import {declareAction, declareAtom} from "@reatom/core";

export const showWarning = declareAction('showWarningAction');
export const hideWarning = declareAction('hideWarningAction');
export const messagesAtom = declareAtom(
  'warningsAtom',
  {warning: null, warningStep: null},
  on => [
    on(showWarning, (state, payload) => {
      return {...state, warning: payload.text, warningStep:payload.step};
    }),
    on(hideWarning, (state, payload) => {
      return {...state, warning: null, warningStep: null};
    })
  ]
);