import { declareAction, declareAtom } from '@reatom/core'
import axios from 'axios'
import { api } from '../common'

export const NRSuccess = 'success'
export const NRError = 'error'

export const NRPhotoGenerated = declareAction(
  'nrPhotoGenerated',
  (payload) => {
    const form = new FormData()
    if (payload.state === NRError) {
      form.append('error', JSON.stringify(payload.error))
    }
    axios.post(
      `${api}/logger/photo-generator/${payload.state}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then(console.log)
      .catch(console.error)
  }
)

export const NRSubscribed = declareAction(
  'nrSubscribed',
  (payload) => {
    const form = new FormData()
    axios.post(
      `${api}/logger/subscribed/${payload}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then(console.log)
      .catch(console.error)
  }
)

export const NRUnSubscribed = declareAction(
  'nrUnSubscribed',
  (payload) => {
    const form = new FormData()
    axios.post(
      `${api}/logger/unsubscribed/${payload}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then(console.log)
      .catch(console.error)
  }
)

export const NRStartPaymentProcess = declareAction('nrStartPaymentProcess')
export const NREndPaymentProcess = declareAction(
  'nrEndPaymentProcess',
  (payload, store) => {
    const nrState = store.getState(NewRelic)
    const form = new FormData()
    form.append('time', (new Date()).getTime() - nrState.startPaymentProcessTime)
    axios.post(
      `${api}/logger/process-subscription-time`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then(console.log)
      .catch(console.error)
      .finally(() => {
        store.dispatch(NRClearPaymentProcess())
      })
  }
)

export const NREmailUpdated = declareAction(
  'nrEmailUpdated',
  (payload) => {
    const form = new FormData()
    axios.post(
      `${api}/logger/email-updated/${payload}`,
      form,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
      .then(console.log)
      .catch(console.error)
  }
)

export const NRClearPaymentProcess = declareAction('nrClearPaymentProcess')

export const NewRelic = declareAtom(
  'newRelicAtom',
  {
    startPaymentProcessTime: null,
  },
  on => [
    on(NRStartPaymentProcess, (state, payload) => {
      return { ...state, startPaymentProcessTime: (new Date()).getTime() }
    }),
    on(NRClearPaymentProcess, (state, payload) => {
      return { ...state, startPaymentProcessTime: null }
    }),
  ]
)
