// @flow

import React from "react";
import {goToStep, StepsAtom} from "../atoms/Steps";
import {useAction, useAtom} from "@reatom/react";
import {useTranslation} from "react-i18next";

export const Step = (props: any) => {
  const {t} = useTranslation();
  const stepsState = useAtom(StepsAtom);



  const step = stepsState.steps.find((step, index, arr) => {
    return step.id === props.stepId;
  });
  const classes = [];
  if (step.done) {
    classes.push('done');
  }
  if (props.currentStepId === step.id) {
    classes.push('active');
  }

  const handleGoToStep = useAction(() => {
    if (step.done || step.id === stepsState.lastDoneStep + 1) {
      return goToStep(step.id);
    }
  }, [step, stepsState.lastDoneStep])

  return (
    <li>
      <a href="#"
         className={classes.join(' ')}
         title={`${t('stepsToolbar.Step')} ${step.id}`}
         onClick={handleGoToStep}
      >
        <span className="icon-box">
          <span className={step.pic}/>
        </span>{t('stepsToolbar.Step')} {step.id}
      </a>
    </li>
  );
}