import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './lng/i18n.en';
import ar from './lng/i18n.ar'
import de from './lng/i18n.de'
import es from "./lng/i18n.es";
import fr from "./lng/i18n.fr";
import hi from "./lng/i18n.hi";
import pl from "./lng/i18n.pl";
import pt from "./lng/i18n.pt";
import ru from "./lng/i18n.ru";
import tr from "./lng/i18n.tr";
import zn from "./lng/i18n.zn";
import babyGenerator from "./lng/i18n.baby-generator";
import whatWillMyBabyLookLike from "./lng/i18n.what-will-my-baby-look-like";
import whatWillOurBabyLookLike from "./lng/i18n.what-will-our-baby-look-like";
import whatWouldOurBabyLookLike from "./lng/i18n.what-would-our-baby-look-like";
import {debugMode} from "./common";

const resources = {
  en: {translation: en},
  ar: {translation: ar},
  de: {translation: de},
  es: {translation: es},
  fr: {translation: fr},
  hi: {translation: hi},
  pl: {translation: pl},
  pt: {translation: pt},
  ru: {translation: ru},
  tr: {translation: tr},
  zh: {translation: zn},
  'baby-generator':{translation:babyGenerator},
  'what-will-my-baby-look-like':{translation:whatWillMyBabyLookLike},
  'what-will-our-baby-look-like':{translation:whatWillOurBabyLookLike},
  'what-would-our-baby-look-like':{translation:whatWouldOurBabyLookLike},
};
const supportedLanguages = Object.keys({...resources});

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    debug: Boolean(debugMode),
    supportedLngs: supportedLanguages,
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    detection: {
      order: ['querystring', 'path'],
    },
    transKeepBasicHtmlNodesFor:['br','strong','i']
  });

export default i18n;
