//@flow

import React, {useState} from 'react';
import {feedbackEmail, iubendaID, privacyPolicyLink, relativeRoot, termsAndConditionsLink} from "../../common";
import {useUnsubscribe} from "../../effects/unsubscribe";
import {Link, useHistory} from "react-router-dom";
import Iubenda from "react-iubenda-policy";
import {useSignIn} from "../../effects/signIn";
import {useTranslation} from "react-i18next";
import {useStartupEffect} from "../../effects/custom";
import useLanguages from "../../effects/languages";
import {useAtom} from "@reatom/react";
import {SubscriberAtom} from "../../atoms/Subscriber";
import {gtagEvent} from "../../utils/gtag";
import {useUnsubExperiment} from "../../contexts/ExperimentsContext";


const Footer = (props) => {
  useStartupEffect();
  const {languages, languageChangeHandler} = useLanguages();
  const unsubscribeHandler = useUnsubscribe();
  const signInHandler = useSignIn();
  const signInPopUp = signInHandler.signInPopUp();
  const {t} = useTranslation();
  const subscriberState = useAtom(SubscriberAtom);
  const history = useHistory();
  const currYear = (new Date()).getFullYear();

  const [signIn, setSignIn] = useState(() => {
  })

  const unsubExperiment = useUnsubExperiment()
  const unsubName = t(`experiment.unsub.${unsubExperiment}`);


  React.useEffect(() => {
    setSignIn(() => {
      return signInHandler.showProfilePopUp
    })
    if (subscriberState.loaded) {
      if (subscriberState.subscriber && subscriberState.subscriber.email) {
        setSignIn(() => {
          return history.push;
        })
      }
    }
  }, [subscriberState.subscriber])

  return (
    <footer className="footer"
            id="footer"
    >
      <div className="container">
        <div className="left-box"
             style={{width: "100%"}}
        >
          <p className="copyright"
          >{t('footer.Copyright')} © {currYear}. {t('footer.All rights received')}.</p>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu">
            <li>
              <Link to={`${relativeRoot}/about-us/`}
              >{t('footer.About Us')}</Link>
            </li>
            <li>
              <Link to={`${relativeRoot}/contact-us/`}
              >{t('footer.Contact Us')}</Link>
            </li>
            <li>
              <Link to={`${relativeRoot}/feedback/`}
              >{t('footer.Feedback')}</Link>
            </li>
            <li>
              <Iubenda id={iubendaID}
                       type={'privacy'}
                       styling={'nostyle'}
              >{t('footer.Privacy Policy')}</Iubenda>
            </li>
            <li>
              <Link to={`${relativeRoot}/terms-of-service/`}
              >{t('footer.Terms of Service')}</Link>
            </li>
            <li>
              <a onClick={() => {
                signIn('/profile');
                gtagEvent({action: "manage account click", category: "manage account", label: "manage-desktop-footer"});
              }}
                 className="pointer">
                {unsubName ? unsubName : t('footer.Profile')}
              </a>
            </li>
          </ul>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu">
            <li>
              <a title={t('footer.Sign In')}
                 className="pointer"
                 onClick={signInHandler.showSignInPopUp}
              >{t('footer.Sign In')}</a>
              {signInPopUp}
            </li>
            <li>
              <Iubenda id={iubendaID}
                       type={'cookie'}
                       styling={'nostyle'}
              >{t('footer.Cookie Policy')}</Iubenda>
            </li>
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="iubenda-ccpa-opt-out"
              >{t('footer.Do Not Sell My Personal Information')}</a>
            </li>
          </ul>
        </div>
        <div className="left-box"
             style={{width: "100%"}}>
          <ul className="footer-menu">
            <li>
              {(languages.map((lang, idx) => {
                return <a key={idx}
                          className={`language pointer`}
                          href={lang.url === 'en' ? `/` : `/${lang.url}/`}
                          onClick={(e) => {
                            e.preventDefault();
                            languageChangeHandler(lang.url)
                          }}>{lang.url.toUpperCase()} {idx !== languages.length - 1 && (
                  <span style={{fontWeight: '100'}}>&nbsp;|&nbsp;</span>)} </a>
              }))}
            </li>
            {/*<li><a href="/my-baby-generator/" className={`landing`}>Baby generator</a></li>*/}
            {/*<li><a href="/see-what-will-my-baby-look-like/" className={`landing`}>What will my baby look like</a></li>*/}
            {/*<li><a href="/see-what-will-our-baby-look-like/" className={`landing`}>What will our baby look like</a></li>*/}
            {/*<li><a href="/what-would-our-baby-look-like/" className={`landing`}>What would our baby look like</a></li>*/}
          </ul>
        </div>
      </div>
    </footer>
  );
}
export default Footer;