import React, {useContext} from "react";
import {useAtom} from "@reatom/react";
import {WebViewDetectorAtom} from "../atoms/WebViewDetector";
import {debugMode, domain} from "../common";

const AndroidWebViewContext = React.createContext()
const AndroidWebViewBehaviorContext = React.createContext()

export function useAndroidWebViewContext() {
  return useContext(AndroidWebViewContext)
}

export function useAndroidWebViewBehaviorContext() {
  return useContext(AndroidWebViewBehaviorContext)
}


export function AndroidWebViewProvider({children}) {
  const WebViewState = useAtom(WebViewDetectorAtom);
  const isAndroidWebView = (WebViewState.isAndroid && WebViewState.isWebView);
  const goPlayOutside = () => {
    if (isAndroidWebView) {
      window.location = `intent:${domain}${window.location.search}#Intent;end`
    } else {
      return
    }
  }
  return (
    <AndroidWebViewContext.Provider value={isAndroidWebView}>
      <AndroidWebViewBehaviorContext.Provider value={goPlayOutside}>
        <div onClick={goPlayOutside}>
          {children}
        </div>
      </AndroidWebViewBehaviorContext.Provider>
    </AndroidWebViewContext.Provider>
  )
}
