// @flow

import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {combine, createStore} from '@reatom/core'
import {context} from '@reatom/react'
import {ToastProvider} from 'react-toast-notifications';
import {BrowserRouter} from "react-router-dom";
import axios from "axios";

import './i18n';

import {StepsAtom} from "./atoms/Steps";
import {ImagesAtom} from "./atoms/FileUpload";
import {GeneratorAtom} from "./atoms/Generator";
import {KidConfigAtom} from "./atoms/KidConfig";
import {AppConfigAtom, InitApp} from "./atoms/AppConfig";
import {ArticlesConfigAtom} from "./atoms/ArticlesConfig"
import {CelebsAtom} from "./atoms/Celebs";
import {StripeApplePaymentAtom} from "./atoms/StripePayment";
import {GooglePaymentAtom} from "./atoms/GooglePayment";
import {SubscriberAtom, SubscriberLoaded} from "./atoms/Subscriber";
import {DebugAtom} from "./atoms/Debug";
import {WebViewDetectorAtom} from "./atoms/WebViewDetector";
import {UtmAnalytics} from "./atoms/UtmAnalytics";
import {messagesAtom} from "./atoms/Util";
import {PaymentAtom} from "./atoms/Payment";
import {api, debugMode} from "./common";
import Wrapper from "./components/Wrapper";
import {CardPaymentAtom} from "./atoms/CardPayment";
import {WalletTypeAtom} from "./atoms/WalletType";
import {ExperimentsAtom} from "./atoms/Experiments";
import {NewRelic} from "./atoms/NewRelic";
import {BillingReceiptsAtom} from "./atoms/BillingReceipts";

const rootAtom = combine({
  appConfig: AppConfigAtom,
  articlesConfigAtom: ArticlesConfigAtom,
  currentStep: StepsAtom,
  images: ImagesAtom,
  payment: GeneratorAtom,
  kidConfig: KidConfigAtom,
  celebs: CelebsAtom,
  applePay: StripeApplePaymentAtom,
  googlePay: GooglePaymentAtom,
  subscriber: SubscriberAtom,
  debug: DebugAtom,
  webview: WebViewDetectorAtom,
  utm: UtmAnalytics,
  messages: messagesAtom,
  paymentState: PaymentAtom,
  cardPayment: CardPaymentAtom,
  walletType: WalletTypeAtom,
  experiments: ExperimentsAtom,
  newrelic: NewRelic,
  billingReceipts: BillingReceiptsAtom,
});

const store = createStore(
  rootAtom, {},
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.dispatch(InitApp());

store.subscribe(SubscriberLoaded, (payload) => {
  const gateway = store.getState(PaymentAtom);
  const utm = store.getState(UtmAnalytics);
  const urlParams = new URLSearchParams(window.location.search);
  const data = new FormData();
  data.append('source', utm.utmSource);
  data.append('medium', utm.utmMedium);
  data.append('campaign', utm.utmCampaign);
  data.append('gateway', gateway.gateway);
  data.append('fbclid', urlParams.get('fbclid'))
  axios.post(`${api}/subscriber/${payload.uid}`, data)
    .catch(console.error);
})

export const App = () => {
  useEffect(() => {
    if (debugMode === 'true') {
      import('@reatom/debug').then((reatomDebug) => {
        reatomDebug.connectReduxDevtools(store)
      });
    }
  }, [])




  return (
    <context.Provider value={store}>
      <ToastProvider placement="bottom-right">
        <BrowserRouter>
          <Wrapper/>
        </BrowserRouter>
      </ToastProvider>
    </context.Provider>
  );
}

const rootContainer = document.getElementById('app');
if (rootContainer) {
  ReactDOM.render(<App/>, rootContainer);
} else {
  document.querySelector('body')?.prepend("<p>cant find tag with id app</p>");
}

/**

 Добавить в <head>
 <script type="text/javascript">
 var _iub = _iub || [];
 _iub.csConfiguration = {
 "lang":"en",
 "enableCcpa":true,
 "countryDetection":true,
 "gdprAppliesGlobally":false,
 "siteId":1759798,
 "cookiePolicyId":56731815,
 "banner":{
  "position":"bottom",
  "textColor":"black",
  "backgroundColor":"white",
  "acceptButtonDisplay":true,
  "acceptButtonColor":"#0073CE",
  "acceptButtonCaptionColor":"white",
  "customizeButtonDisplay":true,
  "customizeButtonColor":"#DADADA",
  "customizeButtonCaptionColor":"#4D4D4D"
 }
 };
 </script>
 <script type="text/javascript" src="//cdn.iubenda.com/cs/iubenda_cs.js" charset="UTF-8" async></script>

 Добавить в подвал страницы:
 a) Privacy Policy
 <a href="https://www.iubenda.com/privacy-policy/56731815" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>

 b) Cookie Policy
 <a href="https://www.iubenda.com/privacy-policy/56731815/cookie-policy" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Cookie Policy">Cookie Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>

 c) <a href="javascript:void(0)" class="iubenda-ccpa-opt-out">Do Not Sell My Personal Information</a>

 */