// @flow

import {PaymentAtom, Unsubscribe} from "../atoms/Payment";
import {useAction, useAtom} from "@reatom/react";
import {useToasts} from "react-toast-notifications";
import {gtagEvent, gtagEventNew} from "../utils/gtag";

export const useUnsubscribe = () => {
  const {addToast} = useToasts();

  const paymentState = useAtom(PaymentAtom);

  const unsubscribeAction = useAction(
    () => Unsubscribe(),
    // if (paymentState.payed) {
    //   return Unsubscribe();
    // } else {
    // window.location.href = 'mailto:babies@luxand.com?subject=I%20want%20to%20unsubscribe%20from%20BabyFaceGenerator&body=Please%20describe%20your%20request%2C%20and%20include%20last%204%20digits%20of%20your%20card%20and%20expiration%20date%20%28MM%2FYY%29.%20This%20information%20will%20allow%20us%20to%20identify%20your%20subscription.';
    // }
    [paymentState]
  );

  const handleUnsubscribe = (unsubPlace) => {
    gtagEvent({category: 'unsubscribe', action: 'unsubscribe complete', label: `unsubscribe-complete-any`});
    if (paymentState.payed) {
      addToast('You successfully unsubscribed', {
        appearance: 'success',
        autoDismiss: true,
      });
    }
    gtagEventNew('unsubscribe_new')
    unsubscribeAction();
  };

  return {handleUnsubscribe};
}