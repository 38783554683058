// @flow

import {declareAction, declareAtom} from "@reatom/core";

export const uploadStart = declareAction('uploadStartAction');
export const uploadEnd = declareAction('uploadEndAction');
export const uploadProgress = declareAction('uploadProgressAction');

export const uploadStateAtom = declareAtom(
  'uploadStateAtom',
  {uploading: false, uploadPercent: 0,},
  on => [
    on(uploadStart, (state, payload) => {
      return Object.assign({}, state, {uploading: true});
    }),
    on(uploadEnd, (state, payload) => {
      return Object.assign({}, state, {uploading: false});
    }),
    on(uploadProgress, (state, payload) => {
      return Object.assign({}, state, {uploadPercent: payload});
    }),
  ]
);
