const fr = {
  "header": {
    "What Will Your Baby Look Like?": "À quoi ressemblera votre bébé ?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Connectez-vous",
    "Sign In mobile": "Connectez-vous",
    "with your email": "avec votre email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Accueil",
    "Contact Us": "Contactez-nous",
    "Feedback": "Commentaires",
    "Privacy Policy": "Politique de confidentialité",
    "Terms of Service": "Conditions d’utilisation",
    "Cookie Policy": "Politique relative aux cookies",
    "Do Not Sell My Personal Information": "Ne vendez pas mes informations personnelles",
    "Cancel Subscription": "Annuler l’abonnement",
    "Copyright": "Copyright",
    "All rights received": "Tous droits réservés",
    "About Us": "À propos de nous",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "À propos de nous",
    "Copyright": "Copyright",
    "All rights received": "Tous droits réservés",
    "Contact Us": "Contactez-nous",
    "Feedback": "Commentaires",
    "Privacy Policy": "Politique de confidentialité",
    "Terms of Service": "Conditions d’utilisation",
    "Cookie Policy": "Politique relative aux cookies",
    "Do Not Sell My Personal Information": "Ne vendez pas mes informations personnelles",
    "Unsubscribe": "Se désabonner",
    "Sign In": "S'identifier",
  },
  "stepsToolbar": {
    "Previous": "Précédent",
    "Next": "Suivant",
    "Step": "Étape",
    "Try For Free": "Essai gratuit",
    "See Your Baby": "Voir votre bébé",
  },
  "parent": {
    "Upload your photo": "Téléchargez votre photo",
    "Upload your partner's photo": "Téléchargez la photo de votre partenaire",
    "leftBox": {
      "Upload your photo to generate a baby": "Téléchargez votre photo pour générer un bébé",
      "Upload your partner's photo": "Téléchargez la photo de votre partenaire",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Commencez par télécharger votre photo ou par prendre un selfie",
      "The face should be frontal, clearly visible, no glasses": "La photo doit être prise de face, claire et sans lunettes",
      "The face should occupy at least 70% of the photo": "Le visage doit occuper au moins 70 % de la photo",
      "Proceed by uploading your partner's photo below": "Continuez en téléchargeant la photo de votre partenaire ci-dessous",
      "Face should be frontal, clearly visible, no glasses": "La photo doit être prise de face, claire et sans lunettes",
      "The face in the photo should occupy at least 70%": "Le visage sur la photo doit occuper au moins 70 %",
    },
    "buttons": {
      "Take a Selfie": "Prenez un selfie",
      "OR CHOOSE A CELEBRITY": "ou choisissez une célébrité",
      "Choose a celebrity": "Choisissez une célébrité",
    },
    "dnd": {
      "Drag & drop your photo": "Glissez-déposez votre photo",
      "or": "ou",
      "Upload Image": "Téléchargez une image",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Les formats acceptés sont JPG, JPG2000, PNG et la taille maximale de l’image est de 8 Mo",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Sélectionnez une célébrité",
      "Search for celebrity": "Recherchez une célébrité",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Téléchargez votre partenaire',
      'Move to baby settings': 'Passer aux paramètres du bébé',
      'Show me my Future Baby': 'Montrez-moi mon futur bébé',
      'See Your Baby': 'Voir votre bébé',
    },
    "title": {
      'Your photo': 'Votre photo',
      'Your partner photo': 'Téléchargez la photo de votre partenaire',
      'Kid config': 'Kid config',
      'Your future baby': 'Votre futur bébé',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Paramètres du bébé",
      "What will your baby look like?": "À quoi ressemblera votre bébé ?",
      "Personalize your baby with a name, gender and skin tone": "Personnalisez votre bébé en indiquant son nom, son sexe et sa couleur de peau",
      "Your picture": "Votre photo",
      "Your partner's picture": "La photo de votre partenaire",
    },
    "Baby's name": "Nom du bébé",
    "Enter baby's name": "Saisissez le nom du bébé",
    "I hope for a": "J’espère avoir",
    "gender": {
      "Baby girl": "Une fille",
      "Baby boy": "Un garçon",
      "Either": "L’un ou l’autre",
    },
    "Select skin tone": "Sélectionnez la couleur de la peau",
    "skin": {
      "Auto-detect": "Auto-détection",
      "(90% accurate)": "",
      "Light": "Claire",
      "Medium": "Moyenne",
      "Dark": "Foncée",
      "Asian": "Asiatique",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "L’accès premium est GRATUIT pendant 3 jours",
      "See your baby for a small [amount] one-time fee": "Voyez votre bébé pour le prix modique de {{amount}}$, à payer une seule fois",
      "See your future baby!": "Voyez votre futur bébé !",
      "See your baby and generate more babies": "Voyez votre bébé et générez d’autres bébés",
      "Save, download and share your babies": "Enregistrez, téléchargez et partagez les photos de vos bébés",
      "Includes name generator & parenting lessons": "Inclut un générateur de noms et des leçons de parentalité",
    },
    "flower": {
      "Try For Free": "Essai gratuit",
      "See Your Baby": "Voir votre bébé",
      "1-day access": "avoir accès pendant un jour",
      "3-day free access": "bénéficier de 3 jours d’accès gratuit",
      // "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!",
      "Then [amount] weekly, cancel anytime!": "Ensuite c’est {{amount}} $ par semaine, et vous pouvez annuler à tout moment!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "Ensuite c’est {{amount}} $ par année, et vous pouvez annuler à tout moment!",
      "See your future baby!": "Voyez votre futur bébé !",
      "Click": "Cliquez sur",
      "to get a": "pour",
      "to your pictures and premium features": "à vos photos et aux fonctionnalités premium",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Essai gratuit",
    "See Your Baby": "Voir votre bébé",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator utilise une technologie de reconnaissance faciale de pointe et permet aux utilisateurs de générer de futures photos de leurs bébés en téléchargeant les photos de deux partenaires. BabyFaceGenerator offre aux utilisateurs un abonnement d’essai gratuit de 3 jours pour accéder à toutes les fonctionnalités premium, qui comprennent, entre autres, la possibilité de voir le bébé généré, de générer un nombre illimité de bébés, d’enregistrer, télécharger et partager les photos des bébés.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator utilise une technologie de reconnaissance faciale de pointe et permet aux utilisateurs de générer de futures photos de leurs bébés en téléchargeant les photos de deux partenaires. BabyFaceGenerator offre aux utilisateurs un abonnement d’essai gratuit de 3 jours pour accéder à toutes les fonctionnalités premium, qui comprennent, entre autres, la possibilité de voir le bébé généré, de générer un nombre illimité de bébés, d’enregistrer, télécharger et partager les photos des bébés.",
      "part2": "Les 3 premiers jours d’abonnement sont gratuits et coûtent 0,00 $, après 3 jours (si l’abonnement n’est pas annulé) l’utilisateur paiera {{amount}} $/semaine, de manière récurrente. Les paiements des abonnements récurrents se feront par Apple Pay ou Google Pay (selon la méthode utilisée pour s’abonner à l’essai gratuit) lorsque l’essai gratuit de 3 jours prendra fin. L’abonnement se renouvelle automatiquement chaque semaine ({{amount}} $/semaine), sauf s’il est annulé au moins 24 heures avant la fin de la période en cours. Votre compte sera débité pour le renouvellement de l’abonnement dans les 24 heures précédant la fin de la période en cours.",
      // "part3": "Ce service doit être utilisé uniquement à des fins de divertissement, et les photos de bébé générées n'ont rien à voir avec la génétique réelle.",
      "part4": "En accédant ou en utilisant le service, vous acceptez de vous conformer et d'être lié par les conditions d'utilisation.",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator utilise une technologie de reconnaissance faciale de pointe et permet aux utilisateurs de générer de futures photos de leurs bébés en téléchargeant les photos de deux partenaires. BabyFaceGenerator permet aux utilisateurs d’avoir accès pendant un jour à toutes les fonctionnalités premium, qui comprennent, entre autres, la possibilité de voir le bébé généré, de générer un nombre illimité de bébés, d’enregistrer, télécharger et partager les photos des bébés. Pour 1 jour d’accès premium vous payez {{amount}} $, une seule fois, PAS d’abonnement, PAS de frais cachés. Le paiement se fera une seule fois par Apple Pay ou Google Pay (en fonction de la méthode utilisée pour effectuer le paiement)",
      "unSub": "Si vous avez utilisé un abonnement d’essai gratuit, cliquez ici –",
      "unSub2": "arrêter l’abonnement/annuler l’adhésion.",
    },
    "annual": {
      "part1": "BabyFaceGenerator utilise une technologie de reconnaissance faciale de pointe et permet aux utilisateurs de générer de futures photos de leurs bébés en téléchargeant les photos de deux partenaires. BabyFaceGenerator offre aux utilisateurs un abonnement d’essai gratuit de 3 jours pour accéder à toutes les fonctionnalités premium, qui comprennent, entre autres, la possibilité de voir le bébé généré, de générer un nombre illimité de bébés, d’enregistrer, télécharger et partager les photos des bébés.",
      "part2": "Les 3 premiers jours d’abonnement sont gratuits et coûtent 0,00 $, après 3 jours (si l’abonnement n’est pas annulé) l’utilisateur paiera {{amount}} $/année, de manière récurrente. Les paiements des abonnements récurrents se feront par Apple Pay ou Google Pay (selon la méthode utilisée pour s’abonner à l’essai gratuit) lorsque l’essai gratuit de 3 jours prendra fin. L’abonnement se renouvelle automatiquement chaque année ({{amount}} $/année), sauf s’il est annulé au moins 24 heures avant la fin de la période en cours. Votre compte sera débité pour le renouvellement de l’abonnement dans les 24 heures précédant la fin de la période en cours.",
      "part3": "Ce service doit être utilisé uniquement à des fins de divertissement, et les photos de bébé générées n'ont rien à voir avec la génétique réelle.",
      "part4": "En accédant ou en utilisant le service, vous acceptez de vous conformer et d'être lié par les conditions d'utilisation.",
    },
    "signIn": "Connectez-vous",
    "signIn2": "à votre compte",
    "contactUs": "Contactez-nous",
    "contactUs2": "si vous avez besoin d’aide ou si vous avez des questions ou des demandes.",
    "unSub": "Vous pouvez annuler votre abonnement à tout moment en cliquant ici :",
    "unSub2": " Arrêter l’abonnement premium / Se désabonners",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Saisissez l'adresse e-mail associée à votre compte BabyFaceGenerator pour vous connecter",
    "Enter your email address": "Entrez votre adresse email",
    "Sign In": "S'identifier",
  },
  "EmailPopup": {
    "Important": "Important",
    "enter your email address to see a baby and manage your account": "entrez votre adresse email pour voir un bébé et gérer votre compte",
    "Enter a valid email address": "Entrez une adresse e-mail valide",
    "Email not valid": "Email not valid",
    "Save email": "Enregistrer l'e-mail",
  },
  "meta": {
    "title": "Créateur de bébés et générateur de visage de bébé",
    "description": "À quoi ressemblera mon bébé ? Téléchargez tout simplement votre photo et celle de votre partenaire et créez un bébé en quelques secondes !",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Téléchargez votre photo",
        "Upload your partner's photo": "2. Téléchargez la photo de votre partenaire",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Téléchargez votre photo pour générer un bébé",
          "Upload your partner's photo": "2. Téléchargez la photo de votre partenaire",
          "Start by uploading your photo or taking a selfie": "Commencez par télécharger votre photo ou par prendre un selfie",
          "The face should be frontal, clearly visible, no glasses": "La photo doit être prise de face, claire et sans lunettes",
          "The face should occupy at least 70% of the photo": "Le visage doit occuper au moins 70 % de la photo",
          "Proceed by uploading your partner's photo below": "Continuez en téléchargeant la photo de votre partenaire ci-dessous",
          "Face should be frontal, clearly visible, no glasses": "La photo doit être prise de face, claire et sans lunettes",
          "The face in the photo should occupy at least 70%": "Le visage sur la photo doit occuper au moins 70 %",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Paramètres du bébé",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Voyez votre futur bébé !",
        }
      },
    }
  }
}
export default fr