const pt = {
  "header": {
    "What Will Your Baby Look Like?": "Com quem seu bebê se parecerá?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Entrar com",
    "Sign In mobile": "Entrar com",
    "with your email": "seu e-mail",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Início",
    "Contact Us": "Fale conosco",
    "Feedback": "Feedback",
    "Privacy Policy": "Política de Privacidade",
    "Terms of Service": "Termos de Serviço",
    "Cookie Policy": "Política de Cookies",
    "Do Not Sell My Personal Information": "Não venda minhas informações pessoais",
    "Cancel Subscription": "Cancelar assinatura",
    "Copyright": "Copyright",
    "All rights received": "Todos os direitos recebidos",
    "About Us": "Sobre nós",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "Sobre nós",
    "Copyright": "Copyright",
    "All rights received": "Todos os direitos recebidos",
    "Contact Us": "Fale conosco",
    "Feedback": "Feedback",
    "Privacy Policy": "Política de Privacidade",
    "Terms of Service": "Termos de Serviço",
    "Cookie Policy": "Política de Cookies",
    "Do Not Sell My Personal Information": "Não venda minhas informações pessoais",
    "Unsubscribe": "Cancelar assinatura",
    "Sign In": "Entrar",
  },
  "stepsToolbar": {
    "Previous": "Anterior",
    "Next": "Próximo",
    "Step": "Etapa",
    "Try For Free": "Fazer teste grátis",
    "See Your Baby": "Veja seu bebê",
  },
  "parent": {
    "Upload your photo": "Carregar sua foto",
    "Upload your partner's photo": "Carregue a foto de seu/sua cônjuge",
    "leftBox": {
      "Upload your photo to generate a baby": "Carregue sua foto para criar um bebê",
      "Upload your partner's photo": "Carregue a foto de seu/sua cônjuge",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Comece carregando uma foto sua ou tirando uma selfie",
      "The face should be frontal, clearly visible, no glasses": "A foto deve ser de rosto, claramente visível e você deve estar sem óculos",
      "The face should occupy at least 70% of the photo": "O rosto deve ocupar pelo menos 70% da foto",
      "Proceed by uploading your partner's photo below": "Agora, carregue a foto de seu/sua cônjuge",
      "Face should be frontal, clearly visible, no glasses": "A foto deve ser de rosto, claramente visível e você deve estar sem óculos",
      "The face in the photo should occupy at least 70%": "O rosto deve ocupar pelo menos 70% da foto",
    },
    "buttons": {
      "Take a Selfie": "Tirar uma selfie",
      "OR CHOOSE A CELEBRITY": "ou escolha uma celebridade",
      "Choose a celebrity": "Escolher uma celebridade",
    },
    "dnd": {
      "Drag & drop your photo": "Arraste e solte sua foto aqui",
      "or": "ou",
      "Upload Image": "Carregar imagem",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Arquivos compatíveis em JPG, JPG200, PNG e tamanho máximo de imagem de 8 MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Selecionar celebridade",
      "Search for celebrity": "Pesquisar celebridade",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Carregar foto de seu/sua cônjuge',
      'Move to baby settings': 'Ir para as configurações do bebê',
      'Show me my Future Baby': 'Mostre meu futuro bebê',
      'See Your Baby': 'Veja seu bebê',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Configurações do bebê",
      "What will your baby look like?": "Com quem seu bebê se parecerá?",
      "Personalize your baby with a name, gender and skin tone": "Personalize seu bebê com nome, gênero e tom de pele",
      "Your picture": "Sua foto",
      "Your partner's picture": "A foto de seu/sua cônjuge",
    },
    "Baby's name": "Nome do bebê",
    "Enter baby's name": "Digite o nome do bebê",
    "I hope for a": "Tomara que seja",
    "gender": {
      "Baby girl": "Menina",
      "Baby boy": "Menino",
      "Either": "Não tenho preferência",
    },
    "Select skin tone": "Selecione o tom de pele",
    "skin": {
      "Auto-detect": "Automatic",
      "(90% accurate)": "",
      "Light": "Clara",
      "Medium": "Parda",
      "Dark": "Preta",
      "Asian": "Asiática",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "Acesso premium GRATUITO por 3 dias",
      "See your baby for a small [amount] one-time fee": "Veja seu bebê por uma taxa única de US${{amount}}",
      "See your future baby!": "Veja seu futuro bebê!",
      "See your baby and generate more babies": "Veja seu bebê e crie outros bebês",
      "Save, download and share your babies": "Salve, baixe e compartilhe as fotos de seus bebês",
      "Includes name generator & parenting lessons": "Inclui gerador de nomes e lições de parentalidade",
    },
    "flower": {
      "Try For Free": "Fazer teste grátis",
      "See Your Baby": "Veja seu bebê",
      "1-day access": "1 dia de acesso",
      "3-day free access": "3 dias de acesso gratuito",
      "Then [amount] weekly, cancel anytime!": "Depois, custa apenas US${{amount}} por semana e você pode cancelar a qualquer momento!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "Depois, custa apenas US${{amount}} por ano e você pode cancelar a qualquer momento!",
      "See your future baby!": "Veja seu futuro bebê!",
      "Click": "Clique em",
      "to get a": "para ganhar",
      "to your pictures and premium features": "a suas fotos e recursos premium",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "Fazer teste grátis",
    "See Your Baby": "Veja seu bebê",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "Com o BabyFaceGenerator, os usuários podem usar a nossa alta tecnologia de reconhecimento facial para criar imagens de bebês carregando fotos de ambos os pais. O BabyFaceGenerator oferece assinatura grátis de 3 dias de todos os recursos premium, incluindo a exibição de um bebê criado, a criação de vários bebês, a opção de salvar, baixar ou compartilhar fotos de bebês, entre outros recursos",
      // нет перевода part 2 
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period",
    },
    "weekly": {
      "part1": "Com o BabyFaceGenerator, os usuários podem usar a nossa alta tecnologia de reconhecimento facial para criar imagens de bebês carregando fotos de ambos os pais. O BabyFaceGenerator oferece assinatura grátis de 3 dias de todos os recursos premium, incluindo a exibição de um bebê criado, a criação de vários bebês, a opção de salvar, baixar ou compartilhar fotos de bebês, entre outros recursos",
      "part2": "Os primeiros 3 dias da assinatura são gratuitos e custam US$ 0,00. Após esse período, caso a assinatura não seja cancelada, o usuário será continuamente cobrado US${{amount}} por semana. O pagamento de assinaturas contínuas será cobrado através do Apple Pay ou do Google Pay (dependendo do método usado para se inscrever em um teste grátis) quando o período de teste de 3 dias terminar. A assinatura é renovada automaticamente a cada semana (US${{amount}}/semana), a menos que seja cancelada pelo menos 24 horas antes do fim do período vigente. A renovação da conta é cobrada 24 horas antes do término do período vigente.",
      // "part3": "Este serviço deve ser usado apenas para entretenimento, e as fotos de bebês geradas não têm nada em comum com a genética da vida real.",
      "part4": "Ao acessar ou usar o serviço, você concorda em cumprir e estar vinculado aos Termos de Serviço.",
    },
    "oneTime": {
      "part1": "Com o BabyFaceGenerator, os usuários podem usar a nossa alta tecnologia de reconhecimento facial para criar imagens de bebês carregando fotos de ambos os pais. O BabyFaceGenerator oferece acesso de 1 dia a todos os recursos premium, incluindo a exibição de um bebê criado, a criação de vários bebês, a opção de salvar, baixar ou compartilhar fotos de bebês, entre outros recursos. Para o acesso premium de 1 dia, há a taxa única de US$ {{amount}}, SEM assinatura e SEM taxas ocultas. O pagamento será cobrado uma vez através do Apple Pay ou Google Pay (dependendo do método usado para fazer o pagamento)",
      "unSub": "Se você usou uma assinatura de teste gratuito, clique aqui",
      "unSub2": "para pausar ou cancelar sua assinatura",
    },
    "annual": {
      "part1": "Com o BabyFaceGenerator, os usuários podem usar a nossa alta tecnologia de reconhecimento facial para criar imagens de bebês carregando fotos de ambos os pais. O BabyFaceGenerator oferece assinatura grátis de 3 dias de todos os recursos premium, incluindo a exibição de um bebê criado, a criação de vários bebês, a opção de salvar, baixar ou compartilhar fotos de bebês, entre outros recursos",
      "part2": "Os primeiros 3 dias da assinatura são gratuitos e custam US$ 0,00. Após esse período, caso a assinatura não seja cancelada, o usuário será continuamente cobrado US${{amount}} por ano. O pagamento de assinaturas contínuas será cobrado através do Apple Pay ou do Google Pay (dependendo do método usado para se inscrever em um teste grátis) quando o período de teste de 3 dias terminar. A assinatura é renovada automaticamente a cada ano (US${{amount}}/ano), a menos que seja cancelada pelo menos 24 horas antes do fim do período vigente. A renovação da conta é cobrada 24 horas antes do término do período vigente.",
      "part3": "Este serviço deve ser usado apenas para entretenimento, e as fotos de bebês geradas não têm nada em comum com a genética da vida real.",
      "part4": "Ao acessar ou usar o serviço, você concorda em cumprir e estar vinculado aos Termos de Serviço.",
    },
    "signIn": "Faça login",
    "signIn2": "em sua conta",
    "contactUs": "Fale conosco",
    "contactUs2": "para obter assistência ou em caso de dúvidas ou solicitações",
    "unSub": "Você pode cancelar sua assinatura a qualquer momento clicando aqui",
    "unSub2": "Pausar/cancelar assinatura premium",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Digite o e-mail associado à sua conta do BabyFaceGenerator para entrar",
    "Enter your email address": "Insira o seu endereço de email",
    "Sign In": "Entrar",
  },
  "EmailPopup": {
    "Important": "Importante",
    "enter your email address to see a baby and manage your account": "insira seu endereço de e-mail para ver seu bebê e gerenciar sua conta",
    "Enter a valid email address": "Digite um endereço de e-mail válido",
    "Email not valid": "Email not valid",
    "Save email": "Salvar e-mail",
  },
  "meta": {
    "title": "Criador de bebês e rostinhos de bebês",
    "description": "Com quem meu bebê se parecerá? Basta carregar fotos suas e de seu/sua cônjuge e criar um bebê em segundos!",
  },

  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Carregar sua foto",
        "Upload your partner's photo": "2. Carregue a foto de seu/sua cônjuge",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Carregue sua foto para criar um bebê",
          "Upload your partner's photo": "2. Carregue a foto de seu/sua cônjuge",
          "Start by uploading your photo or taking a selfie": "Comece carregando uma foto sua ou tirando uma selfie",
          "The face should be frontal, clearly visible, no glasses": "A foto deve ser de rosto, claramente visível e você deve estar sem óculos",
          "The face should occupy at least 70% of the photo": "O rosto deve ocupar pelo menos 70% da foto",
          "Proceed by uploading your partner's photo below": "Agora, carregue a foto de seu/sua cônjuge",
          "Face should be frontal, clearly visible, no glasses": "A foto deve ser de rosto, claramente visível e você deve estar sem óculos",
          "The face in the photo should occupy at least 70%": "O rosto deve ocupar pelo menos 70% da foto",
        },
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Configurações do bebê",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "4. Veja seu futuro bebê!",
        }
      },
    }
  }
}

export default pt;