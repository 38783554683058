const zn = {
  "header": {
    "What Will Your Baby Look Like?": "想知道未来宝宝长什么样子？",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "使用您的",
    "Sign In mobile": "使用您的",
    "with your email": "电子邮件登录",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "首页",
    "Contact Us": "联系我们",
    "Feedback": "反馈",
    "Privacy Policy": "隐私政策",
    "Terms of Service": "服务条款",
    "Cookie Policy": "Cookie 政策",
    "Do Not Sell My Personal Information": "请勿出售我的个人信息",
    "Cancel Subscription": "取消订阅",
    "Copyright": "版权所有",
    "All rights received": "保留所有权利",
    "About Us": "关于我们",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "关于我们",
    "Copyright": "版权所有",
    "All rights received": "保留所有权利",
    "Contact Us": "联系我们",
    "Feedback": "反馈",
    "Privacy Policy": "隐私政策",
    "Terms of Service": "服务条款",
    "Cookie Policy": "Cookie 政策",
    "Do Not Sell My Personal Information": "请勿出售我的个人信息",
    "Unsubscribe": "取消订阅",
    "Sign In": "登入",
  },
  "stepsToolbar": {
    "Previous": "上一步",
    "Next": "下一步",
    "Step": "步骤",
    "Try For Free": "免费试用",
    "See Your Baby": "看看您的宝宝",
  },
  "parent": {
    "Upload your photo": "上传您的照片",
    "Upload your partner's photo": "上传您伴侣的照片",
    "leftBox": {
      "Upload your photo to generate a baby": "上传您的照片生成宝宝图像",
      "Upload your partner's photo": "上传您伴侣的照片",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "开始上传您的照片或自拍",
      "The face should be frontal, clearly visible, no glasses": "面部应朝向正前方，确保清晰可见，不得佩戴眼镜",
      "The face should occupy at least 70% of the photo": "面部应至少占照片的 70%",
      "Proceed by uploading your partner's photo below": "请在下方上传您伴侣的照片以继续",
      "Face should be frontal, clearly visible, no glasses": "面部应朝向正前方，确保清晰可见，不得佩戴眼镜",
      "The face in the photo should occupy at least 70%": "面部应至少占照片的 70%",
    },
    "buttons": {
      "Take a Selfie": "自拍",
      "OR CHOOSE A CELEBRITY": "或选择一位名人",
      "Choose a celebrity": "选择一位名人",
    },
    "dnd": {
      "Drag & drop your photo": "拖放您的照片",
      "or": "或",
      "Upload Image": "上传图像",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "支持 JPG、JPG200、PNG 格式，图像最大 8MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "选择名人",
      "Search for celebrity": "选择名人",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': '上传您的伴侣',
      'Move to baby settings': '前往宝宝设置',
      'Show me my Future Baby': '向我显示我的未来宝宝',
      'See Your Baby': '看看您的宝宝',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "宝宝设置",
      "What will your baby look like?": "想知道未来宝宝长什么样子？",
      "Personalize your baby with a name, gender and skin tone": "设定您宝宝的名字、性别和肤色。",
      "Your picture": "您的照片",
      "Your partner's picture": "您伴侣的照片",
    },
    "Baby's name": "宝宝的名字",
    "Enter baby's name": "输入宝宝的名字",
    "I hope for a": "我希望是",
    "gender": {
      "Baby girl": "女宝宝",
      "Baby boy": "男宝宝",
      "Either": "都可以",
    },
    "Select skin tone": "选择肤色",
    "skin": {
      "Auto-detect": "自动检测",
      "(90% accurate)": "（准确率 90%）",
      "Light": "浅色",
      "Medium": "中等",
      "Dark": "深色",
      "Asian": "亚洲",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3 天高级权限可免费",
      "See your baby for a small [amount] one-time fee": "看看您的宝宝仅需一次性支付 {{amount}} 美元",
      "See your future baby!": "看看您的未来宝宝！",
      "See your baby and generate more babies": "查看您的宝宝，并生成更多的宝宝照片",
      "Save, download and share your babies": "保存、下载并分享您宝宝的照片",
      "Includes name generator & parenting lessons": "包括名字生成器和育儿课程",
    },
    "flower": {
      "Try For Free": "免费试用",
      "See Your Baby": "看看您的宝宝",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
      "Then [amount] weekly, cancel anytime!": "此后，每周仅需 {{amount}} 美元，随时均可取消！",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "此后，每年仅需 {{amount}} 美元，随时均可取消！",
      "See your future baby!": "看看您的未来宝宝！",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "点击“看看您的宝宝”，获得 1 天权限，期间可查看照片并使用高级功能。",
      "weekly_sub_fulltext": "点击“免费试用”，获得 3 天免费权限，期间可查看照片并使用高级功能。",
    },
    "Try For Free": "免费试用",
    "See Your Baby": "看看您的宝宝",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator 采用前沿面部识别技术，用户只需上传两张照片，即可生成未来宝宝的照片。BabyFaceGenerator 为用户提供包含全部高级功能的 3 天免费试用订阅，包括但不限于显示生成的宝宝照片、允许生成无限数量的宝宝照片，以及保存、下载并分享宝宝照片。",
      "part2": " 订阅前 3 天免费，无需支付任何费用，3 天后（如果未取消订阅）用户每周支付 {{amount}} 美元。3 天免费订阅结束后，通过 Apple Pay 或 Google Pay 扣取连续订阅费用（取决于采用的支付方式）。每周自动续订（{{amount}} 美元/周）。如需取消订阅，请在当周结束前至少提前 24 小时完成取消订阅操作。当周结束前 24 小时内，系统将从您的账户扣除续订费用。",
    },
    "weekly": {
      "part1": "BabyFaceGenerator 采用前沿面部识别技术，用户只需上传两张照片，即可生成未来宝宝的照片。BabyFaceGenerator 为用户提供包含全部高级功能的 3 天免费试用订阅，包括但不限于显示生成的宝宝照片、允许生成无限数量的宝宝照片，以及保存、下载并分享宝宝照片。",
      "part2": " 订阅前 3 天免费，无需支付任何费用，3 天后（如果未取消订阅）用户每周支付 {{amount}} 美元。3 天免费订阅结束后，通过 Apple Pay 或 Google Pay 扣取连续订阅费用（取决于采用的支付方式）。每周自动续订（{{amount}} 美元/周）。如需取消订阅，请在当周结束前至少提前 24 小时完成取消订阅操作。当周结束前 24 小时内，系统将从您的账户扣除续订费用。",
      // "part3": "本服务仅供娱乐，生成的婴儿照片与现实生活中的遗传学毫无共同之处。",
      "part4": "通过访问或使用服务，您同意遵守服务条款并受其约束。",
    },
    "oneTime": {
      "part1": "BabyFaceGenerator 采用前沿面部识别技术，用户只需上传两张照片，即可生成未来宝宝的照片。BabyFaceGenerator 为用户提供包含全部高级功能的 1 天权限，包括但不限于显示生成的宝宝照片、允许生成无限数量的宝宝照片，以及保存、下载并分享宝宝照片。1 天高级权限仅需一次性支付 {{amount}} 美元，无需订阅，无隐藏收费。通过 Apple Pay 或 Google Pay 扣费（取决于采用的支付方式）",
      "unSub": "如果您曾使用免费试订阅，请点击此处 - ",
      "unSub2": "停止订阅/取消会员。",
    },
    "annual": {
      "part1": "BabyFaceGenerator 采用前沿面部识别技术，用户只需上传两张照片，即可生成未来宝宝的照片。BabyFaceGenerator 为用户提供包含全部高级功能的 3 天免费试用订阅，包括但不限于显示生成的宝宝照片、允许生成无限数量的宝宝照片，以及保存、下载并分享宝宝照片。",
      "part2": "订阅前 3 天免费，无需支付任何费用，3 天后（如果未取消订阅）用户每年支付 {{amount}} 美元。3 天免费订阅结束后，通过 Apple Pay 或 Google Pay 扣取连续订阅费用（取决于采用的支付方式）。每年自动续订（{{amount}} 美元/年）。如需取消订阅，请在当周结束前至少提前 24 小时完成取消订阅操作。当周结束前 24 小时内，系统将从您的账户扣除续订费用。",
      "part3": "本服务仅供娱乐，生成的婴儿照片与现实生活中的遗传学毫无共同之处。",
      "part4": "通过访问或使用服务，您同意遵守服务条款并受其约束。",
    },
    "signIn": "登录到您的帐户",
    "signIn2": "",
    "contactUs": "联系我们",
    "contactUs2": "如需支持或者有任何问题或要求",
    "unSub": "您可以随时取消订阅，只需点击此处：",
    "unSub2": "停止订阅高级功能/取消订阅",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "输入与您的 BabyFaceGenerator 帐户关联的电子邮件地址以登录",
    "Enter your email address": "输入您的电子邮件地址",
    "Sign In": "登入",
  },
  "EmailPopup": {
    "Important": "重要提示",
    "enter your email address to see a baby and manage your account": "输入您的电子邮件地址以查看婴儿并管理您的帐户",
    "Enter a valid email address": "输入一个有效的电子邮件地址",
    "Email not valid": "Email not valid",
    "Save email": "保存电子邮件",
  },
  "meta": {
    "title": "宝宝模拟和宝宝外貌生成器",
    "description": "想知道未来宝宝长什么样子？只需上传您和您伴侣的照片，数秒之内就能看到您的宝宝！",
  },
}

export default zn