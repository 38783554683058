//@flow

import {declareAction, declareAtom} from "@reatom/core";

export const DetectWebView = declareAction(
  'detectWebViewAction',
  (payload, store) => {
    const ua = window.navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf("android") > -1;
    if (isAndroid) {
      const isAndroidWebView = /wv/i.test(navigator.userAgent);
      store.dispatch(SetAndroidWebView(isAndroidWebView));
    } else {
      const isIosWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
      store.dispatch(SetIosWebView(isIosWebView));
    }
  }
)

export const SetAndroidWebView = declareAction('setAndroidWebViewAction');
export const SetIosWebView = declareAction('setIosWebViewAction');

export const WebViewDetectorAtom = declareAtom(
  'webViewDetectorAtom',
  {
    isAndroid: false,
    isIos: false,
    isWebView: false,
  },
  on => [
    on(SetAndroidWebView, (state, payload) => {
      return {...state, isAndroid: true, isWebView: payload}
    }),
    on(SetIosWebView, (state, payload) => {
      return {...state, isIos: true, isWebView: payload}
    })
  ]
);