//@flow

import React, {useEffect, useState} from 'react';
import {useAction, useAtom} from "@reatom/react";
import {useTrial} from "../effects/trial";
import {PaymentAtom} from "../atoms/Payment";
import {useSteps} from "../effects/steps";
import {WebViewDetectorAtom} from "../atoms/WebViewDetector";
import {domain} from "../common";
import {useTranslation} from "react-i18next";
import {WalletType} from "../enums/WalletTypesEnum";
import {Elements} from "@stripe/react-stripe-js";
import {CardPopup} from "./CardPopup";
import {CardPaymentAtom} from "../atoms/CardPayment";
import {WalletTypeAtom} from "../atoms/WalletType";
import {useCardPay} from "../effects/cardPay";
import { useArrowExperiment, useSkipStepExperiment } from '../contexts/ExperimentsContext'
import {usePaymentType} from "../contexts/PaymentTypeContext";
import {PaymentType} from "../enums/PaymentTypesEnum";
import {ArrowEnum} from "../enums/ArrowEnum";
import {useWalletType} from "../contexts/WalletTypeContext";
import {usePaymentPayed, usePaymentTrialState} from "../contexts/PaymentStateContext";
import {
  useFinalStep,
  useClickBackHandle,
  usePrevStep,
  useCurrentStep,
  useNextStep,
  useClickNextHandle, useStepsLength
} from "../contexts/StepsContext";
import {gtagEvent, gtagEventNew} from "../utils/gtag";
import {IncrementPaymentClickCount} from "../atoms/AppConfig";
import { SkipStepExperimentEnum } from '../enums/SkipStepExperimentEnum'

export const MobileStepsToolbarUnified = (props) => {
  const arrowExperiment = useArrowExperiment()

  if (arrowExperiment === ArrowEnum.Old) {
    return (
      <MobileStepsToolbar/>
    )
  }
  if (arrowExperiment === ArrowEnum.New) {
    return (
      <MobileStepsToolbarExperiment/>
    )
  }

  return (<></>)
}

export const MobileStepsToolbar = () => {
  const lastStep = useFinalStep()
  const paymentPayed = usePaymentPayed()
  const paymentTrialState = usePaymentTrialState()

  const payed = paymentPayed || paymentTrialState;

  const showTrialButton = lastStep && !payed && paymentPayed !== null

  return (
    <nav className="mobile-timeline">
      <ul>
        <BackButton/>
        {!lastStep && (
          <NextButton/>
        )}
        {showTrialButton && (
          <TrialButton/>
        )}

      </ul>
    </nav>
  );
}
export const MobileStepsToolbarExperiment = () => {
  const lastStep = useFinalStep()
  const paymentPayed = usePaymentPayed()
  const paymentTrialState = usePaymentTrialState()

  const payed = paymentPayed || paymentTrialState;

  const showTrialButton = lastStep && !payed && paymentPayed !== null

  return (
    <nav className="mobile-timeline mobile-timeline-expirement">
      <ul>
        <BackButton/>
        {!lastStep && (
          <NextButton/>
        )}
        {showTrialButton && (
          <TrialButton/>
        )}

      </ul>
    </nav>
  );
}

const BackButton = () => {
  const {t} = useTranslation();
  const handleClickBack = useClickBackHandle()
  const isPrevStepAvailable = usePrevStep()
  const prevClass = isPrevStepAvailable ? '' : 'disable';

  return (
    <li>
      <a
        className={`prev-step ${prevClass}`}
        title={t('stepsToolbar.Previous')}
        onClick={handleClickBack}>
        <span className="bm_icon_arrow"/>
      </a>
    </li>
  );
};

const NextButton = () => {
  const webviewAtom = useAtom(WebViewDetectorAtom);
  const {t} = useTranslation();
  // const stepsHandler = useSteps();

  const currentStep = useCurrentStep()
  const isNextStepAvailable = useNextStep()
  const handleClickNext = useClickNextHandle()
  const stepsLength = useStepsLength()

  const stepId = currentStep.id;
  const nextClass = isNextStepAvailable || stepId === 3 ? 'enabled-green' : 'disable';

  let handleNext;
  if (webviewAtom.isWebView && webviewAtom.isAndroid) {
    handleNext = () => {
      window.location = `intent:${domain}${window.location.search}#Intent;end`
    }
  } else {
    handleNext = handleClickNext
  }

  const stepName = t(`steps.mobile.${currentStep.mobileName}`);
  return (
    <li>
      <a onClick={handleNext}
         className={`next-step ${nextClass}`}
         title={stepName}>
          <span className="next-step-text-box">
          {stepName}<span>{t('stepsToolbar.Step')} {stepId} / {stepsLength}</span>
          </span>
        <span className="bm_icon_arrows"/>
      </a>
    </li>
  )
}

const TrialButton = () => {

  const paymentType = usePaymentType()
  const isSubscription = paymentType !== PaymentType.OneTimePayment;

  const walletType = useWalletType()

  const {t} = useTranslation();

  const trialHandler = useTrial();


  const trialStepName = isSubscription
    ? t('stepsToolbar.Try For Free')
    : t('stepsToolbar.See Your Baby');


  return (
    <>
      {walletType === WalletType.Wallet && (
        <WalletTrial
          trialHandler={trialHandler.handleTrial}
          trialStepName={trialStepName}
        />
      )}
      {walletType === WalletType.Card && (
        <CardTrial
          trialHandler={trialHandler.handleTrial}
          trialStepName={trialStepName}
          isSubscription={isSubscription}
        />
      )}
    </>
  );
}

function WalletTrial(props) {
  const incrementPC = useAction(() => {
    return IncrementPaymentClickCount();
  })

  const {t} = useTranslation();
  const currentStep = useCurrentStep()
  const stepsLength = useStepsLength()
  const stepSkipExperiment = useSkipStepExperiment()

  const stepId = currentStep.id;
  return (
    <li className="trial-button">
      <a onClick={
        () => {
          gtagEvent({
            action: `payment_tapped`,
            label: 'general'
          });
          gtagEventNew('payment_tapped_new')


          if (stepSkipExperiment === SkipStepExperimentEnum.dontSkip) {
            gtagEventNew('original_payment_tapped')
          } else if (stepSkipExperiment === SkipStepExperimentEnum.skip) {
            gtagEventNew('experiment_payment_tapped')
          }

          props.trialHandler();

          const paymentClickCount = localStorage.getItem('paymentClickCount') ?? 0;
          gtagEvent({
            action: `payment_clicked_${parseInt(paymentClickCount) + 1}`,
            label: 'general'
          });
          incrementPC();
          gtagEvent({
            action: 'paymentClick',
            label: 'trial click'
          });
          gtagEventNew('payment_clicked_new')
          // const container = document.getElementById('trial-btn');
          // if (container) {
          //   container.style.display = 'none';
          // }

        }
      }
         className="next-step"
         title={props.trialStepName}
        >
          <span className="next-step-text-box">
            {props.trialStepName}
            <span>{t('stepsToolbar.Step')} {stepId} / {stepsLength}</span>
          </span>
        <span className="bm_icon_arrows"/>
      </a>
    </li>
  )
}

function CardTrial(props) {
  const currentStep = useCurrentStep()
  const stepsLength = useStepsLength()
  const stepId = currentStep.id;

  const paymentState = useAtom(PaymentAtom);
  const cardState = useAtom(CardPaymentAtom);

  const cardPayHandler = useCardPay();
  const [amount, setAmount] = useState(null);

  useEffect(() => {
    if (paymentState.weeklySub) {
      const amount = props.isSubscription
        ? paymentState.weeklySub.amount
        : paymentState.oneTimePay.amount;
      setAmount(amount)
    }
    return ()=>{}
  }, [paymentState.weeklySub])

  return (
    <>
      <li className="trial-button">
        <a onClick={cardPayHandler.showPopup}
           className="next-step"
           title={props.trialStepName}>
            <span className="next-step-text-box">
              {props.trialStepName}<span>{t('stepsToolbar.Step')} {stepId} / {stepsLength}</span>
            </span>
          <span className="bm_icon_arrows"/>
        </a>
      </li>
      (
      cardState.stripeObject &&
      <Elements stripe={cardState.stripeObject}>
        <CardPopup
          width={'90%'}
          amount={amount}
          isOpen={cardPayHandler.isOpen}
          closePopup={cardPayHandler.hidePopup}
          handlePayment={props.trialHandler}
          isSubscription={props.isSubscription}
        />
      </Elements>
      )
    </>
  )
}