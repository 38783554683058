import {declareAction, declareAtom} from "@reatom/core";
import {PaymentType} from "../enums/PaymentTypesEnum";
import {AppConfigAtom} from "./AppConfig";
import {SubscriberAtom, SubscriberLoaded} from "./Subscriber";
import {UtmAnalytics} from "./UtmAnalytics";
import {PaymentAtom, SetPaymentProcessor, SuccessfulPayment, UnSuccessfulPayment} from "./Payment";
import * as common from "../common";
import axios from "axios";
import {gtagEvent} from "../utils/gtag";
import {StripeApplePaymentAtom} from "./StripePayment";

export const InitCardPayment = declareAction(
  'stripeCardInitPayment',
  (payload, store) => {
    import("@stripe/stripe-js").then((stripeModule) => {
      let paymentType = PaymentType.OneTimePayment;
      let paymentRequestUrl = `${common.api}/payment-request/stripe/card/single-time`;
      if (typeof payload !== "undefined") {
        paymentType = payload.paymentType;
        switch (paymentType) {
          case PaymentType.OneTimePayment:
            paymentRequestUrl = `${common.api}/payment-request/stripe/card/single-time`;
            break;
          case PaymentType.WeeklySubscription:
            paymentRequestUrl = `${common.api}/payment-request/stripe/card/subscription`;
            break;
          case PaymentType.MonthlySubscription:
            paymentRequestUrl = `${common.api}/payment-request/stripe/card/subscription-monthly`;
            break;
          case PaymentType.AnnualSubscription:
            // paymentRequestUrl = `${common.api}/payment-request/stripe/card/subscription-annual`;
            //FIXME: платежи картами сейчас вообще не используются. так что чтоб не было ошибок 404 просто смотрим на старый недельный линк
            paymentRequestUrl = `${common.api}/payment-request/stripe/card/subscription`;
            break;
        }
      }
      store.dispatch(SetPaymentType(paymentType))
      const data = new FormData();
      data.append('paymentTypeId', payload.paymentTypeId);
      axios.post(paymentRequestUrl, data) //single time payment request
        .then(response => {
          if (response.status === 200) {
            stripeModule.loadStripe(response.data.stripePK)
              .then(stripe => {
                const elements = stripe.elements();
                store.dispatch(StoreStripeObject(stripe));
                store.dispatch(StoreElements(elements))
                store.dispatch(StorePaymentTypeId(payload.paymentTypeId));
              })
              .catch(console.error);
          }
        })
        .catch(console.error);
    })
  }
)

export const ProcessCardPayment = declareAction(
  'stripeProcessCardPaymentRequestAction',
  (payload, store) => {
    const appState = store.getState(AppConfigAtom);
    const stripeState = store.getState(StripeApplePaymentAtom);
    const subscriberState = store.getState(SubscriberAtom);
    const utmState = store.getState(UtmAnalytics);
    const paymentState = store.getState(PaymentAtom);

    let confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/stripe/card`;
    switch (stripeState.paymentType) {
      case PaymentType.OneTimePayment:
        confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/pay/stripe/card`;
        break;
      case PaymentType.WeeklySubscription:
        confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe/stripe/card`;
        break;
      case PaymentType.MonthlySubscription:
        confirmationUrl = `${common.api}/subscriber/${appState.fingerprint}/subscribe-monthly/stripe/card`;
        break;
    }

    const paymentToken = JSON.stringify(payload.token)
    const data = {
      token: paymentToken,
      paymentTypeId: payload.paymentTypeId,
      processor: 'CARD',
      gateway: paymentState.gateway,
      utmSource: utmState.utmSource,
      utmMedium: utmState.utmMedium,
      utmCampaign: utmState.utmCampaign,
    };
    axios.post(confirmationUrl, data)
      .then(
        response => {
          if (response.data.state === 'active') {
            const newSubscription = {...response.data};
            delete newSubscription.subscriber;
            const newSubscriptionsList = [...subscriberState.subscriber.subscriptions];
            newSubscriptionsList.push(newSubscription);
            store.dispatch(SubscriberLoaded({...subscriberState.subscriber, subscriptions: newSubscriptionsList}))
            store.dispatch(SetPaymentProcessor('CARD'));
            store.dispatch(SuccessfulPayment(response.data.expireDate));
            let eventData = {};
            switch (stripeState.paymentType) {
              case PaymentType.OneTimePayment:
                eventData = {
                  action: 'onetimeSuccess',
                  category: 'onetimepayment',
                  label: 'one time card payment'
                }
                break;
              case PaymentType.WeeklySubscription:
                eventData = {
                  action: 'subscriptionSuccess',
                  category: 'subscription',
                  label: 'card subscription'
                }
                break;
              case PaymentType.MonthlySubscription:
                eventData = {
                  action: 'monthlySubSuccess',
                  category: 'monthlysubscription',
                  label: 'card monthly subscription'
                }
                break;
            }
            window.fbq('track', 'BFGconversion');
            gtagEvent(eventData);
          } else {
            const container = document.getElementById('apple-pay-button');
            if (container) {
              container.style.display = 'initial';
            }
            store.dispatch(UnSuccessfulPayment());
          }
        }
      )
      .catch(console.error);
  })

const StoreStripeObject = declareAction('stripeCardStoreStripeObject');
const SetPaymentType = declareAction('stripeCardSetPaymentTypeAction');
const StorePaymentTypeId = declareAction('stripeCardStorePaymentTypeId');
const StoreElements = declareAction('stripeCardStoreElements');

export const CardPaymentAtom = declareAtom(
  'stripeCardPaymentAtom',
  {
    stripeObject: null,
    paymentType: PaymentType.OneTimePayment,
    paymentTypeId: null,
    elements: null,
  },
  on => [
    on(StoreStripeObject, (state, payload) => {
      return {...state, stripeObject: payload}
    }),
    on(StorePaymentTypeId, (state, payload) => {
      return {...state, paymentTypeId: payload}
    }),
    on(SetPaymentType, (state, payload) => {
      return {...state, paymentType: payload};
    }),
    on(StoreElements, (state, payload) => {
      return {...state, elements: payload}
    })
  ]
)