//@flow

import {useAction, useAtom} from "@reatom/react";
import {nextStep, nextStepAndGenerate, prevStep, StepsAtom} from "../atoms/Steps";
import {nextStepSkipExperiment, nextStepAndGenerateSkipExperiment, prevStepSkipExperiment, StepsSkipExperimentAtom} from "../atoms/StepsSkipExperiment";
import {PaymentAtom} from "../atoms/Payment";
import { useHistory } from 'react-router-dom';

import {useSkipStepExperiment} from "../contexts/ExperimentsContext";
import { SkipStepExperimentEnum } from "../enums/SkipStepExperimentEnum";


export const useSteps = () => {
  // const stepsState = useAtom(StepsAtom);

  // эксперимент
  const stepSkipExperiment = useSkipStepExperiment()
  // если эксперимент, тогда выдаем эксперимент шаги, иначе не эксперимент
  const stepsState = stepSkipExperiment === SkipStepExperimentEnum.dontSkip ? useAtom(StepsAtom) : useAtom(StepsSkipExperimentAtom);

  // console.log(stepsState)

  const paymentState = useAtom(PaymentAtom);
  const currentStep = stepsState.steps[stepsState.currentStepId - 1];
  const steps = stepsState.steps;

  // console.log(steps, 'steps')

  const history = useHistory();

  const handleClickNext = useAction(
    () => {
      if (currentStep.done) {
        if (stepSkipExperiment === SkipStepExperimentEnum.dontSkip) {
          return nextStep({step: currentStep});
        } else {
          return nextStepSkipExperiment({step: currentStep})
        }
      }
    },
    [currentStep]
  )

  const handleClickGenerateKid = useAction(
    () => {
      if (currentStep.done || currentStep.id === 3) {
        if ((paymentState.payed || paymentState.trial || paymentState.expired)
          // && paymentState.expirationDate > (new Date()).getTime()
        ) {

          if (stepSkipExperiment === SkipStepExperimentEnum.dontSkip) {
            return nextStepAndGenerate({step: currentStep});
          } else {
            return nextStepAndGenerateSkipExperiment({step: currentStep})
          }
        } else {
          // return nextStep({step: currentStep});
          if (stepSkipExperiment === SkipStepExperimentEnum.dontSkip) {
            return nextStep({step: currentStep});
          } else {
            return nextStepSkipExperiment({step: currentStep})
          }
        }
      }
    },
    [currentStep, paymentState]
  );

  const handleClickBack = useAction(() => {
    // если мы на странице WIKI то нажатие на кнопку назад, вернет нас на предыдущую страницу
    if (history.location.pathname === '/wiki' || history.location.pathname === '/wiki/') {
      history.goBack()
    } else {
      if (currentStep.id > 1) {
        // return prevStep({step: currentStep})
        if (stepSkipExperiment === SkipStepExperimentEnum.dontSkip) {
          return prevStep({step: currentStep});
        } else {
          return prevStepSkipExperiment({step: currentStep})
        }
      }
    }
  }, [currentStep]);

  const handleStartTrial = useAction(()=>{

  });

  const lastStep = currentStep.id === steps.length;

  const prevAvailable = currentStep.id > 1;

  const nextAvailable = !lastStep && currentStep.done;

  return {
    currentStep,
    handleClickNext,
    handleClickGenerateKid,
    handleClickBack,
    handleStartTrial,
    lastStep,
    prevAvailable,
    nextAvailable,
    steps,
  };
}