// @flow

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
import { gaID } from '../common'

export const pageView = (url: string) => {
  window.gtag('config', gaID, {
    page_location: url
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gtagEvent = ({ action, category, label, value }: any) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value
  })
}

export const gtagEventNew = (action: string, testmode) => {
  const options = {}
  if (Boolean(testmode) === true) {
    options.debug_mode = true
  }
  window.gtag('event', action, options)
}
