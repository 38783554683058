const en = {
  "header": {
    "What Will Your Baby Look Like?": "Baby Generator - What will my baby look like?",
    "Baby Face Generator": "Baby Face Generator",
    "Sign In": "Sign In",
    "Sign In mobile": "Sign In",
    "with your email": "with your email",
    "Available on Apple store": "Available on Apple store",
    "Available on": "Available on",
    "Apple store": "Apple store",
    "Android App on Google Play": "Android App on Google Play",
    "Android App on": "Android App on",
    "Google Play": "Google Play",
    "Home": "Home",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Cancel Subscription": "Unsubscribe",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "About Us": "About Us",
    "Profile": "Unsubscribe",
  },
  "main": {
    "Skip Navigation": "Skip Navigation",
  },
  "footer": {
    "About Us": "About Us",
    "Copyright": "Copyright",
    "All rights received": "All rights received",
    "Contact Us": "Contact Us",
    "Feedback": "Feedback",
    "Privacy Policy": "Privacy Policy",
    "Terms of Service": "Terms of Service",
    "Cookie Policy": "Cookie Policy",
    "Do Not Sell My Personal Information": "Do Not Sell My Personal Information",
    "Unsubscribe": "Unsubscribe",
    "Sign In": "Sign In",
    "Profile": "Unsubscribe",
  },
  "stepsToolbar": {
    "Previous": "Previous",
    "Next": "Next",
    "Step": "Step",
    "Try For Free": "Try For Free",
    "See Your Baby": "See Your Baby",
  },
  "parent": {
    "Upload your photo": "Upload your photo",
    "Upload your partner's photo": "Upload your partner's photo",
    "leftBox": {
      "Upload your photo to generate a baby": "Upload your photo to generate a baby",
      "Upload your partner's photo": "Upload your partner's photo",
      "Good Photo": "Good Photo",
      "Bad Photo": "Bad Photo",
      "Start by uploading your photo or taking a selfie": "Start by uploading your photo or taking a selfie",
      "The face should be frontal, clearly visible, no glasses": "The face should be frontal, clearly visible, no glasses",
      "The face should occupy at least 70% of the photo": "The face should occupy at least 70% of the photo",
      "Proceed by uploading your partner's photo below": "Proceed by uploading your partner's photo below",
      "Face should be frontal, clearly visible, no glasses": "Face should be frontal, clearly visible, no glasses",
      "The face in the photo should occupy at least 70%": "The face in the photo should occupy at least 70%",
    },
    "buttons": {
      "Take a Selfie": "Take a Selfie",
      "OR CHOOSE A CELEBRITY": "OR CHOOSE A CELEBRITY",
      "Choose a celebrity": "Choose a celebrity",
    },
    "dnd": {
      "Drag & drop your photo": "Drag & drop your photo",
      "or": "or",
      "Upload Image": "Upload Image",
      "Remove photo": "Remove photo",
      "Suppots JPG, JPG200, PNG and max image size of 8MB": "Supports JPG, JPG200, PNG and max image size of 8MB",
    },
    "celebs": {
      "Loading": "Loading",
      "Select Celebrity": "Select Celebrity",
      "Search for celebrity": "Search for celebrity",
    },
  },
  "steps": {
    "mobile": {
      'Upload your partner': 'Upload your partner',
      'Move to baby settings': 'Move to baby settings',
      'Show me my Future Baby': 'Show me my Future Baby',
      'See Your Baby': 'See Your Baby',
    },
    "title": {
      'Your photo': 'Your photo',
      'Your partner photo': 'Your partner photo',
      'Kid config': 'Kid config',
      'Your future baby': 'Your future baby',
    },
  },
  "kidConfig": {
    "leftBox": {
      "Baby settings": "Baby settings",
      "What will your baby look like?": "What will your baby look like?",
      "Personalize your baby with a name, gender and skin tone": "Personalize your baby with a name, gender and skin tone",
      "Your picture": "Your picture",
      "Your partner's picture": "Your partner's picture",
    },
    "Baby's name": "Baby's name",
    "Enter baby's name": "Enter baby's name",
    "I hope for a": "I hope for a",
    "gender": {
      "Baby girl": "Baby girl",
      "Baby boy": "Baby boy",
      "Either": "Either",
    },
    "Select skin tone": "Select skin tone",
    "skin": {
      "Auto-detect": "Auto-detect",
      "(90% accurate)": "(90% accurate)",
      "Light": "Light",
      "Medium": "Medium",
      "Dark": "Dark",
      "Asian": "Asian",
    },
  },
  "payScreen": {
    "leftBox": {
      "3-day premium access is FREE": "3-day premium access is FREE",
      "See your baby for a small [amount] one-time fee": "See your baby for a small ${{amount}} one-time fee",
      "See your future baby!": "4.See your future baby!",
      "See your baby [name]!": "See your baby ${{name}}!",
      "See your baby and generate more babies": "See your baby and generate more babies",
      "Save, download and share your babies": "Save, download and share your babies",
      "Includes name generator & parenting lessons": "Includes name generator & parenting lessons",
      "then [amount] weekly": "then ${{amount}} weekly",
    },
    "flower": {
      "Try For Free": "TRY FOR FREE",
      "See Your Baby": "SEE YOUR BABY",
      "1-day access": "1-day access",
      "3-day free access": "3-day free access",
// 
      "3-day free access caps": "3-DAY FREE ACCESS",

      "Then [amount] weekly, cancel anytime!": "Then ${{amount}} weekly, cancel anytime!",
      "Then [amount] monthly, cancel anytime!": "Then ${{amount}} monthly, cancel anytime!",
      "Then [amount] annually, cancel anytime!": "Then ${{amount}} annually, cancel anytime!",
      "See your future baby!": "See your future baby!",
// 
      "See your baby for free!": "See your baby for FREE!",

      "See your baby [name]!": "See your baby<br>{{name}}!",
      "Click": "Click",
      "to get a": "to get a",
      "to your pictures and premium features": "to your pictures and premium features",
      "onetime_fulltext": "",
      "weekly_sub_fulltext": "",
    },
    "Try For Free": "TRY FOR FREE",
    "See Your Baby": "SEE YOUR BABY",
  },
  "payButton": {
    "Apple Pay": "Apple Pay",
    "Google Pay": "Google Pay",
    "Credit Card": "Credit Card",
  },
  "smallPrint": {
    "monthly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00, after 3 days (if subscription is not cancelled) the user is charged ${{amount}}/month on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a monthly basis (${{amount}}/month) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
    },
    "weekly": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00. Users may see a $0.00 or $1.00 charge in a bank account or transaction report after starting a free trial, however this is only a card authorization fee that stays on hold and then released back to the user, or charged and returned back to the user. After 3 days (if subscription is not cancelled) the user is charged ${{amount}}/week on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on a weekly basis (${{amount}}/week) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
      "part3": "BabyFaceGenerator uses generative AI technology to render hypothetical images of potential babies based on user-provided photos. These images are intended for entertainment purposes only and are not predictive of actual genetic outcomes. Please be aware that the AI may occasionally produce content that could be considered inappropriate or offensive due to the unpredictability of AI generation. BabyFaceGenerator is not directed at anyone under the age of 18 years old. By using our service, you accept these conditions. User discretion is advised.",
      "part4": "By accessing or using the service, you agree to comply with and be bound by the Terms Of Service.",
    },
    "oneTime": {
      "preferTrial": "If you prefer a free trial subscription to 1-day access",
      "trial": "click here",
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 1-day access to all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies. 1-day premium access costs {{amount}} one-time fee, NO subscriptions, NO hidden fees. Payment will be charged once via Apple Pay or Google Pay (depending on the method used to make a payment)",
      "unSub": "In case you used a free trial subscription click here - ",
      "unSub2": "stop subscription/cancel membership",
    },
    "annual": {
      "part1": "BabyFaceGenerator is backed by a hi-tech face recognition technology, and allows users to generate future baby photos by uploading photos of two partners. BabyFaceGenerator provides users with a 3-day free trial subscription to access all premium features, which include but not limited to showing a generated baby, allowing to generate unlimited babies, save, download and share babies.",
      "part2": "First 3 days of subscription are free and cost $0.00. Users may see a $0.00 or $1.00 charge in a bank account or transaction report after starting a free trial, however this is only a card authorization fee that stays on hold and then released back to the user, or charged and returned back to the user. After 3 days (if subscription is not cancelled) the user is charged ${{amount}}/year on a recurring basis. Recurring subscription payments will be charged via Apple Pay or Google Pay (depending on the method used to subscribe to a free trial) when the 3-day free trial ends. Subscription automatically renews on an annual basis (${{amount}}/year) unless it is canceled at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period.",
      "part3": "BabyFaceGenerator uses generative AI technology to render hypothetical images of potential babies based on user-provided photos. These images are intended for entertainment purposes only and are not predictive of actual genetic outcomes. Please be aware that the AI may occasionally produce content that could be considered inappropriate or offensive due to the unpredictability of AI generation. BabyFaceGenerator is not directed at anyone under the age of 18 years old. By using our service, you accept these conditions. User discretion is advised.",
      "part4": "By accessing or using the service, you agree to comply with and be bound by the Terms Of Service.",
    },
    "signIn0": "Proceed to the",
    "signIn": "Account",
    "signIn2": "page to see your profile and manage your account.",
    "contactUs0": "Proceed to the",
    "contactUs": "Contact us",
    "contactUs2": "page for support, or in case you have any questions or requests.",
    "contactUs3": "Please use Contact Us for special requests and questions only, in case you want to manage your account or subscription please proceed to the Manage Account page.",
    "unSub": "You can cancel your subscription anytime by clicking here:",
    // "unSub2": "Stop premium subscription / Unsubscribe",
    "unSub2": "Unsubscribe",
  },
  "SignInPopup": {
    "Enter the email address associated with your BabyFaceGenerator account to sign in": "Enter the email address associated with your BabyFaceGenerator account to sign in",
    "Enter your email address": "Enter your email address",
    "Sign In": "Sign In",
  },
  "EmailPopup": {
    "Important": "Important",
    "enter your email address to see a baby and manage your account": "Enter your email address to see a baby and manage your account",
    "Enter a valid email address": "Enter a valid email address",
    "Email not valid": "Email not valid",
    "Save email": "Save email",
  },
  "meta": {
    "title": "Baby Maker & Baby Face Generator | What will my baby look like?",
    "description": "What will our baby look like? Just upload photos of yourself and your partner and create a baby in seconds!",
  },
  "seo": {
    "pic1alt": 'baby picture generator',
    "pic2alt": 'baby predictor face',
    "pic3alt": 'baby maker generator',
    "pic4alt": 'what will my child look like',
    "small": {
      "part1": "Are you in a serious relationship and have you been asking yourself “what would our baby look like”? With the help of artificial intelligence technologies and deep learning, it is possible to predict the appearance of your future baby. Our algorithm works in real-time, and it takes one photo from each “parent” to see your future little one!",
      "part2": "This website can be used to easily predict the appearance of your future baby, or to create family photo albums to then share with your friends and relatives.",
      "h2": "Baby Maker",
      "part3": "The Baby Face Generator analyzes up to 70 facial features of your and your partner's face to precisely generate the face of your future baby! Our newly developed algorithms identify all the major facial features of one parent or partner, such as face shape, skin tone, hair color, eye color, and many more, and then AI technology matches identified parameters with other parent or partner, whose uploaded photos are processed as well.",
      "part4": "Shortly speaking, a server-based computer processes two photographs with the help of face recognition technology to create a realistic face of newborn babies. When two faces from uploaded head shots of the partners are processed, the future baby generator algorithm creates a new baby that is partner based (not using any randomization or fun mechanics). We apply newly developed algorithms to make sure the baby is generated as precisely as possible, for example high quality color rendering makes the baby's hair match the color of the partners' hair.",
    },
  },
  "cardPopup": {
    "title otp": "See your baby!",
    "title sub": "3-day premium access is FREE!",
    "subtitle otp [amount]": "For a small ${{amount}} one-time fee",
    "subtitle sub [amount]": "Then ${{amount}} weekly, cancel anytime.",
    "message": "Please wait, payment in progress",
    "buttons": {
      "pay [amount]": "PAY ${{amount}}",
      "pay": "Pay Now",
      "subscribe": "Free Trial",
    }
  },
  "experiment": {
    "unsub": {
      "unsubscribe": "Unsubscribe",
      "membership": "Membership",
      "profile": "Profile",
      "opt_out": "Opt Out",
      "unregister": "Unregister",
    }
  },


  "experiments":{
    "pageShort":{
      "parent": {
        "Upload your photo": "Upload your photo",
        "Upload your partner's photo": "Upload your partner's photo",
        "leftBox": {
          "Upload your photo to generate a baby": "1. Upload your photo to generate a baby",
          "Upload your partner's photo": "2. Upload your partner's photo",
          "Start by uploading your photo or taking a selfie": "The face should be frontal",
          "The face should be frontal, clearly visible, no glasses": "The face should be clearly visible",
          "The face should occupy at least 70% of the photo": "Avoid low-quality images",
          "Proceed by uploading your partner's photo below": "The face should be frontal",
          "Face should be frontal, clearly visible, no glasses": "The face should be clearly visible",
          "The face in the photo should occupy at least 70%": "Avoid low-quality images",
        }
      },
      "kidConfig": {
        "leftBox": {
          "Baby settings": "3. Baby settings",
        }
      },
      "payScreen": {
        "leftBox": {
          "See your future baby!": "3. See your future baby!",
        }
      },
    }
  }
}
export default en;