// @flow

import 'regenerator-runtime/runtime'
import { declareAction, declareAtom } from '@reatom/core'
import axios from 'axios'
import * as common from './../common'
import { uploadEnd, uploadProgress, uploadStart } from './UploadState'
import { stepDone } from './Steps'
import { experimentStepDone }  from './StepsSkipExperiment'
import { ParentEnum } from '../enums/ParentEnum'
import { ErrorLog, ResponseLog } from './Debug'
import { hideWarning, showWarning } from './Util'
import { gtagEvent } from '../utils/gtag'

const uploadDone = declareAction('uploadDoneAction')

export const uploadImage = declareAction(
  'uploadImageAction',
  async (payload, store) => {
    store.dispatch(uploadStart())

    const form = new FormData()
    form.append('parent', payload.parent)
    form.append('subscriberId', payload.subscriberId)
    form.append('uploadfile', payload.file)
    form.append('alg', payload.alg)

    const axiosUploadConfig = {
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        store.dispatch(uploadProgress(percentCompleted))
      }
    }

    axios.post(
      `${common.api}/upload/photo`,
      form,
      axiosUploadConfig
    ).then(res => {
      // store.dispatch(ResponseLog(res));
      store.dispatch(uploadEnd())
      if (res.data.parentMedia.indexOf('BabyMaker_') === 0) {
        const picUrl = parseInt(payload.alg) !== 100 ? `/babymaker/2/uploaded/${res.data.parentMedia}.jpg`
          : `/uploads/${res.data.parentMedia}.jpg`;
        localStorage.setItem(payload.parent, picUrl)
        localStorage.setItem(`${payload.parent}Webcam`, payload.webcam)
        localStorage.setItem(`${payload.parent}Id`, res.data.parentId)
        localStorage.setItem(`${payload.parent}IsCeleb`, payload.isCeleb)
        store.dispatch(
          uploadDone({
            parent: payload.parent,
            pic: picUrl,
            webcam: payload.webcam,
            parentId: res.data.parentId,
            isCeleb: payload.isCeleb,
          })
        )
        store.dispatch(hideWarning())
        const stepId = payload.parent === ParentEnum.firstParent ? 1 : 2

        store.dispatch(stepDone({ step: stepId, pageSizeExperiment: payload.pageSizeExperiment }))
        store.dispatch(experimentStepDone({ step: stepId, pageSizeExperiment: payload.pageSizeExperiment }))

        if (localStorage.getItem('firstParentIsCeleb') === 'true' && localStorage.getItem('secondParentIsCeleb') === 'true') {
          gtagEvent({
              action: 'parent_bothcelebs',
              category: 'general'
            }
          )
        }
        if (localStorage.getItem('firstParentIsCeleb') === 'false' && localStorage.getItem('secondParentIsCeleb') === 'false') {
          gtagEvent({
              action: 'parent_bothphotos',
              category: 'general'
            }
          )
        }
      }
      if (res.data === 'error_format') {
        store.dispatch(showWarning({ text: 'Unrecognized file format' }))
      }
    }).catch(e => {
      store.dispatch(ErrorLog(e))
      store.dispatch(uploadEnd())
      // console.log(e)
    })
  }
)

interface FileState {
  firstParent: Object | null,
  secondParent: Object | null,
}

const loadInitState = () => {
  const state: FileState = { firstParent: null, secondParent: null }
  state.firstParent = localStorage.getItem(ParentEnum.firstParent)
    ? {
      pic: localStorage.getItem(ParentEnum.firstParent),
      fromWebcam: localStorage.getItem('firstParentWebcam') === 'true',
      parentId: localStorage.getItem('firstParentId'),
      isCeleb: localStorage.getItem('firstParentIsCeleb') === 'true',
    }
    : null
  state.secondParent = localStorage.getItem(ParentEnum.secondParent)
    ? {
      pic: localStorage.getItem(ParentEnum.secondParent),
      fromWebcam: localStorage.getItem('secondParentWebcam') === 'true',
      parentId: localStorage.getItem('secondParentId'),
      isCeleb: localStorage.getItem('secondParentIsCeleb') === 'true',
    }
    : null
  return state
}

export const ClearBothParents = declareAction(
  'clearBothParentsAction',
  (payload, store) => {
    store.dispatch(ClearParent(ParentEnum.firstParent))
    store.dispatch(ClearParent(ParentEnum.secondParent))
  }
)

export const ClearParent = declareAction('clearParentAction')

const initState = loadInitState()

export const ImagesAtom = declareAtom(
  'parentImagesAtom',
  initState,
  on => [
    on(uploadDone, (state, payload) => {
      let parentData = {}
      if (payload.parent === ParentEnum.firstParent) {
        parentData = {
          firstParent: {
            pic: payload.pic,
            fromWebcam:
            payload.webcam,
            parentId: payload.parentId,
            isCeleb: payload.isCeleb
          }
        }
      } else if (payload.parent === ParentEnum.secondParent) {
        parentData = {
          secondParent: {
            pic: payload.pic,
            fromWebcam: payload.webcam,
            parentId: payload.parentId,
            isCeleb: payload.isCeleb
          }
        }
      }
      return { ...state, ...parentData }
    }),
    on(ClearParent, (state, payload) => {
      let parentData = {}
      if (payload.parent === ParentEnum.firstParent) {
        parentData = { firstParent: null }
      } else if (payload.parent === ParentEnum.secondParent) {
        parentData = { secondParent: null }
      }
      return { ...state, ...parentData }
    })
  ]
)

