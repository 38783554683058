import axios from "axios";
import * as common from "../common";
import {declareAction, declareAtom} from "@reatom/core";
import {AppConfigAtom} from "./AppConfig";

// ======================================================================================================
export const LoadBillingReceipts = declareAction('loadBillingReceipts');

export const BillingReceipts = declareAction(
  'billingReceipts',
  (payload, store) => {
    const appState = store.getState(AppConfigAtom);
    axios.get(`${common.api}/billing-receipts/${appState.fingerprint}`)
      .then(res => {
        window.location.href=res.data.portal_url;
        // store.dispatch(LoadBillingReceipts(res.data))
      })
      .catch(console.error);
  }
);

export const BillingReceiptsAtom = declareAtom(
  'billingReceiptsAtom',
  {
    url: '',
  },
  on => [
    on(LoadBillingReceipts, (state, payload) => {
      return {...state, url: payload};
    })]
)

// ======================================================================================================
