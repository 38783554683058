import React from "react";
import {useTranslation} from "react-i18next";
import {useAction, useAtom} from "@reatom/react";
import {Header} from "./static/header";
import Footer from "./static/footer";
import {StepsToolbar} from "./StepsToolbar";
import {Screen} from "./Screen";
import {MobileStepsToolbarUnified} from "./MobileStepsToolbar";
import {AppConfigAtom, ChangeVersion} from "../atoms/AppConfig";
import {useLocation, useHistory} from "react-router-dom";
import {ExperimentsProvider} from "../contexts/ExperimentsContext";
import {PaymentTypeProvider} from "../contexts/PaymentTypeContext";
import {WalletTypeProvider} from "../contexts/WalletTypeContext";
import {PaymentStateProvider} from "../contexts/PaymentStateContext";
import {SubscriberStateProvider} from "../contexts/SubscriberStateContext";
import {StepsProvider} from "../contexts/StepsContext";
import {AppConfigProvider} from "../contexts/AppContext";
import {AndroidWebViewProvider} from "../contexts/AndroidWebViewContext";
import useExperimentsResolver from "../effects/experiments";


const Wrapper = () => {
  const experiments = useExperimentsResolver()
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const appState = useAtom(AppConfigAtom);
  const changeVersion = useAction(v=>ChangeVersion(v));

  document.body.dir = i18n.dir();
  document.title = t('meta.title');
  document.querySelector('meta[name="description"]').content = t('meta.description')
  const lang = i18n.language === 'en' ? `` : `${i18n.language}/`;
  document.querySelector('link[rel="canonical"]').href = `https://babyfacegenerator.com/${lang.toLowerCase()}`;


  const location = useLocation();

  const pagesWithoutCTA = [
    '/profile', '/profile/', '/contact-us', '/contact-us/',
  ];

  const [showToolbars, setShowToolbars] = React.useState(true);

  React.useEffect(()=>{
    // console.log(experiments.aiExperiment)
    changeVersion(experiments.aiExperiment)
  },[])

  React.useEffect(() => {
    const path = location.pathname;
    setShowToolbars(!pagesWithoutCTA.includes(path));
  }, [location.pathname])


  return (
    <>
      <AppConfigProvider>
        <ExperimentsProvider>
          <PaymentTypeProvider>
            <WalletTypeProvider>
              <PaymentStateProvider>
                <SubscriberStateProvider>
                  <StepsProvider>
                    <AndroidWebViewProvider>
                      <div className="wrapper">
                        <a className="btn btn-jump-to-content"
                           href="#main-content"
                           title={t('main.Skip Navigation')}
                        >{t('main.Skip Navigation')}</a>
                        <Header/>

                        <main id="main-content">
                          <div className="container">
                            {
                              !appState.isMobile &&
                              showToolbars && !(
                                history.location.pathname === '/wiki'
                                || history.location.pathname === '/wiki/'
                              ) &&
                              <StepsToolbar/>
                            }
                              <Screen/>
                            {
                              appState.isMobile &&
                              showToolbars &&
                              <MobileStepsToolbarUnified/>
                            }
                          </div>
                        </main>
                      </div>

                      <Footer/>
                    </AndroidWebViewProvider>
                  </StepsProvider>
                </SubscriberStateProvider>
              </PaymentStateProvider>
            </WalletTypeProvider>
          </PaymentTypeProvider>
        </ExperimentsProvider>
      </AppConfigProvider>
    </>
  )
}

export default Wrapper;