//@flow

import { declareAction, declareAtom } from '@reatom/core'
import axios from 'axios'
import md5 from 'md5'
import { InitCelebs } from './Celebs'
import { SubscriberLoaded } from './Subscriber'
import * as common from './../common'
import { DetectWebView } from './WebViewDetector'
import { StoreUtmData } from './UtmAnalytics'
import { GetGateway, GetPaymentType } from './Payment'
import { SetWalletType } from './WalletType'
import { WikiArticles } from './ArticlesConfig'
import { InitGooglePayment } from './GooglePayment'
import useExperimentsResolver from '../effects/experiments'
import { GeneratorExperiment } from '../enums/GeneratorExperimentEnum'

export const InitConfig = declareAction(
  'initConfigAction',
  (payload, store) => {
    store.dispatch(LoadUID())
    // store.dispatch(InitCelebs())
  }
)

export const InitApp = declareAction(
  'initAppAction',
  (payload, store) => {
    localStorage.setItem('paymentClickCount', 0)
    store.dispatch(StoreUtmData())
    store.dispatch(SetWalletType())
    store.dispatch(GetPaymentType())
    store.dispatch(GetGateway())
    store.dispatch(DetectWebView())
    store.dispatch(WikiArticles())

    const urlParams = new URLSearchParams(window.location.search)
    let uid = localStorage.getItem('fingerid')
    if (urlParams.get('code') !== null) {
      uid = urlParams.get('code')
    }
    if (urlParams.get('clickid') !== null) {
      store.dispatch(StoreRemobyId({ remobyId: urlParams.get('clickid') }))
    }
    if (uid !== null && typeof uid !== 'undefined' && uid.trim().length > 0) {
      store.dispatch(FingerprintLoaded(uid))
      axios.get(`${common.api}/subscriber/${uid}`).then(response => {
        if (response.status === 200) {
          store.dispatch(SubscriberLoaded(response.data))
          localStorage.setItem('fingerid', uid)
        }
      })
      store.dispatch(StoreFingerprintParts(JSON.stringify([atob(uid)])))
      store.dispatch(InitConfig())
    } else {
      axios.get('https://api.ipify.org?format=json').then(res => {
        const navigator = window.navigator.userAgent
        const time = Date.now()
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
        uid = md5(`${res.data.ip}${time}${navigator}${timezone}`)

        localStorage.setItem('fingerid', uid)
        store.dispatch(FingerprintLoaded(uid))
        axios.get(`${common.api}/subscriber/${uid}`).then(response => {
          if (response.status === 200) {
            store.dispatch(SubscriberLoaded(response.data))
          }
        })
        store.dispatch(StoreFingerprintParts(JSON.stringify([res.data.ip, time, navigator, timezone])))
        store.dispatch(InitConfig())
      }).catch(console.error)
    }
  }
)

export const FingerprintLoaded = declareAction('fingerPrintLoadedAction')
const StoreFingerprintParts = declareAction('storeFingerPrintPartsAction')
const StoreRemobyId = declareAction('storeRemobyIdAction')

const LoadUID = declareAction(
  'loadUIDAction',
  (payload, store) => {
    let uid = localStorage.getItem('uid')
    if (uid !== null && typeof uid !== 'undefined' && uid.trim().length > 0) {
      store.dispatch(UIDLoaded(uid))
    } else {
      axios.get('https://api.ipify.org?format=json').then(res => {
        const navigator = window.navigator.userAgent
        const time = Date.now()
        uid = md5(res.data.ip + navigator + time)

        localStorage.setItem('uid', uid)
        store.dispatch(UIDLoaded(uid))
      }).catch(console.error)
    }
  }
)

const UIDLoaded = declareAction('uidLoadedAction')

const loadInitState = () => {
  let isMobile = false
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)) {
    isMobile = true
    document.querySelector('body')?.classList.add('is-mobile')
  }

  return {
    isMobile: isMobile,
    uid: null,
    fingerprint: null,
    fingerprintParts: null,
    wideFrame: 0, // 0,1
    // newFaceAlgorithm: 0, // 0,1
    // version: 27, // 21-27
    newFaceAlgorithm: 1, // 0,1
    //version: 28, // 21-28
    version: 30, // 21-28
    paymentClickCount: 0,
    // gateway: 'bluesnap'
    gateway: 'stripe',
    remobyId: null,
  }
}

export const IncrementPaymentClickCount = declareAction(
  'IncrementPaymentClickCountAction',
  (payload, store) => {
    localStorage.setItem('paymentClickCount', store.getState(AppConfigAtom).paymentClickCount)
  }
)
export const ChangeGateway = declareAction(
  'ChangeGatewayAction',
  (payload, store) => {
  }
)

export const ChangeVersion = declareAction(
  'changeGeneratorVersionAction',
  (payload, store) => {
  }
)

const initState = loadInitState()

export const AppConfigAtom = declareAtom(
  'appConfigAtom',
  initState,
  on => [
    on(FingerprintLoaded, (state, payload) => {
      return { ...state, fingerprint: payload }
    }),
    on(StoreFingerprintParts, (state, payload) => {
      return { ...state, fingerprintParts: payload }
    }),
    on(UIDLoaded, (state, payload) => {
      return { ...state, uid: payload }
    }),
    on(IncrementPaymentClickCount, (state, payload) => {
      return { ...state, paymentClickCount: parseInt(state.paymentClickCount) + 1 }
    }),
    on(ChangeGateway, (state, payload) => {
      return { ...state, gateway: payload }
    }),
    on(ChangeVersion, (state, payload) => {
      return { ...state, version: payload }
    }),
    on(StoreRemobyId, (state, payload) => {
      return { ...state, ...payload }
    })
  ])