// @flow

export const SkinEnum = Object.freeze({/*auto: -1,*/ light: 0, medium: 1, dark: 2, asian: 3});
export const SkinProps = {
  // auto: {class: 'bm_icon_reload-1', title: 'Auto-detect', subTitle: '(90% accurate)'},
  light: {class: 'icon-color light', title: 'Light', subTitle: ''},
  medium: {class: 'icon-color dark', title: 'Medium', subTitle: ''},
  dark: {class: 'icon-color very-dark', title: 'Dark', subTitle: ''},
  asian: {class: 'icon-color asian', title: 'Asian', subTitle: ''},
};
