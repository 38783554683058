import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const useLanguages = () => {
  const history = useHistory();
  const {i18n} = useTranslation();
  // const languages = ['en', 'fr', 'de', 'tr', 'pl', 'pt', 'zh', 'ru', 'hi', 'es'];
  const languages = [
    { url: 'en', name: 'English'},
    { url: 'fr', name: 'Français'},
    { url: 'de', name: 'Deutsch'},
    { url: 'tr', name: 'Türkçe'},
    { url: 'pl', name: 'Polski'},
    { url: 'pt', name: 'Português'},
    { url: 'zh', name: '中文'},
    { url: 'ru', name: 'Русский'},
    { url: 'hi', name: 'हिन्दी'},
    { url: 'es', name: 'Español'}];
  const seoLandings = [
    // {url: 'my-baby-generator', name: 'Baby Generator',},
    // {url: 'see-what-will-my-baby-look-like', name: 'What Will My Baby Look Like',},
    // {url: 'see-what-will-our-baby-look-like', name: 'What Will Our Baby Look Like',},
    // {url: 'what-would-our-baby-look-like', name: 'What Would Our Baby Look Like',}
  ]
  const languageChangeHandler = (lang) => {
    const url = lang === 'en' ? `/${document.location.search}` : `/${lang}/${document.location.search}`
    history.replace(url);
    i18n.changeLanguage(lang);
  }
  return {languages, seoLandings, languageChangeHandler}
}

export default useLanguages;
