//@flow

import {useEffect} from 'react';
// import ReactPixel from "react-facebook-pixel";
// import * as common from './../common';

const preventDefault = (e: MouseEvent | TouchEvent) => {
  e.preventDefault();
}

export const useStartupEffect = () => {
  //prevent default
  useEffect(() => {
    document.querySelectorAll('.prevent, .btn-modal, a[href="#"], .timeline a.active').forEach(disabled => {
      disabled.addEventListener('click', preventDefault);
      disabled.addEventListener('touchcancel', preventDefault);
      disabled.addEventListener('touchstart', preventDefault,{passive:true});
      disabled.addEventListener('touchmove', preventDefault,{passive:true});
      disabled.addEventListener('touchend', preventDefault);
    });
    return () => {
      document.querySelectorAll('.prevent, .btn-modal, a[href="#"], .timeline a.active').forEach(disabled => {
        disabled.removeEventListener('click', preventDefault);
        disabled.removeEventListener('touchcancel', preventDefault);
        disabled.removeEventListener('touchstart', preventDefault);
        disabled.removeEventListener('touchmove', preventDefault);
        disabled.removeEventListener('touchend', preventDefault);
      });
    }
  });

  //burger
  useEffect(() => {
    const mobileMenuToggle = document.querySelector('#header .mobile-menu-toggle');

    const burgerMenu = () => {
      mobileMenuToggle?.classList.toggle('active');
      document.querySelector('.mobile-menu-wrap')?.classList.toggle('showing');
      document.querySelector('body')?.classList.toggle('overflow');
    }

    const burgerPreparation = () => {
      if (document.documentElement !== null) {
        const width = document.documentElement.clientWidth;
        if (width > 768 && mobileMenuToggle?.classList.contains('active')) {
          burgerMenu();
        }
      }
    }

    mobileMenuToggle?.addEventListener('click', burgerMenu);
    // window.addEventListener('resize', burgerPreparation);

    return () => {
      mobileMenuToggle?.removeEventListener('click', burgerMenu);
      // window.removeEventListener('resize', burgerPreparation);
    }
  });

  //header scroll
  useEffect(() => {
    let lastScrollTop = 0,
      delayHeight = 0,
      testCount = 0,
      workTrigger = 0;

    const body = document.querySelector('body'),
      header = document.getElementById('header'),
      headerOffset = header !== null ? header.offsetTop : 0,
      headerHeight = header !== null ? header.offsetHeight : 0,
      headerTrigger = headerOffset + headerHeight;

    const currentPos = window.pageYOffset;

    const scroller = () => {
      const top = window.pageYOffset;
      if (top !== 0) {
        header?.classList.add('scrolling');
      } else {
        header?.classList.remove('scrolling');
      }

      if (lastScrollTop > top) {
        // scroll UP
        if (top === 0 && top < headerTrigger) {
          if (body?.classList.contains('direction-up')) {
            body?.classList.remove('direction-up');
          }
        } else if (top !== 0 && top > headerTrigger) {
          if (workTrigger === 1) {
            if (!(body?.classList.contains('direction-up'))) {
              body?.classList.remove('direction-down');
              body?.classList.add('direction-up');
            }
          }
        }
      } else {
        // scroll DOWN
        if (workTrigger === 1) {
          if (top > headerTrigger) {
            if (!(body?.classList.contains('direction-down'))) {
              body?.classList.remove('direction-up');
              body?.classList.add('direction-down');
            }
          }
        }
      }

      if (testCount > delayHeight || testCount < -delayHeight) {
        testCount = 0;
        workTrigger = 1;
      } else {
        testCount = testCount + (top - lastScrollTop);
        workTrigger = 0;
      }

      lastScrollTop = top;
    }

    const headerScroll = () => {
      const windowWidth = window.document.documentElement.clientWidth;

      if (windowWidth < 768) {
        if (currentPos !== 0) {
          header?.classList.add('scrolling');
        }
        window.removeEventListener('scroll', scroller);
        window.addEventListener('scroll', scroller);
      } else {
        body?.classList.remove('direction-up', 'direction-down');
        header?.classList.remove('scrolling');
      }
    }

    const headerScrollWrap = () => {
      body?.classList.remove('direction-up', 'direction-down');
      header?.classList.remove('scrolling');
      setTimeout(headerScroll, 10);
    }

    // window.addEventListener('resize', headerScrollWrap);
    window.addEventListener('load', headerScrollWrap);
    return () => {
      // window.removeEventListener('resize', headerScrollWrap);
      window.removeEventListener('load', headerScrollWrap);
    }
  });

  //change input
  useEffect(() => {
    const styleInput = (e: any) => {
      if (e.target.value.trim()) {
        if (!e.target.classList.contains('not-empty')) {
          e.target.classList.add('not-empty');
        }
      } else {
        if (e.target.classList.contains('not-empty')) {
          e.target.classList.remove('not-empty');
        }
      }
    }

    document.querySelectorAll('input.input-style').forEach(input => {
      input.addEventListener('keyup', styleInput);
      input.addEventListener('change', styleInput);
      input.addEventListener('load', styleInput);
    })

    return () => {
      document.querySelectorAll('input.input-style').forEach(input => {
        input.removeEventListener('keyup', styleInput);
        input.removeEventListener('change', styleInput);
        input.removeEventListener('load', styleInput);
      })
    }
  });

  //init FBPixel
  // useEffect(() => {
  //   const advancedMatching = {};
  //   const options = {
  //     autoConfig: true,
  //     debug: common.debugMode,
  //   };
  //   ReactPixel.init(`${common.FBPixelID}`, advancedMatching, options);
  //
  //   ReactPixel.pageView();
  // })
};