// @flow

import {declareAction, declareAtom} from "@reatom/core";
import {SkinEnum} from "../enums/SkinEnum";
import {GenderEnum} from "../enums/GenderEnum";
import {StyleEnum} from "../enums/StyleEnum";
import axios from "axios";
import * as common from "../common";

const loadInitState = () => {
  let state = {
    name: '',
    gender: GenderEnum.either,
    skin: SkinEnum.medium,
    frame: 6, //frame with "babyfacegenerator.com"
    avatar: -1,
    style: StyleEnum.photoRealistic,

    //probably must be moved to app config
    frames: [],
    avatars: {still: [], animated: []},
    noFrame: 0,
    babiesName: [],
    professions: [],
  };
  if (localStorage.getItem('kidConfig')) {
    state = {...state, ...JSON.parse(localStorage.getItem('kidConfig') || '{}')}
  }
  return state;
};

const initState = loadInitState();

export const SaveKidConfig = declareAction(
  'saveKidConfigAction',
  (payload, store) => {
    const kidConfig = store.getState(KidConfigAtom);
    localStorage.setItem('kidConfig', JSON.stringify(kidConfig));
  }
);

export const changeKidName = declareAction(
  'changeKidNameAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const changeKidGender = declareAction(
  'changeKidGenderAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const changeKidColor = declareAction(
  'changeKidColorAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const changeFrame = declareAction(
  'changeFrameAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const changeAvatar = declareAction(
  'changeAvatarAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const changeStyle = declareAction(
  'changeStyleAction',
  (payload, store) => {
    store.dispatch(SaveKidConfig());
  }
);
export const showFramePopup = declareAction('showFramePopupAction');
export const hideFramePopup = declareAction('hideFramePopupAction');

export const FramesUpdated = declareAction('framesUpdatedAction');

export const InitFrames = declareAction(
  'initFramesAction',
  (payload, store) => {
    axios.get(`${common.api}/frames`).then(res => {
      store.dispatch(FramesUpdated(res.data));
    }).catch(console.error);
  }
);

export const LoadStillAvatars = declareAction('loadStillAvatarsAction');
export const LoadAnimatedAvatars = declareAction('loadAnimatedAvatarsAction');

export const LoadBabiesName = declareAction('loadBabiesName');
export const LoadProfessions = declareAction('loadProfessions');


export const InitAvatars = declareAction(
  'initAvatarsAction',
  (payload, store) => {
    axios.get(`${common.api}/avatars`).then(res => {
      store.dispatch(LoadStillAvatars(res.data));
    }).catch(console.error);
  }
);


export const BabiesName = declareAction(
  'loadBabiesName',
  (payload, store) => {
    axios.get(`${common.api}/babiesName`).then(res => {
      store.dispatch(LoadBabiesName(res.data));
    }).catch(console.error);
  } 
);


export const Professions = declareAction(
  'loadProfessions',
  (payload, store) => {
    axios.get(`${common.api}/professions`).then(res => {
      // проверяю приходит ли что-то
      store.dispatch(LoadProfessions(res.data));
    }).catch(console.error);
  } 
);

export const KidConfigAtom = declareAtom(
  'kidConfigAtom',
  initState,
  on => [
    on(changeKidName, (state, payload) => {
      return {...state, name: payload};
    }),
    on(changeKidGender, (state, payload) => {
      return {...state, gender: payload};
    }),
    on(changeKidColor, (state, payload) => {
      return {...state, skin: payload};
    }),
    on(changeFrame, (state, payload) => {
      return {...state, frame: payload};
    }),
    on(changeAvatar, (state, payload) => {
      return {...state, avatar: payload};
    }),
    on(changeStyle, (state, payload) => {
      return {...state, style: payload};
    }),
    on(showFramePopup, (state) => {
      return {...state, showFrames: true};
    }),
    on(hideFramePopup, (state) => {
      return {...state, showFrames: false};
    }),
    on(FramesUpdated, (state, payload) => {
      return {...state, frames: payload};
    }),
    on(LoadStillAvatars, (state, payload) => {
      const avatars$ = {...state.avatars, still: payload};
      return {...state, avatars: avatars$};
    }),
    on(LoadAnimatedAvatars, (state, payload) => {
      const avatars$ = {...state.avatars, animated: payload};
      return {...state, avatars: avatars$};
    }),
    on(LoadBabiesName, (state, payload) => {
      return {...state, babiesName: payload}
    }),
    on(LoadProfessions, (state, payload) => {
      return {...state, professions: payload}
    }),
  ]
);