import React, { useContext } from 'react'

import useExperimentsResolver from '../effects/experiments'

const SizeExperimentContext = React.createContext()
const UnsubExperimentContext = React.createContext()
const PayScreenExperimentContext = React.createContext()
const BottomSheetExperimentContext = React.createContext()
const FirstPageExperimentContext = React.createContext()
const ArrowExperimentContext = React.createContext()
const GeneratorExperimentContext = React.createContext()
const PageSizeExperimentContext = React.createContext()
const SkipStepExperimentContext = React.createContext()
export function useSizeExperiment () {
  return useContext(SizeExperimentContext)
}

export function useUnsubExperiment () {
  return useContext(UnsubExperimentContext)
}

export function usePayScreenExperiment () {
  return useContext(PayScreenExperimentContext)
}

export function useBottomSheetExperiment () {
  return useContext(BottomSheetExperimentContext)
}

export function useFirstPageExperiment () {
  return useContext(FirstPageExperimentContext)
}

export function useArrowExperiment () {
  return useContext(ArrowExperimentContext)
}

export function useGeneratorExperiment () {
  return useContext(GeneratorExperimentContext)
}

export function usePageSizeExperiment () {
  return useContext(PageSizeExperimentContext)
}

export function  useSkipStepExperiment () {
  return useContext(SkipStepExperimentContext)
}
// тут настроить эксперимент
export function ExperimentsProvider ({ children }) {
  const experimentsResolver = useExperimentsResolver()

  return (
    <SizeExperimentContext.Provider value={experimentsResolver.sizeExperiment}>
      <UnsubExperimentContext.Provider value={experimentsResolver.unsubExperiment}>
        <PayScreenExperimentContext.Provider value={experimentsResolver.payScreenExperiment}>
          <BottomSheetExperimentContext.Provider value={experimentsResolver.bottomSheetExperiment}>
            <FirstPageExperimentContext.Provider value={experimentsResolver.firstPageExperiment}>
              <ArrowExperimentContext.Provider value={experimentsResolver.arrowExperiment}>
                <GeneratorExperimentContext.Provider value={experimentsResolver.aiExperiment}>
                  <PageSizeExperimentContext.Provider  value={experimentsResolver.pageSizeExperiment}>
                    <SkipStepExperimentContext.Provider value={experimentsResolver.skipThreeStepExperiment}>
                      {children}
                    </SkipStepExperimentContext.Provider>
                  </PageSizeExperimentContext.Provider>
                </GeneratorExperimentContext.Provider>
              </ArrowExperimentContext.Provider>
            </FirstPageExperimentContext.Provider>
          </BottomSheetExperimentContext.Provider>
        </PayScreenExperimentContext.Provider>
      </UnsubExperimentContext.Provider>
    </SizeExperimentContext.Provider>
  )
}